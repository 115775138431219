import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    TopToolbar,
    ReferenceField,
    ExportButton, useRecordContext, useGetOne, Loading, useGetList, useDataProvider,
} from 'react-admin';
import { useNavigate } from "react-router-dom";
import { LoadingSection } from "../../common/LoadingSection";
import { useParams } from "react-router";
import Button from "@mui/material/Button";
import { Edit, Visibility } from "@mui/icons-material";
import { Sections, Student_Test_Mapping, Student_Test_Mapping_Results } from "../../custom";
import { NoDataSection } from "../../common/NoDataSection";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import FullNameField from "../../common/FullNameField";
import { useQuery } from "react-query";


const ListActions = ({
    programId,
    testSeriesId,
    testId
}: { programId: string, testId: string, testSeriesId: string }) => {
    return <TopToolbar>

    </TopToolbar>
};

const ShowButton = () => {
    const navigate = useNavigate();
    const record: Student_Test_Mapping = useRecordContext<Student_Test_Mapping>();
    const { testSeriesId, programId, testId } = useParams();
    return <Button startIcon={<Visibility />} onClick={(e) => {
        navigate(`/programs/${programId}/test_serieses/${testSeriesId}/tests/${testId}/student_responses/${record.id}/show`)
    }}>
        Result
    </Button>
};

const ShowEvaluateButton = () => {
    const navigate = useNavigate();
    const record: Student_Test_Mapping_Results = useRecordContext<Student_Test_Mapping_Results>();
    const { testSeriesId, programId, testId } = useParams();
    return <Button className="" onClick={(e) => {
        navigate(`/programs/${programId}/test_serieses/${testSeriesId}/tests/${testId}/student_responses/${record.id}/evaluate`)
    }}>
        Evaluate
    </Button>
};

const ResultButtonVisibility = () => {
    const dataProvider = useDataProvider();
    const { testSeriesId, programId, testId } = useParams();
    const record: Student_Test_Mapping = useRecordContext<Student_Test_Mapping>();
    const { data: results, error: resultError } = useQuery(
        ['results_' + record.id, 'getList', { test_id: testId }],
        () => dataProvider.getList('student_test_mapping_results', {
            filter: { student_test_mapping_id: record.id },
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'created_at', order: 'DESC' }
        })
    );
    const markedAnswerSheet = results?.data[0]?.marked_answer_sheet 
    const { data, isLoading, error } = useGetOne("tests", { id: testId });
    if (isLoading) return <Loading />;
    if (data && data?.category == 'SUBJECTIVE') {
        return (
            (!markedAnswerSheet ? <ShowEvaluateButton /> : <ShowButton />)
        );
    } else
        return (<ShowButton />)
}

ShowButton.defaultProps = {
    label: 'Results'
};

const StudentResponseList = () => {
    const { testSeriesId, programId, testId } = useParams();
    if (!testId) {
        return <LoadingSection />
    }
    return (
        <List
            empty={<NoDataSection resource={'student_test_mapping'} />}
            sort={{ field: 'started_at', order: 'DESC' }} resource={'student_test_mapping'} actions={
                <ListActions programId={programId || ''} testSeriesId={testSeriesId || ''} testId={testId || ''} />
            } filter={{
                test_id: testId,
            }}>
            <Datagrid>
                <ReferenceField reference={'users'} source={'created_by_id'}>
                    <FullNameField source="first_name" />
                </ReferenceField>
                <ReferenceField reference={'tests'} source={'test_id'}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="status" />
                <FormattedDateTimeField source="started_at" />
                <FormattedDateTimeField source="submitted_at" />
                <FormattedDateTimeField source="end_time" />
                <FormattedDateTimeField source="created_at" />
                <ResultButtonVisibility />
            </Datagrid>
        </List>
    );
};
export default StudentResponseList;
