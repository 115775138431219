import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required, SelectInput, ReferenceInput, minValue,
} from 'react-admin';

import {Ce_Blogs,} from "../../custom";
import {EditToolbar} from "../../common/EditToolbar";
import ImageInput from "../../common/inputs/ImageInput";
import HTMLEditorInput, {validHtml} from "../../common/inputs/HTMLEditorInput";

const BlogEdit = () => (
    <Edit title={<BlogTitle/>}>
        <SimpleForm toolbar={<EditToolbar/>}>
            <ImageInput source={'thumbnail'} fullWidth validate={[required()]}/>
            <ReferenceInput label="Blog Topic" source="blog_topic_id" reference="ce_blog_topics">
                <SelectInput optionText="name"  fullWidth validate={[required()]}/>
            </ReferenceInput>
            <TextInput source="title" fullWidth validate={[required()]}/>

            <TextInput source="text_description" fullWidth validate={[required()]}/>
            <TextInput source="slug" fullWidth validate={[required()]}/>
            <TextInput type={'number'} source="display_order" fullWidth validate={[required(),minValue(0)]}/>
            <HTMLEditorInput source={'html_content'} fullWidth validate={[required(), validHtml]}/>
        </SimpleForm>
    </Edit>
);

const BlogTitle = () => {
    const record = useRecordContext<Ce_Blogs>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.blogs.name', {smart_count: 1})} &quot;
            {record.title}&quot;
        </span>
    ) : null;
};

export default BlogEdit;
