import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext,
    SelectInput,
    required,
    FormDataConsumer,
    ReferenceInput, ArrayInput, SelectArrayInput, BooleanInput,
} from 'react-admin';

import { Banners, } from "../../custom";
import ImageInput from "../../common/inputs/ImageInput";
import { EditToolbar } from "../../common/EditToolbar";
import { VIDEO_ENCODING, VIDEO_TYPES } from "../../common/constants";
import VideoInput from "../../common/inputs/VideoInput";
import { InputAdornment } from "@mui/material";
import useAllowedQualities from "../../hooks/useAllowedQualities";

const VideoEdit = () => {
    const Qualities = useAllowedQualities();
    return (
        <Edit title={<BannerTitle />} transform={(data) => ({
            ...data,
            raw_url: data.raw_url
        })}>
            <SimpleForm toolbar={<EditToolbar />}>
                <ImageInput source="thumbnail" fullWidth />
                <TextInput source="title" fullWidth />
                <ReferenceInput reference={'video_subjects'} source={'video_subject_id'}>
                    <SelectInput fullWidth optionText="name" />
                </ReferenceInput>
                <SelectInput
                    source="type"
                    label={'Type'}
                    fullWidth
                    onChange={() => {
                        setTimeout(() => {
                            if (document.getElementById('youtube-input')) {
                                document.getElementById('youtube-input')?.focus();
                            }
                        }, 100);
                    }}
                    disabled={true}
                    validate={[required()]}
                    choices={VIDEO_TYPES}
                />

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.type === 'YOUTUBE' ? <TextInput
                            id={'youtube-input'}
                            label={'Youtube Embed Code'} fullWidth
                            validate={[required()]} source="raw_url" {...rest} /> : (formData.type ? <>
                                <ArrayInput source="qualities" validate={[required()]} defaultValue={['360', '480']}>
                                    <SelectArrayInput
                                        validate={[required()]}
                                        label={'Video Qualities'}
                                        fullWidth
                                        disabled
                                        choices={Qualities} />
                                </ArrayInput>

                                <ArrayInput source="encodings" defaultValue={['HLS', 'DASH']}>
                                    <SelectArrayInput
                                        label={'Video Encodings'}
                                        fullWidth
                                        choices={VIDEO_ENCODING} />
                                </ArrayInput>
                                <BooleanInput defaultValue={false} fullWidth source={'drm_enabled'} />
                                <VideoInput disabled={true} fullWidth
                                    type={'VIDEOS'}
                                    meta={{
                                        drm_enabled: formData.drm_enabled,
                                        encodings: formData.encodings,
                                        qualities: formData.qualities
                                    }}
                                    validate={[required()]} source={'raw_url'} />
                            </> : <></>)
                    }
                </FormDataConsumer>
                <TextInput source="duration_in_seconds" fullWidth type={'number'} />
                {/*<TextInput source="restriction_max_duration_multiple" fullWidth type={'number'}/>*/}
            </SimpleForm>
        </Edit>
    );
};

const BannerTitle = () => {
    const record = useRecordContext<any>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.videos.name', { smart_count: 1 })} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default VideoEdit;
