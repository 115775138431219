import {useDataProvider} from "react-admin";
import {useQuery} from "react-query";
import {useMemo} from "react";

const useAllowedQualities = () =>{
    const dataProvider = useDataProvider();
    const {data: organization, refetch, error} = useQuery(
        ['organization_details', 'getList', {}],
        () => dataProvider.getList('organizations', {
            filter: {},
            pagination: {page: 1, perPage: 1},
            sort: {field: 'id', order: 'ASC'}
        })
    );
    const Qualities = useMemo(()=>{
        return organization?.data?.[0]?.qualities_allowed?.map((quality: string)=>{
            return {
                id: quality,
                name: quality
            }
        })
    },[organization]);
    return Qualities?.length ?Qualities :  [{id: "360", name: '360'}, {id: "480", name: '480'}, {id: "720", name: '720'}];
}
export default useAllowedQualities;
