import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required, ReferenceInput, SelectInput,
} from 'react-admin';
import {Sections} from "../../custom";
import {EditToolbar} from "../../common/EditToolbar";
import {useParams} from "react-router";
import {LoadingSection} from "../../common/LoadingSection";


const SectionEdit = () => {
    let {programId, testSeriesId, testId, sectionId} = useParams();
    if (!testId) {
        return <LoadingSection/>
    }
    return <Edit title={<SectionTitle/>} redirect={() => {
        return `programs/${programId}/test_serieses/${testSeriesId}/tests/${testId}/sections`
    }} resource={'sections'} id={sectionId}>
        <SimpleForm toolbar={<EditToolbar/>}>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <ReferenceInput label="Test" source="test_id" reference="tests">
                <SelectInput optionText="name" disabled fullWidth validate={[required()]}/>
            </ReferenceInput>
        </SimpleForm>
    </Edit>
};

const SectionTitle = () => {
    const record = useRecordContext<Sections>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.sections.name', {smart_count: 1})} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default SectionEdit;
