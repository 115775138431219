import VideoSubjectList from './VideoSubjectList';
import VideoSubjectEdit from './VideoSubjectEdit';
import {Image} from "@mui/icons-material";
import VideoSubjectCreate from "./VideoSubjectCreate";

export default {
    list: VideoSubjectList,
    edit: VideoSubjectEdit,
    create: VideoSubjectCreate,
    icon: Image,
};
