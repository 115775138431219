export function jsonToCSV(data: Array<any>) {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map(record => Object.values(record).map(d => '"' + d + '"').join(',')).join('\n');
    return header + '\n' + rows;
}

export function downloadCSV(csv: string, filename: string) {
    console.log('csv', typeof csv);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
