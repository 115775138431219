import {
    ApolloClient,
    HttpLink,
    ApolloLink,
    InMemoryCache,
    concat,
    from,
    FetchResult,
    Observable,
} from '@apollo/client'
import {onError} from '@apollo/client/link/error'
import {GQL_URL,} from "./common/constants";

const httpLink = new HttpLink({
    uri: GQL_URL,
})
const errorLink = onError(
    ({graphQLErrors, networkError, operation, forward}) => {
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                switch (err.extensions.code) {
                    case 'invalid-jwt':
                        // ignore 401 error for a refresh request
                        if (operation.operationName === 'refreshToken') return

                        const observable = new Observable<FetchResult<Record<string, any>>>(
                            (observer) => {
                                // used an annonymous function for using an async function
                                ;(async () => {
                                    try {
                                        localStorage.clear();
                                        window.location.href = '/'
                                        return;
                                        // Retry the failed request
                                        const subscriber = {
                                            next: observer.next.bind(observer),
                                            error: observer.error.bind(observer),
                                            complete: observer.complete.bind(observer),
                                        }
                                        forward(operation).subscribe(subscriber)
                                    } catch (err) {
                                        observer.error(err)
                                    }
                                })()
                            }
                        )

                        return observable
                }
            }
        }

        if (networkError) console.log(`[Network error]: ${networkError}`)
    }
)
const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    let token =
        ''
    if (window.localStorage.getItem('token')) {
        token = window.localStorage.getItem('token') as string
    }

    operation.setContext(({headers = {}}) => ({
        headers: {
            ...headers,
            authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    }))
    return forward(operation)
})
const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, concat(authMiddleware, httpLink)]),
})

export default client
