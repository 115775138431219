import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface MonthSelectorProps {
    monthsToShow?: number;
    onDateChange: (dateRange: { firstDay: string; lastDay: string }) => void;
}

const MonthSelector: React.FC<MonthSelectorProps> = ({ monthsToShow = 12, onDateChange }) => {
    const [selectedMonth, setSelectedMonth] = useState<string>('');

    const getLastNMonths = (n: number) => {
        const months = [];
        const today = new Date();
        for (let i = 0; i < n; i++) {
            const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
            months.push({
                label: date.toLocaleString('default', { month: 'long', year: 'numeric' }),
                value: format(date, 'yyyy-MM'), // YYYY-MM
                date,
            });
        }
        return months;
    };

    const months = getLastNMonths(monthsToShow);

    const handleMonthChange = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value;
        setSelectedMonth(selectedValue);
        if (selectedValue) {
            const [year, month] = selectedValue.split('-').map(Number);
            const firstDayOfMonth = new Date(year, month - 1, 1);
            const lastDayOfMonth = new Date(year, month, 0);
            onDateChange({
                firstDay: format(firstDayOfMonth, 'yyyy-MM-dd HH:mm:ss'),
                lastDay: format(lastDayOfMonth, 'yyyy-MM-dd HH:mm:ss'),
            });
        }
    };

    return (
        <div className="w-40 mr-[100px]">
            <FormControl variant="outlined" fullWidth>
                <InputLabel id="monthSelector-label">Select Month</InputLabel>
                <Select
                    labelId="monthSelector-label"
                    id="monthSelector"
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    label="Select Month"
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {months.map((month) => (
                        <MenuItem key={month.value} value={month.value}>
                            {month.label}
                        </MenuItem>
                    ))}
                </Select>

            </FormControl>
            <div className="day">{selectedMonth}</div>
        </div>
    );
};

MonthSelector.propTypes = {
    monthsToShow: PropTypes.number,
    onDateChange: PropTypes.func.isRequired,
};

export default MonthSelector;
