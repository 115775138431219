import {

    List,
    Datagrid,
    TextField, NumberField, ReferenceField, TopToolbar, CreateButton, ExportButton, useRecordContext, BooleanField,
} from 'react-admin';
import FormattedDateTimeField from "../../../common/FormattedDateTimeField";
import { NoDataSection } from "../../../common/NoDataSection";
import { format } from "date-fns";
import React, { useState } from "react";

import { jsonToCSV, downloadCSV } from "../../../common/CSVExport";
import MonthSelector from '../../../common/MonthSelector';

const myCustomExporter = (records: any) => {
    const data = records.map((record: any) => {
        return {
            ...record,
            created_at: format(new Date(record.created_at), 'dd-MMM-yyyy hh:mm a'),
        };
    });
    const csv = jsonToCSV(data); // Convert the modified data to CSV format
    downloadCSV(csv, 'User SignUp Report');  // Download the CSV
};

const ReportUserSignupsList = () => {
    const [selectedMonthRange, setSelectedMonthRange] = useState<{ firstDay: any, lastDay: any } | null>(null);
    const handleDateChange = ({ firstDay, lastDay }: { firstDay: any, lastDay: any }) => {
        setSelectedMonthRange({ firstDay, lastDay });
    };
        const ListActions = () => (
            <TopToolbar className="">
                <MonthSelector monthsToShow={12} onDateChange={handleDateChange} />
                <ExportButton />
            </TopToolbar>
        );

        return (
            <>
                <List
                    actions={<ListActions />}
                filter={{
                    created_at: {
                        format: 'hasura-raw-query',
                        value: {
                            _gt: selectedMonthRange?.firstDay,
                            _lt: selectedMonthRange?.lastDay
                        }
                    },
                }}
                empty={<NoDataSection resource={'report_user_signups'} />}
                exporter={myCustomExporter}
                sort={{ field: 'created_at', order: 'DESC' }} resource={'report_user_signups'}>,
                <Datagrid>
                    <TextField source="full_name" />
                    {/*<TextField source="board" />*/}
                    {/*<TextField source="campaign_available_points" />*/}
                    {/*<TextField source="campaign_medium" />*/}
                    {/*<TextField source="campaign_name" />*/}
                    {/*<TextField source="campaign_source" />*/}
                    <TextField source="city" />
                    <TextField source="class" />
                    <TextField source="email" />
                    <TextField source="exam" />
                    <TextField source="father_number" />
                    <TextField source="gender" />
                    {/*<TextField source="is_offline" />*/}
                    <TextField source="language" />
                    <TextField source="last_logged_in" />
                    {/*<TextField source="mother_number" />*/}
                    <TextField source="phone" />
                    {/*<TextField source="profile_completed" />*/}
                    {/*<TextField source="program" />*/}
                    {/*<TextField source="referred_by" />*/}
                    {/*<TextField source="registered_from" />*/}
                    {/*<TextField source="school" />*/}
                    <TextField source="state" />
                    <TextField source="status" />
                    <FormattedDateTimeField source="created_at" />
                </Datagrid>
            </List>
        </>
    );
};
export default ReportUserSignupsList;
