import Button from "@mui/material/Button";
import {RemoveCircle} from "@mui/icons-material";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useDataProvider, useRecordContext, useRefresh, useResourceContext} from "react-admin";
import {useMutation} from "react-query";

// this is the generic soft delete component which will add a delete button to the list view
function DeleteDialog({open, setOpen}: { open: boolean, setOpen: any }) {
    const dataProvider = useDataProvider()
    const resource = useResourceContext();
    const record = useRecordContext();
    const refresh = useRefresh();
    const {mutate, isLoading} = useMutation(
        () => dataProvider.update(resource, {
                id: record?.id,
                data: {deleted: true},
                previousData: record,
            },
        ),
        {
            onSuccess: () => {
                refresh();
                handleClose()
            },
            onError: (e) => {
                console.log('here no', resource)
            }
        }
    );
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Delete item
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Do you really want to delete this item?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={handleClose}>Cancel</Button>
                <Button disabled={isLoading} onClick={() => {
                    mutate()
                }} autoFocus>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export const SoftDeleteWithDialogAction = ({}: any) => {
    const [open, setOpen] = React.useState(false);
    return <>
        <DeleteDialog open={open} setOpen={setOpen}/>
        <Button color={'error'} onClick={() => setOpen(true)}>
            <RemoveCircle/>
        </Button>
    </>
};
