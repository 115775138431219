import * as React from "react";
import {
  List,
  EditButton,
  Datagrid,
  TextField,
  ReferenceOneField,
  ReferenceField,
  ShowButton,
  useRecordContext,
  CloneButton,
  useDataProvider,
  useGetOne,
  useGetList,
  FieldProps,
  Filter,
  TextInput,
  SelectInput,
  required,
  ReferenceInput,
} from "react-admin";
import ThumbnailField from "../../common/ThumbnailField";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import Button from "@mui/material/Button";
import { ContentCopy } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { SoftDeleteWithDialogAction } from "../../common/SoftDeleteWithDialogAction";
import { Batches, Subjects } from "../../custom";
import { ProductTemplateFormModal } from "../subjects/SubjectList";
import { format } from "date-fns";
import { STATUSES } from "../../common/constants";
import StatusTags from "../../common/StatusTags";

const CloneBatch = (props: any) => {
  const record = useRecordContext();
  const keysToCopy = [
    "title",
    "template_id",
    "image",
    "status",
    "start_time",
    "end_time",
    "text_description",
    "html_description",
  ];
  const newRecord: any = {};
  keysToCopy.forEach((b) => {
    newRecord[b] = record[b];
  });
  const navigate = useNavigate();
  return (
    <Button
      startIcon={<ContentCopy />}
      onClick={() => {
        navigate(
          `create?source=${encodeURIComponent(JSON.stringify(newRecord))}`
        );
      }}
    >
      Clone
    </Button>
  );
};

const Actions = (props: FieldProps<Batches>) => {
  return (
    <div className={"flex"}>
      <ShowButton />
      {/*<CloneBatch/>*/}
      <SoftDeleteWithDialogAction />
      <EditButton />
    </div>
  );
};

Actions.defaultProps = {
  label: "Actions",
};

const FormattedRegistrationField = (props: any) => {
  const record = useRecordContext<any>();
  return record ? (
    <div className={"flex flex-col text-sm space-y-1"}>
      {record.registration_start_date && (
        <div className={"flex items-center space-x-2"}>
          <div className={"whitespace-nowrap"}>
            {format(
              new Date(record.registration_start_date),
              "dd/MM/yyyy hh:mm a"
            )}
          </div>
        </div>
      )}
      {record.registration_end_date && (
        <div className={"flex items-center  space-x-2"}>
          <div className={"whitespace-nowrap"}>
            {format(
              new Date(record.registration_end_date),
              "dd/MM/yyyy hh:mm a"
            )}
          </div>
        </div>
      )}
    </div>
  ) : null;
};

FormattedRegistrationField.defaultProps = {
  source: "registration_start_date",
  label: "Registration",
};
export const FormattedStartEndField = (props: any) => {
  const record = useRecordContext<any>();
  return record ? (
    <div className={"flex flex-col text-sm space-y-1"}>
      {record.start_time && (
        <div className={"flex items-center space-x-2"}>
          <div className={"whitespace-nowrap"}>
            {format(new Date(record.start_time), "dd/MM/yyyy hh:mm a")}
          </div>
        </div>
      )}
      {record.end_time && (
        <div className={"flex items-center  space-x-2"}>
          <div className={"whitespace-nowrap"}>
            {format(new Date(record.end_time), "dd/MM/yyyy hh:mm a")}
          </div>
        </div>
      )}
    </div>
  ) : null;
};

FormattedStartEndField.defaultProps = {
  source: "start_time",
  label: "Start - End",
};
const ListFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="title" alwaysOn />
    <SelectInput
      source="status"
      label={"Status"}
      fullWidth
      alwaysOn
      choices={STATUSES}
    />
    <ReferenceInput
      reference={"batch_templates"}
      alwaysOn
      source={"template_id"}
    >
      <SelectInput optionText="title" />
    </ReferenceInput>
  </Filter>
);
const BatchesList = () => {
  return (
    <List
      filters={<ListFilter />}
      sort={{ field: "display_order", order: "ASC" }}
    >
      <Datagrid>
        <ThumbnailField source="image" />
        <TextField source="title" />
        <TextField source="display_order" />
        <ProductTemplateFieldBatches />
        <ReferenceField reference={"batch_templates"} source={"template_id"}>
          <TextField source="title" />
        </ReferenceField>
        <StatusTags source="status" label={"Status"} />
        <FormattedStartEndField source="start_time" />
        <FormattedRegistrationField source="registration_start_date" />
        <FormattedDateTimeField source="created_at" />
        <Actions />
      </Datagrid>
    </List>
  );
};
export const ProductTemplateFieldBatches = (props: FieldProps<Batches>) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext<Batches>();
  const {
    data: productsData,
    isLoading,
    error: resultError,
  } = useQuery(
    ["products_batch_" + record.id, "getList", { batch_id: record.id }],
    () =>
      dataProvider.getList("ecom_products", {
        filter: {
          product_template: {
            format: "hasura-raw-query",
            value: {
              batch_id: {
                _eq: record?.id,
              },
            },
          },
        },
        pagination: { page: 1, perPage: 20 },
        sort: { field: "created_at", order: "DESC" },
      })
  );
  const products = productsData?.data;
  if (isLoading) {
    return <div>...</div>;
  }
  if (products?.length) {
    return (
      <div
        className={
          "min-w-[90px] text-center px-2 py-0.5 rounded-md bg-blue-500 text-white no-wrap text-xs"
        }
      >
        Products ({products.length})
      </div>
    );
  }
  return <ProductTemplateFormModal batch_id={record.id} />;
};

ProductTemplateFieldBatches.defaultProps = {
  label: "Product Template",
};
export default BatchesList;
