import * as React from 'react';
import {Create, TextInput, NullableBooleanInput, SimpleForm, DateInput, SelectInput, required} from 'react-admin';
import ImageInput from "../../common/inputs/ImageInput";
import HTMLEditorInput, {validHtml} from "../../common/inputs/HTMLEditorInput";


const FAQCreate = () => (
    <Create redirect={'/faqs'}>
        <SimpleForm>
            <TextInput source="question" fullWidth validate={[required()]}/>
            <HTMLEditorInput source={'answer'} fullWidth validate={[required(), validHtml]}/>

            <TextInput type={'number'} source="display_order" fullWidth/>
        </SimpleForm>
    </Create>
);

export default FAQCreate;
