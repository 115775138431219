import Button from "@mui/material/Button";
import { CloudUpload, CloudUploadOutlined, RemoveCircle } from "@mui/icons-material";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BulkTestInput from "../../common/inputs/BulkTestInput";
import { Tooltip } from "@mui/material";
import BulkStudentInput from "../../common/inputs/BulkStudentInput";

function BulkStudentUpload({ open, setOpen, path }: { open: boolean, setOpen: any, path: any }) {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                Bulk Upload Student
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <BulkStudentInput path={path} onSuccess={(key: string) => {
                        handleClose();
                    }} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}


export const BulkUploadStudentAction = ({ path }: any) => {
    const [open, setOpen] = React.useState(false);
    return <>
        <BulkStudentUpload open={open} setOpen={setOpen} path={path} />
        <Tooltip title="Bulk Upload">
            <Button className="gap-3" onClick={() => {
                setOpen(true)
            }}>
                <CloudUploadOutlined />
                Student Upload
            </Button>
        </Tooltip>
    </>
};

