import {usePermissions} from "react-admin";

const usePermissionMiddleware = () => {
    const {permissions, isLoading} = usePermissions();

    function hasPermission(_permission: string) {
        if (!_permission || isLoading) {
            return false;
        }
        return permissions.base?.indexOf(_permission) > -1;
    }

    return {
        hasPermission,
        isLoading
    }
}
export default usePermissionMiddleware;
