import React, {useEffect, useState} from 'react';
import {
    List,
    Datagrid,
    TextField,
    TopToolbar,
    ExportButton, DateInput, Filter, useListContext, ListActions, useListFilterContext,
} from 'react-admin';
import FormattedDateTimeField from "../../../common/FormattedDateTimeField";
import { NoDataSection } from "../../../common/NoDataSection";
import { format, } from 'date-fns';
import { downloadCSV, jsonToCSV } from '../../../common/CSVExport';
import MonthSelector from '../../../common/MonthSelector';

const myCustomExporter = (records: any) => {
    const data = records.map((record: any) => ({
        ...record,
        joined_at: format(new Date(record.joined_at), 'dd-MMM-yyyy hh:mm a'),
        last_activity_at: format(new Date(record.last_activity_at), 'dd-MMM-yyyy hh:mm a'),
    }));
    const csv = jsonToCSV(data); // Convert the modified data to CSV format
    downloadCSV(csv, 'Application Users Report');  // Download the CSV
};


const DateRangeFilter = (props:any) => (
    <Filter {...props}>
        {/*<DateInput source="start_date" alwaysOn label="Start Date" />*/}
        {/*<DateInput source="end_date" alwaysOn label="End Date" />*/}
    </Filter>
);

const CustomListToolbar = (props: any) => {
    const { displayedFilters, filterValues, setFilters, showFilter } = useListContext();

    return (
        <TopToolbar>
                <ExportButton />
        </TopToolbar>
    );
};
const ReportApplicationUsersList = () => {
    // const ListActions = () => (
    // const { displayedFilters, filterValues, setFilters, showFilter } = useListContext();
    //
    // <TopToolbar className="">
    //         <DateRangeFilter
    //             displayedFilters={displayedFilters}
    //             filterValues={filterValues}
    //             setFilters={setFilters}
    //             showFilter={showFilter}
    //         />
    //         <ExportButton />
    //     </TopToolbar>
    // );
    // const { displayedFilters, filterValues } = useListContext();
    // const { setFilters } = useListFilterContext();
    //
    // useEffect(() => {
    //     const { start_date, end_date } = filterValues || {};
    //     const filters: any = {};
    //
    //     if (start_date) {
    //         filters.joined_at = filters.joined_at || {};
    //         filters.joined_at._gte = start_date;
    //     }
    //
    //     if (end_date) {
    //         filters.joined_at = filters.joined_at || {};
    //         filters.joined_at._lte = end_date;
    //     }
    //
    //     setFilters(filters, displayedFilters);
    // }, [filterValues, setFilters, displayedFilters]);
    return (

        <List
            actions={<CustomListToolbar />}
            filters={<DateRangeFilter />}
            exporter={myCustomExporter}
            resource="report_application_users"
            empty={<NoDataSection resource="report_application_users" />}
        >
            <Datagrid>
                <TextField source="name" />
                <TextField source="admission_number" />
                <TextField source="phone" />
                <TextField source="email" />
                <FormattedDateTimeField source="joined_at" />
                <FormattedDateTimeField source="last_activity_at" />
            </Datagrid>
        </List>
    )
}

export default ReportApplicationUsersList;
