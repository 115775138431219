import {Link, FieldProps, useRecordContext, ReferenceManyCount} from 'react-admin';

const DemoVideoLinkField = (props: FieldProps<any>) => {
    const record = useRecordContext<any>();
    if (!record) {
        return null;
    }   
    return (
        <Link to={`/demo_video_categories/${record.id}/demo_video`}>
            Demo Videos
            (
            <ReferenceManyCount
                label="Demo Videos"
                reference="demo_videos"
                target="demo_video_category_id"
            />
            )
        </Link>
    );
};

DemoVideoLinkField.defaultProps = {
    label: 'Demo Videos'
};

export default DemoVideoLinkField;
