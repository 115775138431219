import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required, SelectInput, ReferenceInput, minValue,
} from 'react-admin';

import {Ecom_Categories,} from "../../custom";
import ImageInput from "../../common/inputs/ImageInput";
import {EditToolbar} from "../../common/EditToolbar";
import {STATUSES} from "../../common/constants";

const CategoryEdit = () => (
    <Edit title={<CategoryTitle/>}>
        <SimpleForm toolbar={<EditToolbar/>}>
            <ImageInput source="image" fullWidth validate={[required()]}/>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <TextInput source="slug" fullWidth validate={[required()]}/>
            <TextInput type={'number'} source="display_order" fullWidth validate={[required(),minValue(0)]}/>
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUSES}
            />
            <ReferenceInput reference={'ecom_categories'} source={'parent_id'}>
                <SelectInput fullWidth disabled optionText="name"/>
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

const CategoryTitle = () => {
    const record = useRecordContext<Ecom_Categories>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.categories.name', {smart_count: 1})} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default CategoryEdit;
