import * as React from 'react';
import {Link, FieldProps, useRecordContext, ReferenceManyCount} from 'react-admin';
import {Chapters, Subjects, Tests,} from "../../custom";

const SectionsLinkField = (props: FieldProps<Tests>) => {
    const record = useRecordContext<Tests>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/programs/${record.program_id}/test_serieses/${record.test_series_id}/tests/${record.id}/sections`}>
            <div style={{whiteSpace: 'nowrap'}}>
                Sections (<ReferenceManyCount
                label="Sections"
                reference="sections"
                target="test_id"
            />)
            </div>
        </Link>
    );
};

SectionsLinkField.defaultProps = {
    label: 'Sections'
};

export default SectionsLinkField;
