import * as React from 'react';
import {Create, TextInput, NullableBooleanInput, SimpleForm, SelectInput, ReferenceInput, required,} from 'react-admin';
import ImageInput from "../../common/inputs/ImageInput";
import PDFInput from "../../common/inputs/PDFInput";


const DownloadCreate = () => {
    return <Create redirect={'/downloads'}>
        <SimpleForm>
            <TextInput source="title" fullWidth/>
            <ImageInput source={'thumbnail'} validate={[required()]}/>
            <PDFInput source={'url'} validate={[required()]}/>
            <TextInput source="display_order" type={'number'} fullWidth/>
            <NullableBooleanInput source={'is_demo'} defaultValue={true}/>
            <ReferenceInput source="category_id" reference="download_categories" fullWidth>
                <SelectInput optionText={'name'}/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
};

export default DownloadCreate;
