import {Typography} from "@mui/material";
import {
    Count, useDataProvider,
} from "react-admin";
import * as React from "react";
import {useQuery} from "react-query";
import CardWithIcon from "../dashboard/CardWithIcon";
import {CurrencyRupee} from "@mui/icons-material";

const CategoryCount = ({category,}: { category: any }) => {
    const Subtitle = () => {
        return <div className={'flex space-x-2 items-end'}>
            <Typography variant={'h5'} className={'font-semibold'}>
                {category?.total_product_templates}
            </Typography>
            <div className={'mb-1 '}>
                <Typography className={'text-gray-400'}>
                    ({category?.total_products})
                </Typography>
            </div>
        </div>
    }

    return (
        <>
            <CardWithIcon
                to={category?.category_id ? `/ecom_product_templates?filter=${encodeURIComponent(JSON.stringify({category_id: category?.category_id}))}` : `/ecom_product_templates?type=${category.id}&filter=`}
                icon={CurrencyRupee}
                title={category?.name}
                subtitle={<Subtitle/>}
            />
        </>
    );
};
const Store = () => {
    const dataProvider = useDataProvider();
    const {data: categoryData, isLoading, error} = useQuery(
        ['ecom_product_template_categories_view', 'getList', {}],
        () => dataProvider.getList('ecom_product_template_categories_view', {
            filter: {},
            pagination: {page: 1, perPage: 100},
            sort: {field: 'category_id', order: 'ASC'}
        })
    );
    return <div className={'w-full p-4'}>
        <Typography variant={'h4'}>Store</Typography>
        <div className={'h-5'}/>
        <div className={'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'}>
            {
                categoryData?.data.map((category: any) => {
                    return <div key={category.id}>
                        <CategoryCount category={category}/>
                    </div>
                })
            }
        </div>
    </div>
}
export default Store;
