import * as React from "react";
import {
  Create,
  TextInput,
  required,
  SimpleForm,
  DateInput,
  SelectInput,
  ReferenceInput,
  NullableBooleanInput,
  minValue,
  FormDataConsumer,
  SelectArrayInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";
import ImageInput from "../../common/inputs/ImageInput";
import HTMLEditorInput, {
  validHtml,
} from "../../common/inputs/HTMLEditorInput";
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import PDFInput from "../../common/inputs/PDFInput";
import { STATUSES } from "../../common/constants";
import TotalAmountInput from "../../common/inputs/TotalAmountInput";

export const filterToQueryProductChildren = (searchText: string) =>
  searchText ? { name: `${searchText}` } : { name: `${"NOT_AVAILABLE"}` };

const ProductCreate = () => {
  let { categoryId, productTemplateId } = useParams();
  if (!productTemplateId) {
    return <LoadingSection />;
  }

  return (
    <Create
      resource={"ecom_products"}
      redirect={(e: any, id: any) => {
        return `ecom_categories/${categoryId}/ecom_product_templates/${productTemplateId}/ecom_products`;
      }}
    >
      <SimpleForm
        defaultValues={{
          category_id: categoryId,
          product_template_id: productTemplateId,
        }}
      >
        <ImageInput
          source="images"
          multiple
          fullWidth
          validate={[required()]}
        />
        <PDFInput source="preview_pdf" fullWidth label={"Preview PDF"} />
        <PDFInput source="pdf_url" fullWidth label={"PDF"} />
        <SelectInput source="status" choices={STATUSES} fullWidth />
        <TextInput source="name" fullWidth validate={[required()]} />
        <NullableBooleanInput
          source={"address_required"}
          name={"Address Required"}
          fullWidth
        />
        <ReferenceInput
          reference={"ecom_product_templates"}
          source={"product_template_id"}
        >
          <SelectInput fullWidth disabled optionText="name" />
        </ReferenceInput>
        <TextInput
          type={"number"}
          source="mrp"
          prefix={"₹"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <TextInput
          type={"number"}
          source="discount"
          prefix={"₹"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <TextInput
          type={"number"}
          source="gst_percentage"
          prefix={"%"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <div className="w-full">
                <TotalAmountInput formData={formData} source="amount" />
              </div>
            );
          }}
        </FormDataConsumer>
        <TextInput
          type={"number"}
          source="apple_amount"
          prefix={"₹"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <TextInput
          type={"number"}
          source="height_in_mtr"
          prefix={"In Meters"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <TextInput
          type={"number"}
          source="width_in_mtr"
          prefix={"In Meters"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <TextInput
          type={"number"}
          source="length_in_mtr"
          prefix={"In Meters"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <TextInput
          type={"number"}
          source="weight_in_grams"
          prefix={"In Grams"}
          fullWidth
          validate={[required(), minValue(0)]}
        />
        <TextInput
          source="text_description"
          multiline
          rows={4}
          fullWidth
          validate={[required()]}
        />
        <ReferenceArrayInput reference={"ecom_products"} source={"children"}>
          <AutocompleteArrayInput
            filterToQuery={filterToQueryProductChildren}
            label={"For Combo Products"}
            fullWidth
            optionText="name"
          />
        </ReferenceArrayInput>
        <HTMLEditorInput
          source={"html_description"}
          fullWidth
          validate={[required(), validHtml]}
        />
      </SimpleForm>
    </Create>
  );
};

export default ProductCreate;
