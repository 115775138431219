import * as React from 'react';
import {Create, TextInput, required, SimpleForm, DateInput, SelectInput, ReferenceInput, minValue} from 'react-admin';
import { STATUSES} from "../../common/constants";
import ImageInput from "../../common/inputs/ImageInput";


const CategoryCreate = () => (
    <Create redirect={'/ecom_categories'}>
        <SimpleForm>
            <ImageInput source="image" fullWidth validate={[required()]}/>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <TextInput source="slug" fullWidth validate={[required()]}/>
            <TextInput type={'number'} source="display_order" fullWidth validate={[required(),minValue(0)]}/>
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUSES}
            />
            <ReferenceInput reference={'ecom_categories'} source={'parent_id'}>
                <SelectInput fullWidth optionText="name"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export default CategoryCreate;
