import * as React from 'react';
import {Create, TextInput, NullableBooleanInput, SimpleForm, DateInput, SelectInput, minValue} from 'react-admin';
import ImageInput from "../../common/inputs/ImageInput";


const BannerCreate = () => (
    <Create redirect={'/banners'}>
        <SimpleForm >
            <ImageInput source="image" fullWidth/>
            <NullableBooleanInput source="hidden" fullWidth label={'Hidden'}/>
            <TextInput type={'number'} source="display_order" fullWidth validate={[minValue(0)]}/>
        </SimpleForm>
    </Create>
);

export default BannerCreate;
