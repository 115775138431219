import * as React from 'react';
import {
    Edit,
    TextInput,
    useTranslate,
    useRecordContext,
    NullableBooleanInput,
    ReferenceInput,
    SelectInput,
    required,
    AutocompleteInput,
    DateTimeInput,
    useDataProvider,
    FormDataConsumer,
    FunctionField,
    TabbedForm,
} from 'react-admin';

import {Users, Videos,} from "../../custom";
import ImageInput from "../../common/inputs/ImageInput";
import {EditToolbar} from "../../common/EditToolbar";
import {useQuery} from "react-query";
import VideoAutocompleteInput from "../../common/inputs/VideoAutocompleteInput";
import {filterToQueryStudent, studentProfileRender} from "../batches/BatchesShow";

export const videoNameRender = (choice: Videos) => `${choice.title}| ${choice.code}`;
export const filterToQueryVideo = (searchText: string) => searchText ? ({code: `${searchText}`}) : ({code: `${'NOT_AVAILABLE'}`});

const SubjectInput = () => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const {data: subjects,} = useQuery(
        ['batch_template_subjects_subjects_' + record.id, 'getList', {batch_template_id: record.template_id}],
        () => dataProvider.getList('subjects', {
            filter: {
                batch_template_subjects: {
                    format: 'hasura-raw-query',
                    value: {
                        batch_template_id: {_eq: record.template_id}
                    },
                }
            },
            pagination: {page: 1, perPage: 400},
            sort: {field: 'created_at', order: 'ASC'}
        })
    );
    return <SelectInput optionText="name" source={'subject_id'}
                        choices={subjects?.data || []} disabled={!subjects?.data} fullWidth
                        validate={[required()]}/>
}
export const BatchScheduleSubjectChapterInput = ({subjectId}: { subjectId: string }) => {
    const dataProvider = useDataProvider();
    const {data: chapters,} = useQuery(
        ['subject_chapters_' + subjectId, 'getList', {subject_id: subjectId}],
        () => dataProvider.getList('chapters', {
            filter: {
                subject_id: subjectId
            },
            pagination: {page: 1, perPage: 400},
            sort: {field: 'created_at', order: 'ASC'}
        })
    );
    return <SelectInput optionText="name" source={'chapter_id'}
                        choices={chapters?.data || []} disabled={!chapters?.data} fullWidth/>
}


export const homeworkRender = (choice: any) => `${choice.title}`;
export const homeworkQuery = (searchText: string, subject_id: string, batch_id: string) => ({
    title: `${searchText}%`,
    subject_id: subject_id,
    batch_id
});


const BatchScheduleEdit = () => {
    return <Edit title={<Title/>} mutationMode="optimistic" mutationOptions={{

        onSuccess: () => {
            setTimeout(() => {
                window.history.back();
            }, 200);
        }
    }}>
        <TabbedForm toolbar={<EditToolbar/>}>

            <TabbedForm.Tab
                label="Details"
            >
                <ReferenceInput name={'Batch'} source={'batch_id'} reference={'batches'}>
                    <SelectInput optionText="title" disabled fullWidth validate={[required()]}/>
                </ReferenceInput>
                <div className={'w-full'}>
                    <FunctionField
                        render={(record: any) => {
                            return <ReferenceInput fullWidth name={'Primary Teacher'} source={'primary_teacher_id'}
                                                   reference={'users'}
                                                   filter={{
                                                       batch_teachers: {
                                                           format: 'hasura-raw-query',
                                                           value: {
                                                               batch_id: {
                                                                   _eq: record?.batch_id
                                                               }
                                                           },
                                                       }
                                                   }}>
                                <AutocompleteInput optionText={studentProfileRender}
                                                   filterToQuery={filterToQueryStudent}
                                                   debounce={500} fullWidth/>
                            </ReferenceInput>
                        }}
                    />
                </div>

                <ImageInput source={'thumbnail'} validate={[required()]}/>
                <SubjectInput/>
                <FormDataConsumer>
                    {
                        ({formData, ...rest}) => formData.subject_id &&
                            <>
                                <BatchScheduleSubjectChapterInput subjectId={formData.subject_id}/>
                            </>
                    }
                </FormDataConsumer>
                <TextInput source="title" validate={required()} fullWidth/>
                <NullableBooleanInput source="chat_enabled" fullWidth/>
                <FormDataConsumer>
                    {
                        ({formData, ...rest}) => <VideoAutocompleteInput formData={formData} fullWidth
                                                                         source={'video_id'}/>
                    }
                </FormDataConsumer>

                <DateTimeInput source="start_time" validate={required()} fullWidth/>
                <DateTimeInput source="end_time" validate={required()} fullWidth/>
                <NullableBooleanInput source="is_demo" fullWidth/>
                <TextInput source="restriction_max_duration_multiple" type={'number'} fullWidth/>
                <TextInput source="restriction_max_number_playback" type={'number'} fullWidth/>

            </TabbedForm.Tab>
        </TabbedForm>
    </Edit>
};

const Title = () => {
    const record = useRecordContext<any>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.batch_schedule.name', {smart_count: 1})} &quot;
            {record.display_order}&quot;
        </span>
    ) : null;
};

export default BatchScheduleEdit;
