import * as React from 'react';
import {SxProps, Typography} from '@mui/material';
import {memo} from 'react';

import {FieldProps, useRecordContext} from 'react-admin';
import {Customer} from '../types';
import {Ecom_Orders, Users} from "../custom";
import AvatarField from "./AvatarField";
import {displayPrice} from "./string-utilities";

interface Props extends FieldProps<Customer> {
    size?: string;
    sx?: SxProps;
}

const OrderField = (props: Props) => {
    const {size} = props;
    const record = useRecordContext<Ecom_Orders>();
    return record ? (
            <Typography
                variant="body2"
                display="flex"
                flexWrap="nowrap"
                alignItems="center"
                component="div"
                sx={props.sx}
            >
                <div>
                    <div>
                        Payment: {displayPrice(record.amount)}
                    </div>
                    <div>
                        Discount: {displayPrice(record.discount)}
                    </div>
                    <div>
                        Mode: {record.payment_source}
                    </div>
                </div>
            </Typography>
    ) : null;
};

OrderField.defaultProps = {
    source: 'order_id',
    label: 'Order',
};

export default memo<Props>(OrderField);
