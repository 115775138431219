import ProgramList from './ProgramList';
import ProgramEdit from './ProgramEdit';
import {ViewInAr} from "@mui/icons-material";
import ProgramCreate from "./ProgramCreate";
import ProgramShow from "./ProgramShow";

export default {
    list: ProgramList,
    edit: ProgramEdit,
    create: ProgramCreate,
    show: ProgramShow,
    icon: ViewInAr,
};
