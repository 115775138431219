import {Button, CircularProgress, LinearProgress, Typography} from "@mui/material";
import {useState} from "react";
import {useController} from "react-hook-form";
import {BASE_URL} from "../constants";
import {Videocam} from "@mui/icons-material";
import {useCreateContext} from "react-admin";


export const uploadFileToS3 = async (file: any, type: string, s3Url: string, onPregress: any): Promise<any> => {
    return new Promise((resolve, reject)=>{
        const xhr = new XMLHttpRequest();

        xhr.open('PUT', s3Url, true);
        xhr.overrideMimeType(type);
        xhr.upload.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
                const percentComplete = Math.round((event.loaded * 100) / event.total);
                onPregress(percentComplete);
            }
        });

        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve(xhr);
            } else {
                reject(xhr);
            }
        };

        xhr.send(file);
    })
};
export const getPreSignedUrl = async (type: string, file_name: string, test_id?: string) => {
    return fetch(BASE_URL + '/let-me-upload', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`

        },
        body: JSON.stringify({type, file_name, test_id}),
    }).then((res) => res.json());
};
const VideoInput = (props: any = {}) => {
    const i = useController({name: props.source});
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const showImages = () => {
        if (loading) {
            return false;
        }
        return !i.field.value;

    };
    const renderImages = () => {
        const result: any = [];
        if (i.field?.value) {
            result.push(
                <div key={"image"}>
                    <Videocam sx={{fontSize: '100px'}}/>
                    <Button onClick={() => i.field.onChange("")} color="error">
                        Remove
                    </Button>
                </div>
            );
        }
        return result;
    };
    return (
        <>
            {!showImages() && !loading &&
                <div className={'p-2 rounded-md border w-full bg-gray-50 relative mb-4'}>{renderImages()}</div>}
            {
                loading && <div className={' p-4 rounded-md border bg-gray-50  w-full'}>
                <Typography>{progress}%</Typography>
                    <LinearProgress
                        variant="determinate"
                        value={progress}
                        color="secondary"
                    />
                </div>
            }
            <div style={{position: "relative"}} className={'w-full'}>
                {showImages() && (
                    <div className={'p-1 rounded-md bg-gray-50 border w-full'}>
                        <Button startIcon={<Videocam/>}>
                            Select {props.label || 'Video to upload'}
                            <input
                                placeholder={props.placeholder}
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    right: "0",
                                    bottom: "0",
                                    top: "0",
                                    opacity: "0",
                                }}
                                accept="video/*"
                                multiple={false}
                                {...i.field}
                                value={""}
                                onChange={async (e) => {
                                    const file = e.target.files?.[0];
                                    if (!file) {
                                        return;
                                    }
                                    try {
                                        setLoading(true);
                                        const response = await getPreSignedUrl(props.type, file.name);
                                        let uploadUrl = response?.url;
                                        if (uploadUrl) {
                                            try {
                                                const res = await uploadFileToS3(file, file.type, uploadUrl, (progress: any)=>{
                                                    setProgress(progress);
                                                });
                                                if (props.meta && response.metaUrl) {
                                                    // Convert JSON object to a string
                                                    let jsonData = JSON.stringify(props.meta, null, 2);
                                                    let jsonBlob = new Blob([jsonData], {type: 'application/json'});
                                                    // await uploadFileToS3(jsonBlob, 'application/json', response?.metaUrl);
                                                }
                                                if (res?.status === 201 || res?.status === 200) {
                                                    if (props.multiple) {
                                                        const v = i.field.value || [];
                                                        i.field.onChange([
                                                            ...v,
                                                            `${uploadUrl?.split('?')[0]}`,
                                                        ]);
                                                        if(props.onChange){
                                                            props.onChange([
                                                                ...v,
                                                                `${uploadUrl?.split('?')[0]}`,
                                                            ])
                                                        }
                                                    } else {
                                                        if(props.onChange){
                                                            props.onChange(`${uploadUrl?.split('?')[0]}`)
                                                        }
                                                        i.field.onChange(
                                                            `${uploadUrl?.split('?')[0]}`
                                                        );
                                                    }
                                                } else {
                                                    alert("Unable to upload Image, check console for error");
                                                }
                                            } catch (e) {
                                            } finally {
                                                setLoading(false);
                                            }

                                        } else {
                                            setLoading(false);
                                        }

                                    } catch (error) {
                                        alert("Unable to upload Video, check console for error");
                                        setLoading(false);
                                    }
                                }}
                                type={"file"}
                            />
                        </Button>
                    </div>
                )}
            </div>

        </>
    );
};
export default VideoInput;
