import { RichTextInput } from "ra-input-rich-text";

// const ruleSets: Ruleset = {
//     'tagname-lowercase': true,
//     'attr-lowercase': true,
//     'attr-value-double-quotes': true,
//     'tag-pair': true,
//     'id-unique': true,
//     'src-not-empty': true,
//     'attr-no-duplication': true,
//     'title-require': true,
// }
export const validHtml = (value: string) => {
    // let parser = new DOMParser();
    // let doc = parser.parseFromString(value, "application/xml");
    // let errorNode = doc.querySelector('parsererror');
    // Regex to check valid HTML tag.
    // const hints = HTMLHint.verify(value, ruleSets)
    // return hints?.length ? hints.map((h: any) => h.message).join(', ') : false;
}
// const HTMLEditorInput = (props: any = {}) => {
//     const {onChange, onBlur, ...rest} = props;
//     const [annotations, setAnnotations] = useState<Ace.Annotation[]>([])
//
//     const {
//         field,
//         fieldState: {isTouched, invalid, error, ...p},
//         formState: {isSubmitted},
//         isRequired,
//     } = useInput({
//         // Pass the event handlers to the hook but not the component as the field property already has them.
//         // useInput will call the provided onChange and onBlur in addition to the default needed by react-hook-form.
//         onChange,
//         onBlur,
//         ...props,
//     });
//
//     useEffect(() => {
//         // this was put in useEffect rather than onChange
//         // because we want to run this alo when value is loaded initially
//         const hints = HTMLHint.verify(field.value, ruleSets)
//         const newAnnotations: Ace.Annotation[] = hints.map((hint: any) => {
//             return {
//                 row: hint.line - 1,
//                 column: hint.col - 1,
//                 text: hint.message,
//                 type: hint.type,
//             }
//         })
//         // setTimeout was used because annotations errors were not displayed
//         // if the html is copy/pasted into the editor, putting setAnnotations
//         // inside setTimeout solved that issue
//         setTimeout(() => {
//             setAnnotations(newAnnotations)
//         })
//     }, [field.value])
//
//     return (
//         <div style={{
//             width: '100%',
//         }}>
//             <Typography sx={{textTransform: 'capitalize'}}>{field.name?.split('_').join(' ')} {isRequired && '*'}</Typography>
//             <div style={{
//                 width: '100%',
//                 height: '600px',
//                 border: `2px solid ${invalid ? 'red' : 'transparent'}`,
//                 borderRadius: '5px',
//                 overflow: 'hidden'
//             }}>
//                 <AceEditor
//                     mode="html"
//                     theme="monokai"
//                     name="awesome-code"
//                     height={"100%"}
//                     width={"100%"}
//                     onChange={(d) => {
//                         field.onChange(d);
//                     }}
//                     fontSize={14}
//                     showPrintMargin={true}
//                     focus={true}
//                     annotations={annotations}
//
//                     editorProps={{$blockScrolling: true}}
//                     wrapEnabled={true}
//                     highlightActiveLine={true}
//                     value={field.value}
//                     setOptions={{
//                         enableBasicAutocompletion: true,
//                         enableLiveAutocompletion: true,
//                         enableSnippets: true,
//                         showLineNumbers: true,
//                         tabSize: 2,
//                         showGutter: true
//                     }}/>
//
//             </div>
//             {
//                 error && <div style={{color: 'red'}}>{error?.message}</div>
//             }
//         </div>
//     );
// };
const HTMLEditorInput = (props: any = {}) => {
    return <RichTextInput  {...props} />
}
export default HTMLEditorInput;
