import * as React from 'react';
import {

    List,
    Datagrid,
    TextField,
} from 'react-admin';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';

const OTPList = () => {
    return (
        <div>
            <List sort={{field: 'created_at', order: 'DESC'}}>
                <Datagrid>
                    <TextField source="country_code"/>
                    <TextField source="phone"/>
                    <TextField source="otp"/>
                    <FormattedDateTimeField source="valid_till"/>
                    <FormattedDateTimeField source="created_at"/>
                    
                </Datagrid>
            </List>
        </div>
    );
};
export default OTPList;
