import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required,
} from 'react-admin';

import ImageInput from "../../common/inputs/ImageInput";
import {EditToolbar} from "../../common/EditToolbar";

const DownloadCategoryEdit = () => {
    return <Edit title={<Title/>} redirect={'/download_categories'}>
        <SimpleForm  toolbar={<EditToolbar/>}>
            <TextInput source="name" fullWidth name={'name'}/>
            <ImageInput source={'thumbnail'} validate={[required()]}/>
            <TextInput source="display_order" type={'number'} fullWidth/>
        </SimpleForm>
    </Edit>
};

const Title = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.download_categories.name', {smart_count: 1})} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default DownloadCategoryEdit;
