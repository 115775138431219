import {Image} from "@mui/icons-material";
import BatchScheduleEdit from "./BatchScheduleEdit";
import BatchScheduleShow from "./BatchScheduleShow";
import BatchScheduleList from "./BatchScheduleList";
export default {
    edit: BatchScheduleEdit,
    show: BatchScheduleShow,
    list: BatchScheduleList,
    icon: Image,
};
