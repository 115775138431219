import * as React from 'react';
import {
    Show,
    useTranslate,
    useRecordContext,
    TabbedShowLayout,
    TextField,
    ReferenceField,
    DateField,
    List,
    Pagination,
    Datagrid,
    EditButton,
    ReferenceManyField, useDataProvider
} from 'react-admin';

import ThumbnailField from "../../common/ThumbnailField";
import {Batch_Templates} from "../../custom";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import {useMutation, useQuery} from "react-query";
import {Alert, Button, Checkbox, Typography} from "@mui/material";
import {AddCircle, RemoveCircle} from "@mui/icons-material";


const AddSubjectAction = ({
                              refresh,
                              batch_template_id,
                              subject_id,
                              loading
                          }: { refresh: any, loading: boolean, batch_template_id: string, subject_id: string }) => {
    const dataProvider = useDataProvider()
    const {mutate, isLoading} = useMutation(
        () => dataProvider.create('batch_template_subjects', {
                data: {batch_template_id, subject_id},
            },
        ),
        {
            onSuccess: () => {
                refresh()
            }
        }
    );
    return <Button disabled={loading} startIcon={<AddCircle/>} onClick={() => {
        if (!isLoading)
            mutate();
    }}>
        Attach
    </Button>
}
const RemoveSubjectAction = ({refresh, data, loading}: { loading: boolean, refresh: any, data: any, }) => {
    const dataProvider = useDataProvider()
    const {mutate, isLoading} = useMutation(
        () => dataProvider.update('batch_template_subjects', {
                id: data?.id,
                data: {deleted: true,},
                previousData: data,
            },
        ),
        {
            onSuccess: () => {
                refresh()
            },
            onError: (e) => {
                console.log('here no', e)
            }
        }
    );
    return <Button disabled={loading} startIcon={<RemoveCircle/>} onClick={() => {
        if (!isLoading)
            mutate();
    }} color={'error'}>
        Remove
    </Button>
}

const SelectorAction = ({
                            refresh,
                            batchTemplateSubjects,
                            loading,
                            batchTemplate,
                        }: { refresh: any, loading: boolean, batchTemplateSubjects: any[] | any, batchTemplate: any, }) => {

    const record = useRecordContext();

    return <div>
        {
            batchTemplateSubjects?.find((b: any) => b.subject_id === record.id) ?
                <RemoveSubjectAction loading={loading} refresh={refresh}
                                     data={batchTemplateSubjects?.find((b: any) => b.subject_id === record.id)}/> :
                <AddSubjectAction loading={loading} refresh={refresh} batch_template_id={batchTemplate.id}
                                  subject_id={record.id as string}/>
        }
    </div>
}
const BatchTemplateSubjects = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const {data: batchTemplateSubjects, refetch, isLoading, error} = useQuery(
        ['batch_template_subjects_' + record.id, 'getList', {batch_template_id: record.id}],
        () => dataProvider.getList('batch_template_subjects', {
            filter: {batch_template_id: record.id},
            pagination: {page: 1, perPage: 400},
            sort: {field: 'created_at', order: 'ASC'}
        })
    );
    return <>
        <Alert severity={'info'} sx={{marginBottom: '10px'}}>
            Subjects with are listed under same program as this batch template will list
            here
        </Alert>
        <List
            actions={false}
            resource={'subjects'}
            perPage={50}
            component={'div'}
            filter={{program_id: record.program_id}}
        >
            <Datagrid
                sx={{
                    width: '100%',
                    '& .column-comment': {
                        maxWidth: '20em',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    },
                }}
                bulkActionButtons={false}
            >
                <SelectorAction loading={isLoading} refresh={() => {
                    refetch();
                }} batchTemplateSubjects={batchTemplateSubjects?.data} batchTemplate={record}/>
                <TextField source={'name'} label={'Subject Name'}/>
            </Datagrid>
        </List>
    </>
}

const BatchShow = () => (
    <Show title={<BatchTemplateTitle/>}>
        <TabbedShowLayout>
            <TabbedShowLayout.Tab
                label="resources.batches.tabs.details">
                <TextField source="title" fullWidth/>
                <TextField source="slug" fullWidth/>
                <TextField source="type" fullWidth/>
                <ReferenceField reference={'programs'} source={'program_id'}>
                    <TextField fullWidth source="name"/>
                </ReferenceField>
                <TextField source="status"/>
                <TextField source="description"/>
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab
                path={'subjects'}
                label="resources.batches.tabs.subjects">
                <BatchTemplateSubjects/>
            </TabbedShowLayout.Tab>
        </TabbedShowLayout>
    </Show>
);

const BatchTemplateTitle = () => {
    const record = useRecordContext<Batch_Templates>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.batch_templates.name', {smart_count: 1})} &quot;
            {record.title}
        </span>
    ) : null;
};

export default BatchShow;
