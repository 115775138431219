import * as React from 'react';
import {
    Create,
    required,
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    BooleanInput,
    SimpleFormIteratorContext,
    SimpleForm,
    SelectInput,
    useCreateContext, useCreate, useNotify, ReferenceInput, AutocompleteInput, useGetList, Loading
} from 'react-admin';
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import ImageInput from "../../common/inputs/ImageInput";

import { QUESTION_TYPES, VIDEO_TYPES } from "../../common/constants";
import { useNavigate } from "react-router-dom";
import HTMLEditorInput, { validHtml } from '../../common/inputs/HTMLEditorInput';
import { filterToQueryVideo, videoNameRender } from '../batch_schedules/BatchScheduleEdit';
import { filter } from 'lodash';

const Options = () => {
    const { record } = useCreateContext();
    if (record?.type === 'NUMERIC') {
        return <TextInput fullWidth source={'solution_text'} type={'number'} />
    } else if (record?.type) {
        return <ArrayInput source="options" sx={{
            border: '1px solid #aaaaaa',
            padding: '10px',
            borderRadius: '10px',
            marginBottom: '20px'
        }}>
            <SimpleFormIterator>
                <ImageInput fullWidth source={`image`} />
                <TextInput fullWidth source={`display_order`} defaultValue={0} />

                <HTMLEditorInput label={'Content'} fullWidth source={`content`} validate={[validHtml]} />
                <BooleanInput defaultValue={false} fullWidth label={'Is Correct'}
                    source={`is_correct`} />
            </SimpleFormIterator>
        </ArrayInput>

    }

    return <>

    </>
}
const TopicQuestionsCreate = () => {
    let { programId, exerciseId, testId, sectionId } = useParams();
    const { data: sectionData, total, isLoading: loading, error } = useGetList(
        'sections',
        {
            filter: { test_id: testId }
        }
    );
    const SectionId = sectionData?.[0]?.id
    // console.log("SectionId",Section)
    const [create, { isLoading }] = useCreate();
    const navigate = useNavigate();
    const notify = useNotify();

    const postSave = (data: any) => {
        try {
            if (isLoading) {
                return;
            }
            const d: any = {
                ...data
            }
            if (d.options) {
                d.options = {
                    data: d.options
                }
            }

            if (d.type === 'NUMERIC') {
                delete d.options;
            }
            if (d.type === 'SINGLE') {
                const correctOptions = d.options?.data?.filter((option: any) => !!option.is_correct);
                if (correctOptions?.length !== 1) {

                    notify(
                        'Exactly one correct answer should be selected',
                        {
                            type: 'error'
                        }
                    );
                    return;
                }
            }
            if (d.type === 'MULTIPLE') {
                const correctOptions = d.options?.data?.filter((option: any) => !!option.is_correct);
                if (!correctOptions?.length) {
                    notify(
                        'At least one correct answer should be selected',
                        {
                            type: 'error'
                        }
                    );
                    return;
                }
            }
            create('questions', { data: d }, {
                onSuccess: (e) => {
                    navigate(`/programs/${programId}/exercise/${exerciseId}/tests/${testId}/questions_list`)
                }
            });
        } catch (e) {
            return false;
        }
    };
    if (!exerciseId) {
        return <LoadingSection />
    }
    return <Create resource={'questions'}>
        <SimpleForm onSubmit={postSave}
            defaultValues={{ test_id: testId, section_id: SectionId, exercise_id: exerciseId }}>
            <ImageInput source={"image"} />
            <HTMLEditorInput fullWidth source={'content'} />
            <SelectInput
                source="type"
                label={'Type'}
                fullWidth
                disabled
                validate={[required()]}
                choices={QUESTION_TYPES}
            />

            <Options />
            <TextInput source={'positive_marks'} fullWidth />
            <TextInput source={'negative_marks'} fullWidth />
            <HTMLEditorInput fullWidth source={'solution_description'} />
            <ReferenceInput name={'Video'} source={'video_id'} reference={'videos'}>
                <AutocompleteInput optionText={videoNameRender} filterToQuery={filterToQueryVideo}
                    limitChoicesToValue={true} debounce={500} fullWidth />
            </ReferenceInput>
            <ImageInput source={"solution_image"} label={"solution image"} />
        </SimpleForm>
    </Create>
};

export default TopicQuestionsCreate;
