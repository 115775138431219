import * as React from 'react';
import {Create, TextInput, NullableBooleanInput, SimpleForm, SelectInput, ReferenceInput, required,} from 'react-admin';
import ImageInput from "../../common/inputs/ImageInput";
import PDFInput from "../../common/inputs/PDFInput";
import AudioInput from "../../common/inputs/AudioInput";
import HTMLEditorInput from "../../common/inputs/HTMLEditorInput";


const PodcastCreate = () => {
    return <Create redirect={'/podcasts'}>
        <SimpleForm>
            <TextInput source="title" fullWidth/>
            <ImageInput source={'thumbnail'} validate={[required()]}/>
            <AudioInput source={'url'} validate={[required()]}/>
            <TextInput source="display_order" type={'number'} fullWidth/>
            <ReferenceInput source="topic_id" reference="podcast_topics" fullWidth>
                <SelectInput optionText={'name'} fullWidth/>
            </ReferenceInput>
            <HTMLEditorInput source={'description'} fullWith/>
        </SimpleForm>
    </Create>
};

export default PodcastCreate;
