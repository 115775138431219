import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required, SelectInput, ReferenceInput,
} from 'react-admin';

import {Cs_Issue_Topics,} from "../../custom";
import {EditToolbar} from "../../common/EditToolbar";
import {STATUSES} from "../../common/constants";

const IssueTopicEdit = () => (
    <Edit title={<IssueTopicTitle/>}>
        <SimpleForm toolbar={<EditToolbar/>}>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <TextInput source="slug" fullWidth validate={[required()]}/>
            <ReferenceInput label="Issue Template" source="issue_template_id" reference="cs_issue_templates">
                <SelectInput optionText="name" disabled fullWidth/>
            </ReferenceInput>
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUSES}
            />
        </SimpleForm>
    </Edit>
);

const IssueTopicTitle = () => {
    const record = useRecordContext<Cs_Issue_Topics>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.issue_topics.name', {smart_count: 1})} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default IssueTopicEdit;
