import * as React from 'react';
import {Link, FieldProps, useRecordContext, ReferenceManyCount} from 'react-admin';
import {Chapters, Ecom_Categories, Ecom_Product_Templates, Subjects,} from "../../custom";

const ProductTemplatesLinkField = (props: FieldProps<Ecom_Categories>) => {
    const record = useRecordContext<Ecom_Categories>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/ecom_categories/${record.id}/ecom_product_templates`}>
            Product Templates 
            (
            <ReferenceManyCount
                label="Product Templates"
                reference="ecom_product_templates"
                target="category_id"
            />
            )
        </Link>
    );
};

ProductTemplatesLinkField.defaultProps = {
    label: 'Product Templates'
};

export default ProductTemplatesLinkField;
