import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            new_orders: 'New Orders',
            new_students: 'New Students',
            all_students: 'All Students',
            all_reviews: 'All Reviews',
            month_history: 'Month History',
            pending_orders: 'Pending Orders',
            pending_reviews: 'Pending Reviews',
            welcome: {
                title: 'Welcome'
            },
            order: {
                items: 'Items'
            },
            total_users: 'Total Users',
            active_users: 'Active Users',
            new_users: 'New Users',
            total_sale: 'Total Sale',
            mail_balance: 'Mail Balance',
            notification_balance: 'Notification Balance',
            sms_balance: 'SMS Balance',
            voice_otp_balance: 'Voice OTP Balance',
            students_per_class: 'Students Per Class',
            monthly_new_users: 'Monthly New Users',
        },

        menu: {},
    },
    resources: {
        organizations: {
            name: 'Organization |||| Organizations',
            fields: {
                name: 'Name',
            },
            tabs: {
                details: 'Details',
                about_us: 'About Us',
                terms_and_conditions: 'Terms and Conditions',
                privacy_policies: 'Privacy Policies',
                contact_details: 'Contact Details',
                demo_videos: 'Demo Videos',
                preferences: 'Preferences',
                resources: 'Resources',
                refund_and_cancellation_policy: "Refund and Cancellation Policy"
            },
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Organization',
            },
            errors: {},
        },
        dashboard: {
            name: 'Dashboard',
            organization_dashboard: 'Dashboard',
            fields: {
                name: 'Name',
            },
            filters: {},
            fieldGroups: {},
            page: {},
            errors: {},
        },
        student_responses: {
            name: 'Student Responses',
            fields: {
                name: 'Name',
            },
            filters: {},
            fieldGroups: {},
            page: {},
            tabs: {
                details: 'Details',
                question_wise: 'Question Wise',
            },
            errors: {},
        },

        video_subjects: {
            name: 'Video Subject |||| Video Subjects',
            fields: {
                name: 'Name',
            },
            filters: {},
            fieldGroups: {},
            page: {},
            tabs: {
                details: 'Details',
                question_wise: 'Question Wise',
            },
            errors: {},
        },
        chapters: {
            name: 'Chapter |||| Chapters',
            fields: {
                name: 'Name',
            },
            filters: {},
            fieldGroups: {},
            page: {},
            tabs: {
                details: 'Details',
                question_wise: 'Question Wise',
            },
            errors: {},
        },
        demo_videos: {
            name: 'Demo Videos |||| Demo Videos',
            fields: {
                name: 'Name',
            },
            filters: {},
            fieldGroups: {},
            page: {},
            tabs: {
                details: 'Details',
                product: 'Product',
            },
            errors: {},
        },
        demo_video_categories: {
            name: 'Demo Video categories |||| Demo Video categories',
            fields: {
                name: 'Name',
            },
            filters: {},
            fieldGroups: {},
            page: {},
            tabs: {
                details: 'Details',
                product: 'Product',
            },
            errors: {},
        },

        videos: {
            name: 'Videos',
            fields: {
                name: 'Name',
            },
            filters: {},
            fieldGroups: {},
            page: {},
            tabs: {
                details: 'Details',
                logs: 'Logs',
                question_wise: 'Question Wise',
            },
            errors: {},
        },
        users: {
            name: 'User |||| Users',
            fields: {
                name: 'Name',
                first_name: 'First Name',
                last_name: 'Last Name',
            },
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete User',
            },
            tabs: {
                details: "User Details",
                orders: "Orders",
                addresses: "Addresses",
                doubts: "Doubts",
                issues: "Issues",
                devices: "Devices",
                logins: "Logins",
                reviews: "Reviews",
                test_series: "Test Series",
                batches: "Batches",
                permissions: "Permissions",
                programs: "programs",
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        students: {
            name: 'Student |||| Students',
            fields: {
                name: 'Name',
                first_name: 'First Name',
                last_name: 'Last Name',
            },
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Student',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },

        organization_resources: {
            name: 'Organization Resource |||| Organization Resources',
            fields: {
                name: 'Name',
            },
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Organization Resource',
            },
            errors: {

            },
        },

        faqs: {
            name: 'FAQ |||| FAQs',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete FAQ',
            },
            errors: {},
        },
        student_video_stats: {
            name: 'Student video stat |||| Student video stats',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Student video stat',
            },
            errors: {},
        },

        batch_schedule: {
            name: 'Batch Schedule |||| Batch Schedules',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete FAQ',
            },
            errors: {},
        },
        teachers: {
            name: 'Teacher |||| Teachers',
            fields: {
                name: 'Name',
                first_name: 'First Name',
                last_name: 'Last Name',
            },
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Teacher',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        banners: {
            name: 'Banner |||| Banners',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Banner',
            },
            errors: {},
        },
        contents: {
            name: 'Content |||| Contents',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Content',
            },
            errors: {},
        },
        programs: {
            name: 'Program |||| Programs',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Program',
            },
            errors: {},
            tabs: {
                details: "Details",
                product: "Product Details",
                user: "Users"
            },
        },

        batch_templates: {
            name: 'Batch Template |||| Batch Templates',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Batch Template',
            },
            errors: {},
        },
        batches: {
            name: 'Batch |||| Batches',
            fields: {},
            filters: {},
            fieldGroups: {},
            tabs: {
                details: "Details",
                students: "Students",
                teachers: "Teachers",
                product: "Product Details",
                subjects: "Subjects",
                schedules: "Schedules",
                homework: "Homework",
                exercise: "Exercises",
            },
            page: {
                delete: 'Delete Batch',
            },
            errors: {},
        },
        test_serieses: {
            name: 'Test Series |||| Test Serieses',
            fields: {},
            filters: {},
            fieldGroups: {},
            tabs: {
                details: "Details",
                students: "Students",
                product: "Product Details",
            },

            page: {
                delete: 'Delete Test Series',
            },
            errors: {},
        },
        categories: {
            name: 'Category |||| Categories',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Category',
            },
            errors: {},
        },
        blog_topics: {
            name: 'Blog Topic |||| Blog Topics',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Blog Topic',
            },
            errors: {},
        },
        product_templates: {
            name: 'Product Template |||| Product Templates',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Product Templates',
            },
            errors: {},
        },
        products: {
            name: 'Product |||| Product',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Product',
            },
            errors: {},
        },
        orders: {
            name: 'Order |||| Orders',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Order',
            },
            errors: {},
        },
        store: {
            name: 'Store |||| Stores',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Store',
            },
            errors: {},
        },
        reviews: {
            name: 'Review |||| Reviews',
            fields: {
                rating: 'Ratings'
            },
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Review',
            },
            errors: {},
        },
        issue_templates: {
            name: 'Issue Template |||| Issue Templates',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Issue Template',
            },
            errors: {},
        },
        issue_tags: {
            name: 'Issue Tag |||| Issue Tags',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Issue Tag',
            },
            errors: {},
        },
        issue_topics: {
            name: 'Issue Topics |||| Issue Topics',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Issue Topics',
            },
            errors: {},
        },
        issues: {
            name: 'Issues |||| Issues',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Issue',
            },
            errors: {},
        },
        issue_responses: {
            name: 'Issue Response |||| Issue Responses',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Issue Response',
            },
            errors: {},
        },
        podcast_topics: {
            name: 'Podcast Topic |||| Podcast Topics',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Podcast Topic',
            },
            errors: {},
        },
        podcasts: {
            name: 'Podcasts |||| Podcasts',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Podcasts',
            },
            errors: {},
        },
        download_categories: {
            name: 'Download Category |||| Download Categories',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Download Category',
            },
            errors: {},
        },
        downloads: {
            name: 'Download |||| Downloads',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Downloads',
            },
            errors: {},
        },
        jobs: {
            name: 'Job |||| Jobs',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Job',
            },
            errors: {},
        },
        webhook_logs: {
            name: 'Webhook Log |||| Webhook Logs',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Webhook Log',
            },
            errors: {},
        },
        doubts: {
            name: 'Doubt |||| Doubts',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Doubt',
            },
            errors: {},
        },
        queries: {
            name: 'Query |||| Queries',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete query',
            },
            errors: {},
        },
        batch_schedules: {
            name: 'Batch Schedule |||| Batch Schedules',
            fields: {
                start_time: "Start Time",
                end_time: "End Time",
            },
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Doubt',
            },
            errors: {},
        },
        blogs: {
            name: 'Blog |||| Blogs',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Blog',
            },
            errors: {},
        },
        coupons: {
            name: 'Coupon |||| Coupons',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Coupon',
            },
            errors: {},
        },
        otps: {
            name: 'OTP |||| OTPs',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete OTP',
            },
            errors: {},
        },
        app_versions: {
            name: 'App Version |||| App Versions',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete App Version',
            },
            errors: {},
        },
        testimonials: {
            name: 'Testimonial |||| Testimonials',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Testimonial',
            },
            errors: {},
        },
        user_permissions: {
            name: 'User Permission |||| User Permissions',
            fields: {},
            filters: {},
            fieldGroups: {},
            page: {
                delete: 'Delete Testimonial',
            },
            errors: {},
        },
        reports: {
            reports: 'reports',
            signups: "signups",
            tests: "tests",
            store_items: 'store items',
            orders: 'orders',
            batch_students: 'batch students',
            application_users: 'application users',
        }
    },
};

export default customEnglishMessages;
