import BatchesList from './BatchesList';
import BatchesEdit from './BatchesEdit';
import BatchesCreate from "./BatchesCreate";
import BatchesShow from "./BatchesShow";

export default {
    list: BatchesList,
    edit: BatchesEdit,
    create: BatchesCreate,
    show: BatchesShow,
};
