import * as React from 'react';
import {Create, TextInput, required, SimpleForm, SelectInput, ReferenceInput} from 'react-admin';
import { STATUSES} from "../../common/constants";
import {EditToolbar} from "../../common/EditToolbar";


const IssueTemplateCreate = () => (
    <Create redirect={'/cs_issue_templates'}>
        <SimpleForm  toolbar={<EditToolbar/>}>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <TextInput source="slug" fullWidth validate={[required()]}/>
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUSES}
            />
        </SimpleForm>
    </Create>
);

export default IssueTemplateCreate;
