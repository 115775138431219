import * as React from 'react';
import {Link, FieldProps, useRecordContext, ReferenceManyCount} from 'react-admin';
import {Programs, Test_Serieses,} from "../../custom";

const TestSeriesesLinkField = (props: FieldProps<Test_Serieses>) => {
    const record = useRecordContext<Test_Serieses>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/programs/${record.id}/test_serieses`}>
            Test Series
            (
            <ReferenceManyCount
                label="Test Serieses"
                reference="test_serieses"
                target="program_id"
            />
            )
        </Link>
    );
};

TestSeriesesLinkField.defaultProps = {
    label: 'Test Series'
};

export default TestSeriesesLinkField;
