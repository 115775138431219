import * as React from 'react';
import {

    List,
    EditButton,
    Datagrid,
    TextField, ReferenceField, NumberField,
} from 'react-admin';
import ThumbnailField from "../../common/ThumbnailField";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import FullNameField from "../../common/FullNameField";
import {DeleteWithDialogAction} from "../../common/DeleteWithDialogAction";


const BlogList = () => {
    return (
        <List sort={{field: 'created_at', order: 'DESC'}}>
            <Datagrid>
                <ThumbnailField source="thumbnail"/>
                <TextField source="title"/>
                <ReferenceField reference={'ce_blog_topics'} label={'Blog Topic'} source={'blog_topic_id'}>
                    <TextField source={'name'}/>
                </ReferenceField>
                <TextField source="slug"/>
                <NumberField source="display_order"/>
                <ReferenceField reference={'users'} label={'Author'} source={'created_by_id'}>
                    <FullNameField source={'first_name'}/>
                </ReferenceField>

                <FormattedDateTimeField source="created_at"/>
                
                <EditButton/>
                <DeleteWithDialogAction/>
            </Datagrid>
        </List>
    );
};
export default BlogList;
