import * as React from 'react';
import {Link, FieldProps, useRecordContext, ReferenceManyCount} from 'react-admin';
import {Chapters, Ecom_Order_Items, Subjects,} from "../../custom";

const OrderItemsFiled = (props: FieldProps<any>) => {
    const record = useRecordContext<Ecom_Order_Items>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/ecom_orders/${record?.id}/orderItems`}>
            Order Items
            (
            <ReferenceManyCount
                label="Order Items"
                reference="ecom_order_items"
                target="order_id"
            />
            )
        </Link>
    );
};

OrderItemsFiled.defaultProps = {};

export default OrderItemsFiled;
