import * as React from 'react';
import {Create, TextInput, required, SimpleForm, minValue,} from 'react-admin';
import ImageInput from "../../common/inputs/ImageInput";


const BlogTopicCreate = () => (
    <Create redirect={'/ce_blog_topics'}>
        <SimpleForm>
            <ImageInput source={'image'} fullWidth validate={[required()]}/>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <TextInput type={'number'} source="display_order" fullWidth validate={[required(),minValue(0)]}/>
        </SimpleForm>
    </Create>
);

export default BlogTopicCreate;
