import * as React from 'react';
import {
    Edit,
    TextInput,
    useTranslate,
    useRecordContext,
    required,
    SelectInput,
    TabbedForm,
    useDataProvider,
    Pagination,
    Datagrid,
    ReferenceField,
    ReferenceManyField, TextField, BooleanField, FunctionField, List, useRefresh, DateField,
} from 'react-admin';
import { useMutation, useQuery } from 'react-query';

import { Users } from "../../custom";
import { AllPermissions, BASE_URL, COUNTRY_CODES, USER_STATUSES } from "../../common/constants";
import ImageInput from "../../common/inputs/ImageInput";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Checkbox, CircularProgress, Divider, FormControlLabel } from '@mui/material';
import HTMLEditorInput, { validHtml } from "../../common/inputs/HTMLEditorInput";
import { EditToolbar } from "../../common/EditToolbar";
import FullNameField from "../../common/FullNameField";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { Public } from "@mui/icons-material";
import CurrencyField from "../../common/CurrencyField";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import PurchasedPrice from '../orders/PurchasedPrice';
import OrderItemsFiled from '../orders/OrderItemField';
import ThumbnailField from '../../common/ThumbnailField';
import ProductTemplatesLinkField from '../categories/ProductTemplateLinkField';
import StatusTags from '../../common/StatusTags';
import {
    FormattedStartEndField,
    ProductTemplateFieldBatches,
} from '../batches/BatchesList';
import { ProductTemplateField } from '../test_serieses/TestSeriesList';

export enum ClientType {
    Chrome = "Chrome",
    Firefox = "Firefox",
    Safari = "Safari",
    Edge = "Edge",
    InternetExplorer = "Internet Explorer",
    Opera = "Opera",
    Other = "Other"
}

function detectClientType(userAgent: string): ClientType {
    if (userAgent.includes("Chrome")) return ClientType.Chrome;
    if (userAgent.includes("Firefox")) return ClientType.Firefox;
    if (userAgent.includes("Safari")) return ClientType.Safari;
    if (userAgent.includes("Edge")) return ClientType.Edge;
    if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) return ClientType.InternetExplorer;
    if (userAgent.includes("Opera")) return ClientType.Opera;
    return ClientType.Other;
}

interface ClientDetectorProps {
    userAgent: string;
}

const ClientTypeField = () => {
    const record = useRecordContext();
    const clientType = detectClientType(record?.device_details);
    return <div className={'flex gap-4'}>
        {/*<div className={'px-2 py-0.5 bg-red-500 text-white rounded-md'}>{clientType}</div>*/}
        <div>
            {record.platform === 'WEB' && <Public className={'text-blue-500'} />}
        </div>
    </div>;
};
ClientTypeField.defaultProps = {
    label: 'Device Type'
}
const AssignAction = ({ refresh, permission, user_id }: { refresh: any, user_id: string, permission: string }) => {
    const dataProvider = useDataProvider()
    const { mutate, isLoading } = useMutation(
        () => dataProvider.create('acl_user_permissions', {
            data: { user_id, permission },
        },
        ),
        {}
    );
    return <div>
        <Checkbox onChange={() => {
            if (!isLoading)
                mutate();
        }} />
    </div>
}
const UnregisterDevice = () => {
    const dataProvider = useDataProvider()
    const refresh = useRefresh();
    const [open, setOpen] = useState(false);
    const { mutate, isLoading } = useMutation(
        () => dataProvider.update('user_devices', {
            id: record?.id,
            data: { manually_deactivated_at: new Date(), },
            previousData: {},
        },
        ),
        {
            onSuccess: () => {
                refresh();
            },
            onError: (e) => {
                console.log('here no', e)
            }
        }
    );
    const record = useRecordContext();
    if (record.manually_deactivated_at) {
        return <></>
    }
    return <div>
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Unregister Device
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Do you really want to unregister this device?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={() => setOpen(false)}>Cancel</Button>
                <Button disabled={isLoading} onClick={() => {
                    if (!isLoading)
                        mutate();
                }} autoFocus>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
        <Button color={'error'} onClick={() => {
            setOpen(true);
        }}>Unregister Device</Button>
    </div>
}
const EndSession = () => {
    const dataProvider = useDataProvider()
    const refresh = useRefresh();
    const [open, setOpen] = useState(false);
    const { mutate: mutateDevice } = useMutation(
        () => dataProvider.update('user_devices', {
            id: record?.user_device_id,
            data: { logged_out_at: new Date(), },
            previousData: {},
        },
        ),
        {
            onSuccess: () => {
                refresh();
            },
            onError: (e) => {
                console.log('here no', e)
            }
        }
    );
    const { mutate, isLoading } = useMutation(
        () => dataProvider.update('user_sessions', {
            id: record?.id,
            data: { logged_out_at: new Date(), },
            previousData: record,
        },
        ),
        {
            onSuccess: () => {
                console.log('here')
                mutateDevice();
            },
            onError: (e) => {
                console.log('here no', e)
            }
        }
    );
    const record = useRecordContext();
    if (record.logged_out_at) {
        return <></>
    }
    return <div>
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Logout User
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Do you really want to log this user out?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={() => setOpen(false)}>Cancel</Button>
                <Button disabled={isLoading} onClick={() => {
                    if (!isLoading)
                        mutate();
                }} autoFocus>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
        <Button color={'error'} onClick={() => {
            setOpen(true);
        }}>End Session</Button>
    </div>
}
const UnAssign = ({ refresh, data, }: { refresh: any, data: any, }) => {
    const dataProvider = useDataProvider()
    const { mutate, isLoading } = useMutation(
        () => dataProvider.update('acl_user_permissions', {
            id: data?.id,
            data: { deleted_at: new Date(), },
            previousData: data,
        },
        ),
        {
            onSuccess: () => {
                console.log('here')
            },
            onError: (e) => {
                console.log('here no', e)
            }
        }
    );
    return <div>
        <Checkbox defaultChecked={true} onChange={() => {
            if (!isLoading)
                mutate();
        }} />
    </div>
}
const UserPermission = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const { data: userPermissions, isLoading, error } = useQuery(
        ['user_permissions_' + record.id, 'getList', { user_id: record.id }],
        () => dataProvider.getList('acl_user_permissions', {
            filter: { user_id: record.id },
            pagination: { page: 1, perPage: 400 },
            sort: { field: 'created_at', order: 'ASC' }
        })
    );

    return <>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Selected</TableCell>
                        <TableCell>Permission</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {AllPermissions.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>
                                {
                                    userPermissions?.data?.find((userPermission) => userPermission.permission === row.name) ?
                                        <UnAssign
                                            data={userPermissions?.data?.find((userPermission) => userPermission.permission === row.name)}
                                            refresh={() => {

                                            }} /> : <AssignAction refresh={() => {
                                            }} user_id={record.id as string} permission={row.name} />
                                }
                            </TableCell>

                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}
const AdminTabs = () => {
    return <TabbedForm toolbar={<EditToolbar />}>
        <TabbedForm.Tab
            label="resources.users.tabs.details"
        >
            <ImageInput source="profile_image" label={'Profile Image'} fullWidth />
            <TextInput source="first_name" validate={[required()]} fullWidth />
            <TextInput source="last_name" validate={[required()]} fullWidth />
            <SelectInput source="country_code" choices={COUNTRY_CODES} fullWidth />
            <TextInput source="phone" validate={[required()]} fullWidth />
            <TextInput source="registration_number" fullWidth />
            <TextInput source="email" fullWidth />
            <TextInput disabled source="role" fullWidth />
            <TextInput source="admission_number" fullWidth />
        </TabbedForm.Tab>
        <TabbedForm.Tab
            label="resources.users.tabs.permissions"
            path="batches"
            sx={{ maxWidth: '40em' }}
        >
            <UserPermission />
        </TabbedForm.Tab>
    </TabbedForm>
}
const TeacherTabs = () => {
    return <TabbedForm toolbar={<EditToolbar />}>
        <TabbedForm.Tab
            label="resources.users.tabs.details"
        >
            <ImageInput source="profile_image" label={'Profile Image'} fullWidth />
            <TextInput source="first_name" validate={[required()]} fullWidth />
            <TextInput source="last_name" validate={[required()]} fullWidth />
            <SelectInput source="country_code" choices={COUNTRY_CODES} fullWidth />
            <TextInput source="phone" validate={[required()]} fullWidth />
            <TextInput source="registration_number" fullWidth />
            <TextInput source="email" fullWidth />
            <TextInput disabled source="role" fullWidth />
            <TextInput source="admission_number" fullWidth />
        </TabbedForm.Tab>

        <TabbedForm.Tab
            label="resources.users.tabs.batches"
            path="batches"
            sx={{ maxWidth: '40em' }}
        >

        </TabbedForm.Tab>
    </TabbedForm>
}

function generateRandomPassword(length: number) {
    const charset = "0123456789";
    let password = "";
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }
    return password;
}

const StudentTabs = () => {
    const [updatedPassword, setUpdatedPassword] = useState('');
    const [updatingPassword, setUpdatingPassword] = useState(false);
    const record = useRecordContext();
    return <TabbedForm toolbar={<EditToolbar />}>
        <TabbedForm.Tab
            label="resources.users.tabs.details">
            <ImageInput source="profile_image" label={'Profile Image'} fullWidth />
            <TextInput source="first_name" validate={[required()]} fullWidth />
            <TextInput source="last_name" validate={[required()]} fullWidth />
            <SelectInput source="country_code" choices={COUNTRY_CODES} fullWidth />
            <TextInput source="phone" validate={[required()]} fullWidth />
            <TextInput source="registration_number" fullWidth />
            <TextInput source="email" fullWidth />
            <TextInput disabled source="role" fullWidth />
            <TextInput source="admission_number" fullWidth />
            <SelectInput source="status" choices={USER_STATUSES} fullWidth />
            <Divider />
            <div className={'text-xl font-bold mb-2'}>
                Change Password
            </div>
            <div className={'flex space-x-2 items-center'}>
                {
                    updatedPassword && <div>{updatedPassword}</div>
                }
                {
                    updatingPassword ? <CircularProgress /> : <Button onClick={() => {
                        setUpdatingPassword(true);
                        const newP = generateRandomPassword(6);
                        fetch(BASE_URL + '/auth/admin/set-user-password', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                            },
                            body: JSON.stringify({ userId: record.id, password: newP }),
                        }).then((q: any) => {
                            return q.json();
                        }).then((q) => {
                            if (!q?.error) {
                                setUpdatedPassword(newP);
                            }
                        }).finally(() => {
                            setUpdatingPassword(false);
                        });
                    }}>
                        Update Password
                    </Button>
                }
            </div>

        </TabbedForm.Tab>

        <TabbedForm.Tab
            label="resources.users.tabs.orders"
            path="orders"
        >
            <ReferenceManyField
                label={'Orders'}
                reference="ecom_orders"
                target="created_by_id"
                pagination={<Pagination />}
            >
                <div className={'w-full'}>
                    <Datagrid
                        sx={{
                            width: '100%',
                            '& .column-comment': {
                                maxWidth: '20em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            },
                        }}
                        sort={{ field: 'created_at', order: 'DESC' }}
                    >
                        <TextField source="id" />
                        <TextField source="tracking_id" />
                        <TextField source="status" />
                        <TextField source="payment_source" />
                        <OrderItemsFiled />
                        {/* <CurrencyField source="amount" /> */}
                        {/* <CurrencyField source="discount" /> */}
                        {/* <CurrencyField source="tax" /> */}
                        <PurchasedPrice label="Amount" />
                        <FormattedDateTimeField source="created_at" />
                    </Datagrid>
                </div>
            </ReferenceManyField>
        </TabbedForm.Tab>

        <TabbedForm.Tab
            label="resources.users.tabs.programs"
            path="programs"
        >
            <ReferenceManyField
                label={'Programs'}
                reference="user_programs"
                target="user_id"
                pagination={<Pagination />}
            >
                <div className={'w-full'}>
                    <Datagrid
                        sx={{
                            width: '100%',
                            '& .column-comment': {
                                maxWidth: '20em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            },
                        }}
                        sort={{ field: 'created_at', order: 'DESC' }}
                    >
                        <TextField source="id" />
                        <ReferenceField reference={'programs'} source={'program_id'}>
                            <TextField source={'name'} />
                        </ReferenceField>
                        <ReferenceField reference={'users'} source={'assigned_by'}>
                            <TextField source={'first_name'} />
                        </ReferenceField>
                        <FormattedDateTimeField source="created_at" />
                    </Datagrid>
                </div>
            </ReferenceManyField>
        </TabbedForm.Tab>

        <TabbedForm.Tab
            label="resources.users.tabs.batches"
            path="batches"
        >
            <ReferenceManyField
                label={'Batches'}
                reference="batch_students"
                target="student_id"
            // pagination={<Pagination />}
            >
                <div className={'w-full'}>
                    <Datagrid
                        sx={{
                            width: '100%',
                            '& .column-comment': {
                                maxWidth: '20em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            },
                        }}
                        sort={{ field: 'created_at', order: 'DESC' }}
                    >
                        <ReferenceField reference={'batches'} source={'batch_id'} label="Image">
                            <ThumbnailField source="image" />
                        </ReferenceField>
                        <ReferenceField reference={'batches'} source={'batch_id'} label="Title">
                            <TextField source="title" />
                        </ReferenceField>
                        <ReferenceField reference={'batches'} source={'batch_id'} label="Product Template">
                            <ProductTemplateFieldBatches />
                        </ReferenceField>
                        <ReferenceField reference={'batches'} source={'batch_id'} label="Status">
                            <StatusTags source="status" label={'Status'} />
                        </ReferenceField>
                        <ReferenceField reference={'batches'} source={'batch_id'} label="Start - End">
                            <FormattedStartEndField source="start_time" />
                        </ReferenceField>
                        <ReferenceField reference={'batches'} source={'batch_id'} label="Template">
                            <ReferenceField reference={'batch_templates'} source={'template_id'}>
                                <TextField source="title" />
                            </ReferenceField>
                        </ReferenceField>
                        <FormattedDateTimeField source="created_at" />
                    </Datagrid>
                </div>
            </ReferenceManyField>
        </TabbedForm.Tab>

        <TabbedForm.Tab
            label="Purchased Subjects"
            path="purchased_subjects"
        >

            <ReferenceManyField
                label={'Purchased Subjects'}
                reference="student_subjects"
                target="student_id"
            // pagination={<Pagination />}
            >
                <div className={'w-full'}>
                    <Datagrid
                        sx={{
                            width: '100%',
                            '& .column-comment': {
                                maxWidth: '20em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            },
                        }}
                        sort={{ field: 'created_at', order: 'DESC' }}
                    >
                        <ReferenceField reference={'subjects'} source={'subject_id'} label="Image">
                            <ThumbnailField source="image" />
                        </ReferenceField>
                        <ReferenceField reference={'subjects'} source={'subject_id'} label="name">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField reference={'subjects'} source={'subject_id'} label="Status">
                            <StatusTags source="status" label={'Status'} />
                        </ReferenceField>
                        <DateField source='ends_on'/>
                        <FormattedDateTimeField source="created_at" />
                    </Datagrid>
                </div>
            </ReferenceManyField>
        </TabbedForm.Tab>

        <TabbedForm.Tab
            label="resources.users.tabs.test_series"
            path="test_series"
        >
            <ReferenceManyField
                label={'Test Series'}
                reference="student_test_series"
                target="student_id"
            // pagination={<Pagination />}
            >
                <Datagrid
                    sx={{
                        width: '100%',
                        '& .column-comment': {
                            maxWidth: '20em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        },
                    }}
                    sort={{ field: 'created_at', order: 'DESC' }}
                >

                    <ReferenceField reference={'test_serieses'} source={'test_series_id'} label="Image">
                        <ThumbnailField source="image" />
                    </ReferenceField>
                    <ReferenceField reference={'test_serieses'} source={'test_series_id'} label="Title">
                        <TextField source="title" />
                    </ReferenceField>
                    <ReferenceField reference={'test_serieses'} source={'test_series_id'} label="Product Template">
                        <ProductTemplateField />
                    </ReferenceField>
                    <ReferenceField reference={'test_serieses'} source={'test_series_id'} label="Slug">
                        <TextField source="slug" />
                    </ReferenceField>
                    <ReferenceField reference={'test_serieses'} source={'test_series_id'} label="Type">
                        <TextField source="type" />
                    </ReferenceField>
                    <ReferenceField reference={'test_serieses'} source={'test_series_id'} label="Status">
                        <StatusTags source="status" label={'Status'} />
                    </ReferenceField>
                    <ReferenceField reference={'test_serieses'} source={'test_series_id'} label="Program">
                        <ReferenceField reference={'programs'} source={'program_id'}>
                            <TextField source="name" />
                        </ReferenceField>
                    </ReferenceField>

                    {/* <TestsLinkField /> */}
                    <FormattedDateTimeField source="created_at" />
                </Datagrid>
            </ReferenceManyField>

        </TabbedForm.Tab>

        <TabbedForm.Tab
            label="resources.users.tabs.addresses"
            path="addresses"
            sx={{ maxWidth: '40em' }}
        >
            <HTMLEditorInput source="description" label="" validate={[required(), validHtml]} />
        </TabbedForm.Tab>

        <TabbedForm.Tab
            label="resources.users.tabs.doubts"
            path="doubts"
            sx={{ maxWidth: '40em' }}
        >
            <HTMLEditorInput source="description" label="" validate={[required(), validHtml]} />
        </TabbedForm.Tab>

        <TabbedForm.Tab
            label="resources.users.tabs.issues"
            path="issues"
            sx={{ maxWidth: '40em' }}
        >
            <HTMLEditorInput source="description" label="" validate={[required(), validHtml]} />
        </TabbedForm.Tab>

        <TabbedForm.Tab
            label="resources.users.tabs.reviews"
            path="reviews"
            sx={{ maxWidth: '40em' }}
        >
            <HTMLEditorInput source="description" label="" validate={[required(), validHtml]} />
        </TabbedForm.Tab>

        <TabbedForm.Tab
            label="resources.users.tabs.devices"
            path="devices"
        >

            <ReferenceManyField
                label={'User Devices'}
                reference="user_devices"
                target="user_id"
                sort={{ field: 'updated_at', order: 'DESC' }}
                pagination={<Pagination />}
            >
                <Datagrid
                    sx={{
                        width: '100%',
                        '& .column-comment': {
                            maxWidth: '20em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        },
                    }}
                    sort={{ field: 'created_at', order: 'DESC' }}
                >
                    <TextField source="spirit_code" />
                    <ClientTypeField />
                    <BooleanField source="blocked" />
                    <FormattedDateTimeField source="manually_deactivated_at" />
                    {/*<FormattedDateTimeField source="last_activity_at"/>*/}
                    <FormattedDateTimeField source="created_at" />
                    <UnregisterDevice />
                </Datagrid>
            </ReferenceManyField>
        </TabbedForm.Tab>

        <TabbedForm.Tab
            label="resources.users.tabs.logins"
            path="logins"
        >
            <div className={'w-full'}>
                <FunctionField
                    render={(user: Users) => (
                        <List
                            pagination={<Pagination />}
                            sort={{ field: 'logged_in_at', order: 'DESC' }}
                            resource={'user_sessions'} filter={{
                                device: {
                                    format: 'hasura-raw-query',
                                    value: {
                                        user_id: {
                                            _eq: user?.id
                                        }
                                    },
                                }
                            }}
                        >
                            <Datagrid
                                sx={{
                                    width: '100%',
                                    '& .column-comment': {
                                        maxWidth: '20em',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    },
                                }}
                                sort={{ field: 'created_at', order: 'DESC' }}
                            >
                                <ReferenceField reference={'user_devices'} source={'user_device_id'}>
                                    <TextField source={'spirit_code'} />
                                </ReferenceField>
                                <FormattedDateTimeField source="logged_in_at" />
                                <FormattedDateTimeField source="logged_out_at" />
                                <EndSession />
                            </Datagrid>
                        </List>
                    )}
                />
            </div>
        </TabbedForm.Tab>

    </TabbedForm>
}
const Middleware = () => {
    const record = useRecordContext();
    if (record.role === "ORG_ADMIN") {
        return <AdminTabs />
    }
    if (record.role === "TEACHER") {
        return <TeacherTabs />
    }
    if (record.role === "STUDENT") {
        return <StudentTabs />
    }
    return <></>
}
const UserEdit = () => (
    <Edit title={<UserTile />}>
        <Middleware />
    </Edit>
);

const UserTile = () => {
    const record = useRecordContext<Users>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.users.name', { smart_count: 1 })} &quot;
            {record.first_name}&quot;
            {record.last_name}&quot;
        </span>
    ) : null;
};

export default UserEdit;
