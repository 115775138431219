import * as React from 'react';
import { Fragment, useCallback } from 'react';
import {
    Count,
    DatagridConfigurable,
    DateField,
    EditButton,
    ExportButton,
    FilterButton,
    List,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TopToolbar, useGetIdentity,
    useListContext,
} from 'react-admin';
import { Divider, Tabs, Tab, Typography } from '@mui/material';
import ThumbnailField from "../../common/ThumbnailField";
import { AccountCircle, Image, Videocam } from "@mui/icons-material";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import { Roles } from "../../common/constants";
import FullNameField from "../../common/FullNameField";
import { BulkUploadsUsersAction } from '../../common/inputs/BulkUsersUploaad';


const ListActions = () => {
    const navigate = useNavigate();
    return <TopToolbar>
       < BulkUploadsUsersAction/>
        <Button
            startIcon={<AccountCircle />}
            onClick={() => {
                navigate(`/users/create?role=ORG_ADMIN`)
            }}
            sx={{ padding: '0 5px' }}>
            Add Org User
        </Button>
        <Button
            startIcon={<AccountCircle />}
            onClick={() => {
                navigate(`/users/create?role=TEACHER`)
            }}
            sx={{ padding: '0 5px' }}>
            Add Teacher
        </Button>
        <Button
            startIcon={<AccountCircle />}
            onClick={() => {
                navigate(`/users/create?role=STUDENT`)
            }}
            sx={{ padding: '0 5px' }}>
            Add Student
        </Button>
        <SelectColumnsButton />
        <FilterButton />
        <ExportButton />
    </TopToolbar>
};

const UserList = () => {
    const { data } = useGetIdentity();
    if (data?.role === Roles.ADMIN) {
        return <div>
            <Typography>You can see Users after logging in to organization account</Typography>
        </div>
    }
    return <List
        filterDefaultValues={{ role: 'ORG_ADMIN' }}
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={25}
        filters={userFilters}
        actions={<ListActions />}
    >
        <TabbedDatagrid />
    </List>
};

const userFilters = [
    <SearchInput source="phone" alwaysOn />,
    <SearchInput source="registration_number" />,
    <SearchInput source="id" />,
];


const tabs = [
    { id: 'ORG_ADMIN', name: 'Org User' },
    { id: 'STUDENT', name: 'Students' },
    { id: 'TEACHER', name: 'Teachers' },
];

const TabbedDatagrid = () => {
    const listContext = useListContext();
    const { filterValues, setFilters, displayedFilters } = listContext;

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
                setFilters(
                    { role: value },
                    displayedFilters,
                    false // no debounce, we want the filter to fire immediately
                );
        },
        [displayedFilters, filterValues, setFilters]
    );

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.role}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            <span>
                                {choice.name} (
                                <Count
                                    filter={{
                                        role: choice.id,
                                    }}
                                    sx={{ lineHeight: 'inherit' }}
                                />
                                )
                            </span>
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider />
            <>
                {filterValues.role === 'ORG_ADMIN' && (
                    <DatagridConfigurable
                        // rowClick="edit"
                        omit={['value']}

                    >
                        <FullNameField />
                        <TextField source="id" />
                        <TextField source="country_code" />
                        <TextField source="phone" />
                        <TextField source="email" />
                        <TextField source="role" />
                        <FormattedDateTimeField source="created_at" />

                        <EditButton />
                        <DeleteWithDialogAction />
                    </DatagridConfigurable>
                )}
                {filterValues.role === 'STUDENT' && (
                    <DatagridConfigurable
                        // rowClick="edit"
                        omit={['value']}

                    >
                        <FullNameField />
                        <TextField source="country_code" />
                        <TextField source="phone" />
                        <TextField source="email" />
                        <TextField source="role" />
                        <TextField source="status" />
                        <FormattedDateTimeField source="created_at" />

                        <EditButton />
                        <DeleteWithDialogAction />
                    </DatagridConfigurable>
                )}
                {filterValues.role === 'TEACHER' && (
                    <DatagridConfigurable
                        // rowClick="edit"
                        omit={['value']}
                    >
                        <FullNameField />
                        <TextField source="country_code" />
                        <TextField source="phone" />
                        <TextField source="email" />
                        <TextField source="role" />
                        <FormattedDateTimeField source="created_at" />

                        <EditButton />
                        <DeleteWithDialogAction />
                    </DatagridConfigurable>
                )}

            </>
        </Fragment>
    );
};

export default UserList;
