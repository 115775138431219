import {
    AccountCircle, BugReport, BugReportOutlined, Campaign, Category, ContactSupport,
    CorporateFare, CurrencyRupee, Dashboard, Download, Forum,
    Image, ImportantDevices, Key, LocalActivity,
    LocalLibrary,
    LocalLibraryOutlined, Newspaper, OndemandVideo, Pin, PsychologyAlt, QuestionAnswer, Task, VerifiedUser,
    ViewInAr, Webhook, YouTube
} from "@mui/icons-material";

const MenuOptions = [
    [
        {
            name: 'resources.dashboard.organization_dashboard',
            to: '/',
            icon: <Dashboard/>,
            permission: 'organization_dashboard',
            children: []
        },
        {
            name: 'resources.organizations.name',
            to: '/organizations',
            icon: <CorporateFare/>,
            permission: 'organizations',
            children: []
        },
        {
            name: 'resources.organization_resources.name',
            to: '/organization_resources',
            icon: <CorporateFare/>,
            permission: 'organization_resources',
            children: []
        },

        {
            name: 'resources.users.name',
            to: '/users',
            permission: 'users',
            icon: <AccountCircle/>,
            children: []
        },
        {
            name: 'Deleted Users',
            to: '/deleted_users',
            permission: 'deleted_users',
            icon: <AccountCircle/>,
            children: []
        },
        {
            name: 'resources.banners.name',
            to: '/banners',
            permission: 'banners',
            icon: <Image/>,
            children: []
        },
        {
            name: 'resources.testimonials.name',
            to: '/testimonials',
            permission: 'testimonials',
            icon: <VerifiedUser/>,
            children: []
        },
        {
            name: 'resources.user_permissions.name',
            to: '/acl_user_permissions',
            icon: <Key/>,
            permission: 'acl_user_permissions',
            children: []
        },
        {
            name: 'resources.faqs.name',
            to: '/faqs',
            permission: 'faqs',
            icon: <QuestionAnswer/>,
            children: []
        },
    ],
    [
        {
            name: 'resources.programs.name',
            to: '/programs',
            permission: 'programs',
            icon: <ViewInAr/>,
            children: []
        },
        {
            name: 'resources.batch_templates.name',
            permission: 'batch_templates',
            to: '/batch_templates',
            icon: <LocalLibraryOutlined/>,
            children: []
        },
        {
            name: 'resources.batches.name',
            to: '/batches',
            permission: 'batches',
            icon: <LocalLibrary/>,
            children: []
        },
        {
            name: 'resources.demo_videos.name',
            to: '/demo_video_categories',
            permission: 'demo_video_categories',
            icon: <OndemandVideo/>,
            children: []
        },
        {
            name: 'resources.doubts.name',
            to: '/doubts',
            permission: 'doubts',
            icon: <ContactSupport/>,
            children: []
        },
        {
            name: 'resources.queries.name',
            to: '/queries',
            icon: <PsychologyAlt/>,
            children: []
        },
    ],
    [
        // {
        //     name: 'resources.store.name',
        //     to: '/store',
        //     permission: 'store',
        //     icon: <Category/>,
        //     children: []
        // },
        {
            name: 'resources.categories.name',
            to: '/ecom_categories',
            permission: 'ecom_categories',
            icon: <Category/>,
            children: []
        },
        {
            name: 'resources.coupons.name',
            to: '/ecom_coupons',
            permission: 'ecom_coupons',
            icon: <LocalActivity/>,
            children: []
        },
        {
            name: 'resources.orders.name',
            to: '/ecom_orders',
            permission: 'ecom_orders',
            icon: <CurrencyRupee/>,
            children: []
        },
    ],
    [
        {
            name: 'resources.issue_templates.name',
            to: '/cs_issue_templates',
            permission: 'cs_issue_templates',
            icon: <BugReportOutlined/>,
            children: []
        },
        {
            name: 'resources.issue_topics.name',
            to: '/cs_issue_topics',
            permission: 'cs_issue_topics',
            icon: <BugReportOutlined/>,
            children: []
        },

        {
            name: 'resources.issues.name',
            to: '/cs_issues',
            permission: 'cs_issues',
            icon: <BugReport/>,
            children: []
        },
        {
            name: 'resources.issue_responses.name',
            to: '/cs_issue_responses',
            permission: 'cs_issue_responses',
            icon: <Forum/>,
            children: []
        },

    ],
    [
        {
            name: 'Segments',
            to: '/segmentations',
            permission: 'segmentations',
            icon: <BugReportOutlined/>,
            children: []
        },
        {
            name: 'Campaigns',
            to: '/campaigns',
            permission: 'campaigns',
            icon: <BugReportOutlined/>,
            children: []
        },
    ],
    [
        {
            name: 'resources.download_categories.name',
            to: '/download_categories',
            permission: 'download_categories',
            icon: <Download/>,
            children: []
        },
        {
            name: 'resources.downloads.name',
            to: '/downloads',
            permission: 'downloads',
            icon: <Download/>,
            children: []
        },
        {
            name: 'resources.podcast_topics.name',
            to: '/podcast_topics',
            permission: 'podcast_topics',
            icon: <Campaign/>,
            children: []
        },
        {
            name: 'resources.podcasts.name',
            to: '/podcasts',
            permission: 'podcasts',
            icon: <Campaign/>,
            children: []
        },

    ],
    [
        {
            name: 'resources.blog_topics.name',
            to: '/ce_blog_topics',
            permission: 'ce_blog_topics',
            icon: <Newspaper/>,
            children: []
        },
        {
            name: 'resources.blogs.name',
            to: '/ce_blogs',
            permission: 'ce_blogs',
            icon: <Newspaper/>,
            children: []
        },

    ],
    [
        {
            name: 'resources.app_versions.name',
            to: '/app_versions',
            permission: 'app_versions',
            icon: <ImportantDevices/>,
            children: []
        },
        {
            name: 'resources.otps.name',
            to: '/otps',
            permission: 'otps',
            icon: <Pin/>,
            children: []
        },
        {
            name: 'resources.jobs.name',
            to: '/bp_jobs',
            permission: 'bp_jobs',
            icon: <Task/>,
            children: []
        },
        {
            name: 'resources.webhook_logs.name',
            to: '/bp_webhook_logs',
            permission: 'bp_webhook_logs',
            icon: <Webhook/>,
            children: []
        },
        {
            name: 'resources.video_subjects.name',
            to: '/video_subjects',
            permission: 'video_subjects',
            icon: <Webhook/>,
            children: []
        },
        {
            name: 'resources.videos.name',
            to: '/videos',
            permission: 'videos',
            icon: <Webhook/>,
            children: []
        },
    ],
    [
        {
            name: 'resources.reports.reports',
            to: '/report/signups',
            permission: 'report_user_signups',
            icon: <Webhook/>,
            children: [
                {
                    name: 'resources.reports.signups',
                    to: '/report/signups',
                    permission: 'report_user_signups',
                    icon: <Webhook/>,
                    children: []
                },
                {
                    name: 'resources.reports.tests',
                    to: '/report/tests',
                    permission: 'report_tests',
                    icon: <Webhook/>,
                    children: []
                },
                {
                    name: 'resources.reports.store_items',
                    to: '/report/store_items',
                    permission: 'report_store_items',
                    icon: <Webhook/>,
                    children: []
                },
                {
                    name: 'resources.reports.orders',
                    to: '/report/orders',
                    permission: 'report_orders',
                    icon: <Webhook/>,
                    children: []
                },
                {
                    name: 'resources.reports.batch_students',
                    to: '/report/batch_students',
                    permission: 'report_batch_students',
                    icon: <Webhook/>,
                    children: []
                },
                {
                    name: 'resources.reports.application_users',
                    to: '/report/application_users',
                    permission: 'report_application_users',
                    icon: <Webhook/>,
                    children: []
                }
            ]
        }
    ]

]
export default MenuOptions
