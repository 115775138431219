import * as React from 'react';
import {

    List,
    Datagrid,
    TextField, NumberField, BooleanField,
} from 'react-admin';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';

const AppVersionList = () => {
    return (
        <div>
            <List sort={{field: 'created_at', order: 'DESC'}}>
                <Datagrid>
                    <TextField source="version"/>
                    <TextField source="client_type"/>
                    <NumberField source="total_number_of_active_devices"/>
                    <BooleanField source="hard_update"/>
                    <BooleanField source="is_public"/>
                    <FormattedDateTimeField source="expiring_on"/>
                    <FormattedDateTimeField source="created_at"/>
                    
                </Datagrid>
            </List>
        </div>
    );
};
export default AppVersionList;
