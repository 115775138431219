import * as React from 'react';
import {Create, TextInput, required, SimpleForm, SelectInput, ReferenceInput} from 'react-admin';
import {STATUSES} from "../../common/constants";


const IssueTopicCreate = () => (
    <Create redirect={'/cs_issue_topics'}>
        <SimpleForm>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <TextInput source="slug" fullWidth validate={[required()]}/>
            <ReferenceInput label="Issue Template" source="issue_template_id" reference="cs_issue_templates">
                <SelectInput optionText="name" fullWidth/>
            </ReferenceInput>
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUSES}
            />
        </SimpleForm>
    </Create>
);

export default IssueTopicCreate;
