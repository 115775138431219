import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required, ReferenceInput, SelectInput,
} from 'react-admin';
import {Subjects} from "../../custom";
import ImageInput from "../../common/inputs/ImageInput";
import {EditToolbar} from "../../common/EditToolbar";

import {useParams} from "react-router";
import {LoadingSection} from "../../common/LoadingSection";
import HTMLEditorInput, {validHtml} from '../../common/inputs/HTMLEditorInput';
import {STATUSES} from "../../common/constants";


const SubjectEdit = () => {
    let {programId, subjectId} = useParams();
    if (!subjectId) {
        return <LoadingSection/>
    }
    return <Edit  mutationMode={'pessimistic'} title={<SubjectTitle/>} redirect={() => {
        return `programs/${programId}/subjects`
    }} resource={'subjects'} id={subjectId}>
        <SimpleForm toolbar={<EditToolbar/>}>
            <ImageInput source="image" fullWidth/>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <TextInput source="slug" fullWidth validate={[required()]}/>
            <TextInput source="display_order" type={'number'} fullWidth validate={[required()]}/>
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUSES}
            />
            <ReferenceInput label="Program" source="program_id" reference="programs">
                <SelectInput optionText="name" disabled fullWidth validate={[required()]}/>
            </ReferenceInput>
            <HTMLEditorInput source={'description'} fullWidth validate={[required(), validHtml]}/>
        </SimpleForm>
    </Edit>
};

const SubjectTitle = () => {
    const record = useRecordContext<Subjects>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.subjects.name', {smart_count: 1})} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default SubjectEdit;
