import * as React from 'react';
import {
    Datagrid,
    Edit,
    EditButton,
    NumberField,
    Labeled,
    ReferenceManyField,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, NullableBooleanInput, required,
} from 'react-admin';

import {Banners, Faqs,} from "../../custom";
import ImageInput from "../../common/inputs/ImageInput";
import {EditToolbar} from "../../common/EditToolbar";
import HTMLEditorInput, {validHtml} from "../../common/inputs/HTMLEditorInput";


const FAQEdit = () => (
    <Edit title={<Title/>}>
        <SimpleForm toolbar={<EditToolbar />}>
            <TextInput source="question" fullWidth validate={[required()]}/>
            <HTMLEditorInput source={'answer'} fullWidth validate={[required(), validHtml]}/>
            <TextInput type={'number'} source="display_order" fullWidth/>
        </SimpleForm>
    </Edit>
);

const Title = () => {
    const record = useRecordContext<Faqs>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.faqs.name', {smart_count: 1})} &quot;
            {record.display_order}&quot;
        </span>
    ) : null;
};

export default FAQEdit;
