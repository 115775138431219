import * as React from 'react';
import {
    Create,
    TextInput,
    NullableBooleanInput,
    SimpleForm,
    DateInput,
    SelectInput,
    required,
    ReferenceInput
} from 'react-admin';
import HTMLEditorInput, {validHtml} from '../../common/inputs/HTMLEditorInput';
import ImageInput from "../../common/inputs/ImageInput";


const TestimonialCreate = () => (
    <Create redirect={'/testimonials'}>
        <SimpleForm>
            <ImageInput source="author_image" fullWidth validate={[required()]}/>
            <TextInput source="author_name" fullWidth validate={[required()]}/>
            <TextInput source="display_order" type={'number'} fullWidth validate={[required()]}/>
            <HTMLEditorInput source="description" fullWidth validate={[required(), validHtml]}/>
            <ReferenceInput label="Batch Template" source="batch_template_id" reference="batch_templates">
                <SelectInput optionText="title" fullWidth/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export default TestimonialCreate;
