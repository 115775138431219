import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  useTranslate,
  useRecordContext, required, ReferenceInput, SelectInput, FormDataConsumer, ImageInput,
} from 'react-admin';
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import HTMLEditorInput, { validHtml } from "../../common/inputs/HTMLEditorInput";
import VideoAutocompleteInput from '../../common/inputs/VideoAutocompleteInput';


const DemoVideoEdit = () => {
  let { categoryId, demoVideoId } = useParams();
  if (!demoVideoId) {
    return <LoadingSection />
  }
  return <Edit mutationMode={'pessimistic'} title={<DemoVideoTitle />} redirect={() => {
    return `demo_video_categories/${categoryId}/demo_video`
  }} resource={'demo_videos'} id={demoVideoId}>
    <SimpleForm
      defaultValues={{ demo_video_category_id: categoryId }}
    >
      <ImageInput source="image" fullWidth />
      <TextInput source="title" fullWidth validate={[required()]} />
      <TextInput source="video_url" fullWidth validate={[required()]} />
      <TextInput source="title" fullWidth validate={[required()]} />
      <FormDataConsumer validate={[required()]} >
        {
          ({ formData, ...rest }) => <VideoAutocompleteInput formData={formData} fullWidth
            source={'video_id'} />
        }
      </FormDataConsumer>
      <HTMLEditorInput source={'description'} fullWidth validate={[required(), validHtml]} />
    </SimpleForm>
  </Edit>
};

const DemoVideoTitle = () => {
  const record = useRecordContext<any>();
  const translate = useTranslate();
  return record ? (
    <span>
      {translate('resources.demo_videos.name', { smart_count: 1 })} &quot;
      {record.title}&quot;
    </span>
  ) : null;
};

export default DemoVideoEdit;
