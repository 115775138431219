import * as React from 'react';
import {
    Create,
    SimpleForm,
    SelectInput,
    required, TextInput, minValue, ArrayInput, SimpleFormIterator, DateInput, AutocompleteInput, ReferenceInput,
} from 'react-admin';
import {SEGMENTATION_MODULE} from "../../common/constants";
import {Batches, Programs} from "../../custom";


export const operatorChoices = [
    { id: 'AND', name: 'AND' },
    { id: 'OR', name: 'OR' }
];
export const ConditionTypeChoice = [
    { id: 'BATCH', name: 'Batch' },
];
export const ConditionMethodsChoice = [
    { id: '=', name: '= Equals' },

];
export const BatchRender = (choice: Batches) => `${choice.title}`;

const SegmentCreate = () => (
    <Create redirect={'/segmentations'}>
        <SimpleForm defaultValues={{condition: {}}}>
            <TextInput source="name" fullWidth validate={[minValue(0)]}/>

            <SelectInput
                source="module"
                label={'Module'}
                fullWidth
                validate={[required()]}
                choices={SEGMENTATION_MODULE}
            />
            <ArrayInput source="condition">
                <SimpleFormIterator>
                    <SelectInput fullWidth source="type" label="Type" choices={ConditionTypeChoice}/>
                    <SelectInput fullWidth source="method" label="Method" choices={ConditionMethodsChoice}/>
                    <ReferenceInput name={'Batch'} source={'value'} reference={'batches'}
                                    fullwidth>
                        <AutocompleteInput optionText={BatchRender}
                                           debounce={500} fullWidth />
                    </ReferenceInput>
                    <SelectInput source="operator" fullWidth label="Operator" choices={operatorChoices}/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);

export default SegmentCreate;
