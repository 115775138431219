import Button from "@mui/material/Button";
import {CloudUpload, CloudUploadOutlined, RemoveCircle} from "@mui/icons-material";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useCreate, useDataProvider, useRecordContext, useRefresh, useResourceContext} from "react-admin";
import {useMutation} from "react-query";
import BulkTestInput from "../../common/inputs/BulkTestInput";
import {Tooltip} from "@mui/material";

function BulkUploadDialog({open, setOpen}: { open: boolean, setOpen: any }) {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                Bulk Upload Test
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <BulkTestInput onSuccess={(key: string) => {
                        handleClose();
                    }}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}


export const BulkUploadQuestionsAction = ({}: any) => {
    const [open, setOpen] = React.useState(false);
    return <>
        <BulkUploadDialog open={open} setOpen={setOpen}/>
        <Tooltip title="Bulk Upload">
            <Button onClick={() => {
                setOpen(true)
            }}>
                <CloudUploadOutlined/>
            </Button>
        </Tooltip>
    </>
};

