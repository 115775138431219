import {Button} from "@mui/material";
import {useState} from "react";
import {CheckCircle, UploadFileOutlined} from "@mui/icons-material";
import {useRecordContext} from "react-admin";
import {BASE_URL} from "../constants";
import {LoadingSection} from "../LoadingSection";

export const uploadFile = (testId: string, questions: any, images: any) => {
    const formData = new FormData();
    formData.append("questions", questions);
    formData.append("images", images);

    return fetch(BASE_URL + "/admin/tests/" + testId + "/upload-tests", {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });
};
const BulkTestInput = (props: any = {}) => {
    const record = useRecordContext();
    const [loading, setLoading] = useState(false);
    const [questionsLoading, setQuestionsLoading] = useState(false);
    const [imagesUploaded, setImagesUploaded] = useState(false);
    const [questionsFile, setQuestionsFile] = useState(null as any);
    const [imagesFile, setImagesFile] = useState(null as any);

    return (
        <>
            <div style={{position: "relative", width: '500px'}}>
                {
                    loading ? <LoadingSection/> : <>
                        <div style={{display: 'flex', alignItems: 'center'}}>

                            {
                                imagesFile ? <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                                    <CheckCircle style={{color: 'green'}}/> Images Uploaded</div> : <Button>
                                    Add Image File
                                    <input
                                        placeholder={props.placeholder}
                                        style={{
                                            position: "absolute",
                                            left: "0",
                                            right: "0",
                                            bottom: "0",
                                            top: "0",
                                            opacity: "0",
                                        }}
                                        accept="application/zip"
                                        multiple={false}
                                        value={""}
                                        onChange={async (e) => {
                                            const file = e.target.files?.[0];
                                            setImagesFile(file);
                                        }}
                                        type={"file"}
                                    />
                                </Button>
                            }
                        </div>

                        <div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {
                                    questionsFile ? <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                                            <UploadFileOutlined/>
                                            <Button onClick={async (e) => {
                                                try {
                                                    setLoading(true);
                                                    const res = await uploadFile(record.id?.toString(), questionsFile, imagesFile);
                                                    if (res?.status === 201 || res?.status === 200) {
                                                        const data = await res.json();
                                                        props.onSuccess();
                                                    } else {
                                                        console.log(res);
                                                        alert("Unable to upload questions, check console for error");
                                                    }
                                                } catch (error) {
                                                    console.log(error);

                                                    alert("Unable to upload questions, check console for error");
                                                }
                                                setLoading(false);
                                            }}>
                                                Upload Excel File
                                            </Button>
                                        </div> :
                                        <Button disabled={loading || !imagesFile}>
                                            Add Excel file

                                            <input
                                            accept={".xlsx,.xls"}
                                                placeholder={props.placeholder}
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    right: "0",
                                                    bottom: "0",
                                                    top: "0",
                                                    opacity: "0",
                                                }}
                                                multiple={false}
                                                value={""}
                                                onChange={async (e) => {
                                                    const file = e.target.files?.[0];
                                                    setQuestionsFile(file);
                                                }}
                                                type={"file"}
                                            />
                                        </Button>
                                }
                            </div>

                        </div>
                    </>
                }

            </div>
        </>
    );
};
export default BulkTestInput;
