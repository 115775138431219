import UserList from './UserList';
import UserEdit from './UserEdit';
import {AccountCircle} from "@mui/icons-material";
import UserCreate from "./UserCreate";

export default {
    list: UserList,
    edit: UserEdit,
    create: UserCreate,
    icon: AccountCircle,
};
