import * as React from 'react';
import {
    BooleanInput,
    Button,
    DateField,
    Edit,
    Form,
    Labeled,
    ReferenceField,
    SelectInput,
    TextField,
    Toolbar,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, Box, Grid, Typography, Link } from '@mui/material';

import Basket from './Basket';
import Totals from './Totals';
import { Ecom_Orders, Users } from "../../custom";
import "./index.css"

const OrderEdit = () => {
    const handlePrint = () => {
        window.print();
    };
    return (<div >
        <div className="print-button pt-5 flex  justify-end w-4/5 ">
        <Button className='' label="Print" onClick={handlePrint} />
        </div>
        <Edit title={<OrderTitle />} component="div">
            <OrderForm />
        </Edit>
    </div>
    )
};

const OrderTitle = () => {
    const translate = useTranslate();
    const record = useRecordContext<Ecom_Orders>();
    return record ? (
        <span>
            {translate('resources.orders.name', { smart_count: 1 })}
            {record.tracking_id}
        </span>
    ) : null;
};

const CustomerDetails = () => {
    const record = useRecordContext<Users>();
    return (
        <div>
            <Typography
                component={RouterLink}
                color="primary"
                to={`/customers/${record?.id}`}
                style={{ textDecoration: 'none' }}
            >
                {record?.first_name} {record?.last_name}
            </Typography>
            <Typography
                component={Link}
                color="primary"
                href={`mailto:${record?.email}`}
                style={{ textDecoration: 'none' }}
            >
                {record?.email}
            </Typography>
        </div>
    );
};


const CustomerAddressField = () => {
    const record = useRecordContext();
    console.log("record",record?.address)
    return (
        <div>
            <Typography>
                {record?.address?.first_name} {record?.address?.last_name}
            </Typography>
            <Typography>{record?.address?.phone} </Typography>
            <Typography>{record?.address?.email} </Typography>
            <Typography>{record?.address?.address_line} </Typography>
            <Typography>{record?.address?.landmark} </Typography>
            <Typography>{record?.address?.locality},{record?.address?.city},{record?.address?.district} </Typography>
            <Typography>{record?.address?.pincode} </Typography>
            <Typography>{record?.address?.state} </Typography>
            {/*<Typography>*/}
            {/*    {record?.city}, {record?.stateAbbr} {record?.zipcode}*/}
            {/*</Typography>*/}
        </div>
    );
};

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const OrderForm = () => {
    const record = useRecordContext<Ecom_Orders>();
    return (<Form className='flex justify-center'>
        <Box id="printt" className="print-only">
            <Card>
                <CardContent className='w-[50vw]'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={8}>
                            <Typography variant="h6" gutterBottom>
                                Order
                            </Typography>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Labeled source="created_at">
                                        <DateField source="created_at" />
                                    </Labeled>
                                </Grid>
                                {/* <Grid item xs={12} sm={12} md={6}>
                                    <Labeled source="reference">
                                        <TextField source="reference" />
                                    </Labeled>
                                </Grid> */}
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6}>
                                    <SelectInput
                                        source="status"
                                        defaultValue={record?.status ? record?.status : "UNKNOWN"}
                                        choices={[
                                            {
                                                id: "DELIVERED",
                                                name: "DELIVERED",
                                            },
                                            {
                                                id: 'ORDERED',
                                                name: 'ORDERED',
                                            },
                                            {
                                                id: 'CANCELLED',
                                                name: 'CANCELLED',
                                            },
                                            {
                                                id: "PENDING",
                                                name: "PENDING",
                                            },
                                            {
                                                id: "RECEIVED",
                                                name: "RECEIVED",
                                            },
                                            {
                                                id: "SHIPPED",
                                                name: "SHIPPED",
                                            },
                                            {
                                                id: "RETURNED",
                                                name: "RETURNED",
                                            },
                                            {
                                                id: 'UNKNOWN',
                                                name: 'UNKNOWN',
                                                disabled: true,
                                            },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box mt={2}>
                                        <BooleanInput
                                            row={true}
                                            source="returned"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Typography variant="h6" gutterBottom>
                                Student
                            </Typography>
                            <ReferenceField
                                source="created_by_id"
                                reference="users"
                                link={false}
                            >
                                <CustomerDetails />
                            </ReferenceField>
                            <Spacer />

                            <Typography variant="h6" gutterBottom>
                                Address
                            </Typography>
                            <CustomerAddressField/>
                            {/* <TextField source="address" /> */}
                        </Grid>
                    </Grid>
                    <Spacer />
                    <Typography variant="h6" gutterBottom>
                        Order items
                    </Typography>
                    <div>
                        <Basket />
                    </div>
                    <Spacer />

                    <Typography variant="h6" gutterBottom>
                        Total
                    </Typography>
                    <div>
                        <Totals />
                    </div>
                </CardContent>
                <Toolbar />
            </Card>
        </Box>
    </Form>
    );
};

export default OrderEdit;