import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext,
} from 'react-admin';

import {Banners,} from "../../custom";
import {EditToolbar} from "../../common/EditToolbar";
import {useNavigate} from "react-router-dom";

const StudentVideoStatEdit = () => {
    const navigate = useNavigate();
    return <Edit title={<BannerTitle/>} mutationMode={'pessimistic'} redirect={location.href} mutationOptions={{
        onSuccess: () => {
            navigate(-1);
        }
    }}>
        <SimpleForm toolbar={<EditToolbar disableDelete={true}/>}>
            <TextInput fullWidth source={'max_allowed_duration_in_seconds'}/>
            <TextInput fullWidth source={'max_allowed_playbacks'}/>
        </SimpleForm>
    </Edit>
};

const BannerTitle = () => {
    const record = useRecordContext<Banners>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.student_video_stats.name', {smart_count: 1})} &quot;
        </span>
    ) : null;
};

export default StudentVideoStatEdit;
