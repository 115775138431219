import * as React from 'react';
import {
    Show,
    TextInput,
    useTranslate,
    useRecordContext,
    required,
    SelectInput,
    ReferenceInput,
    Create,
    TabbedShowLayout,
    TextField,
    ReferenceField,
    DateTimeInput,
    NumberField,
    Pagination,
    Datagrid,
    ReferenceManyField,
    TopToolbar,
    SimpleForm,
    useRefresh,
    RichTextField,
    ReferenceOneField,
    useDataProvider,
    useGetList,
    Edit,
    SimpleShowLayout,
    EditButton,
    AutocompleteInput,
    minValue,
    DateInput,
    DateField,
    FormDataConsumer
} from 'react-admin';

import { Batches, Test_Serieses, Users } from "../../custom";
import ThumbnailField from "../../common/ThumbnailField";
import FullNameField from "../../common/FullNameField";
import FormattedDateTimeField from '../../common/FormattedDateTimeField';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { ChatBubble, CopyAll, Edit as EditIcon, Today, Visibility } from "@mui/icons-material";

import { BASE_URL, VIDEO_TYPES } from "../../common/constants";
import { useState } from "react";
import CurrencyField from "../../common/CurrencyField";
import { useQuery } from "react-query";
import { Alert, LinearProgress, Typography } from "@mui/material";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import { NoDataSection } from "../../common/NoDataSection";
import { useNavigate, useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import { EditToolbar } from '../../common/EditToolbar';
import { SoftDeleteWithDialogAction } from '../../common/SoftDeleteWithDialogAction';
import NonCategoryProductList from "../non_category_products/NonCategoryProductList";
import TotalAmountInput from '../../common/inputs/TotalAmountInput';
import { addDays } from 'date-fns';
import test_serieses from '.';


const EditTestSeries = () => {
    const params = useParams();
    const navigate = useNavigate();
    return <div>
        <Button onClick={() =>
            navigate(`/programs/${params?.programId}/test_serieses/${params?.testSeriesId}/edit`)}>
            <EditIcon />
        </Button>
    </div >
}


export const ProductCard = ({ testSeriesId, compactView, productId }: {
    compactView?: boolean,
    testSeriesId?: string,
    productId?: string
}) => {
    const record = useRecordContext();
    const { data: products, isLoading } = useGetList(
        'ecom_products',
        {
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'created_at', order: 'ASC' },
            filter: productId ? { id: productId } : {
                product_template: {
                    format: 'hasura-raw-query',
                    value: {
                        test_series_id: { _eq: testSeriesId || record.id }
                    },
                }
            }
        },
    );

    if (isLoading) {
        return <LinearProgress />
    }
    if (!products?.length) {
        return <NoDataSection resource={'ecom_products'} />
    }
    const product = products?.[0];
    if (compactView) {
        return <div>
            <Typography>
                <div style={{ display: 'flex' }}>
                    Amount:&nbsp;<strong><span>₹</span>
                        {product.amount}</strong>
                </div>
            </Typography>
            <Typography>
                <div style={{ display: 'flex' }}>
                    Discount:&nbsp;<strong><span>₹</span>
                        {product.discount || 0}</strong>
                </div>
            </Typography>
            <Typography>
                <div style={{ display: 'flex' }}>
                    Delivery Amount:&nbsp;<strong><span>₹</span>
                        {product.delivery_amount || 0}</strong>
                </div>
            </Typography>
            <Typography>
                <div style={{ display: 'flex' }}>
                    Final Amount:&nbsp;<strong><span>₹</span>
                        {product.amount - (product.discount || 0) + (product.delivery_amount || 0)}</strong>
                </div>
            </Typography>


        </div>
    }
    return <div>
        <div style={{ display: 'flex' }}>
            {<img src={product.images?.[0]} style={{ marginRight: '10px' }} width={200} />}
            <div>
                <Typography>
                    <div style={{ display: 'flex' }}>
                        Product:&nbsp;<strong><span>₹</span>
                            {product.name}</strong>
                    </div>
                </Typography>

                <Typography>
                    <div style={{ display: 'flex' }}>
                        Amount:&nbsp;<strong><span>₹</span>
                            {product.amount}</strong>
                    </div>
                </Typography>
                <Typography>
                    <div style={{ display: 'flex' }}>
                        Available Quantity:&nbsp;<strong><span>₹</span>
                            {product.available_quantity}</strong>
                    </div>
                </Typography>
                <Typography>
                    <div style={{ display: 'flex' }}>
                        Discount:&nbsp;<strong><span>₹</span>
                            {product.discount || 0}</strong>
                    </div>
                </Typography>
                <Typography>
                    <div style={{ display: 'flex' }}>
                        Delivery Amount:&nbsp;<strong><span>₹</span>
                            {product.delivery_amount || 0}</strong>
                    </div>
                </Typography>

                <Typography>
                    <div style={{ display: 'flex' }}>
                        GST Percentage:&nbsp;<strong><span>₹</span>
                            {product.gst_percentage || 0}</strong>
                    </div>
                </Typography>

                <Typography>
                    <div style={{ display: 'flex' }}>
                        Height in Mtr.:&nbsp;<strong><span>₹</span>
                            {product.height_in_mtr || 0}</strong>
                    </div>
                </Typography>
                {
                    <ProgramProductEditButton product={product} />
                }

            </div>
        </div>
        {
            <Typography dangerouslySetInnerHTML={{ __html: product.html_description }} />
        }
    </div>
}

ProductCard.defaultProps = {
    source: 'test_series_id',
    label: 'Product',
};

const ProgramProductEditButton = ({ product }: { product: any }) => {
    const [formOpened, setFormOpened] = useState(false);
    const refresh = useRefresh();
    return <div className={'py-2'}>
        <Button onClick={() => setFormOpened(true)}>Edit Amount</Button>
        <Dialog
            open={formOpened}
            title={'Edit product form'}
            onClose={(_refresh: boolean) => {
                setFormOpened(false)
            }}
        >
            <Edit redirect={window.location.href} className={' w-[400px]'} mutationMode={'pessimistic'}
                resource={'ecom_products'} mutationOptions={{
                    onSuccess: () => {
                        setFormOpened(false);
                        refresh();
                    }
                }} id={product.id}>
                <SimpleForm toolbar={<EditToolbar disableDelete={true} />}>
                    <TextInput type={'number'} source="mrp" prefix={'₹'} fullWidth validate={[required(), minValue(0)]} />
                    <TextInput type={'number'} source="discount" prefix={'₹'} fullWidth validate={[required(), minValue(0)]} />
                    <TextInput type={'number'} source="gst_percentage" prefix={'%'} fullWidth validate={[required(), minValue(0)]} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            return <div className='w-full'>
                                <TotalAmountInput formData={formData} source="amount" />
                            </div>
                        }}
                    </FormDataConsumer>
                    <TextInput type={'number'} source="apple_amount" prefix={'₹'} fullWidth validate={[required(), minValue(0)]} />
                </SimpleForm>
            </Edit>
        </Dialog>
    </div>
}

// ====================== Start Assign Test Series ===================================
export const studentProfileRender = (choice: Users) => `${choice.first_name} ${choice.last_name || ''} | ${choice.phone || 'No Phone'} | ${choice.email || 'No Email'}`;
export const filterToQueryStudent = (searchText: string) => ({ phone: `${searchText}%` });

const AssignTestSeries = () => {
    const record =useRecordContext()
    const [openStudentModal, setOpenStudentModal] = useState(false);
    const refresh = useRefresh();
    return <>
        {/* <BulkUploadStudentAction path={"programs"} /> */}
        <Button onClick={() => {
            setOpenStudentModal(true);
        }} startIcon={<Today />}>
            Assign Test Series
        </Button>

        <Dialog
            open={openStudentModal}
            onClose={(_refresh: boolean) => {

                setOpenStudentModal(false)
            }}
        >
            <AssignTestSeriestForm programId={record?.id} onClose={(_refresh: boolean) => {
                if (_refresh) {
                    refresh();
                }
                setOpenStudentModal(false)
            }} />
        </Dialog>

    </>

}

const AssignTestSeriestForm = (props: any) => {
    let { testSeriesId} = useParams();
    const [loading, setLoading] = useState(false)
    const { data: TestSeriesData, isLoading } = useGetList(
        'ecom_products',
        {
            pagination: { page: 1, perPage: 1 }, sort: { field: 'created_at', order: 'ASC' }, filter: {
                product_template: {
                    format: 'hasura-raw-query',
                    value: {
                        test_series_id: { _eq: testSeriesId}
                    },
                }
            },
        },
    );

    const postSave = (data: any) => {
        if (loading) {
            return;
        }
        setLoading(true);
        fetch(BASE_URL + '/admin/orders/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ ...data, offer_amount: parseInt(data.offer_amount) }),
        }).then((q) => q.json()).then((d) => {
            console.log(d);
        }).finally(() => {
            setLoading(false);
            props.onClose(true);
        })
    }

    const products = TestSeriesData || [];
    console.log("Test Series",TestSeriesData)
    if (!testSeriesId) {
        return <LoadingSection />
    }
    return <div style={{ width: '500px', maxWidth: '90vw' }}>
        <Create resource={'user_programs'} redirect={undefined} mutationOptions={{
            onSuccess: () => {
                if (props.onClose) {
                    props.onClose(true);
                }
            }
        }}>
            <SimpleForm onSubmit={postSave} defaultValues={{ test_series_id: testSeriesId }}>
                <ReferenceInput name={'Student by phone'} source={'student_id'} reference={'users'}
                    filter={{ role: 'STUDENT' }}>
                    <AutocompleteInput optionText={studentProfileRender} filterToQuery={filterToQueryStudent}
                        debounce={500} fullWidth validate={[required()]} />
                </ReferenceInput>
                <SelectInput
                    source="product_id"
                    label={'Plan'}
                    fullWidth
                    validate={[required()]}
                    choices={products}
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        const product = products.find((p: any) => p.id === formData.product_id);
                        if (!product) {
                            return <></>
                        }
                        return <div className={'w-full'}>
                            <DateInput source="ends_on" label="Ends On" fullWidth
                                defaultValue={addDays(new Date(), product?.validity_in_days)}
                                validate={[required()]} />
                            <ProductCard key={product.id} compactView={true} productId={product.id} />

                            <div className={'pt-2'}>
                                <TextInput fullWidth
                                    key={product.id + 'price'}
                                    type={'number'}
                                    defaultValue={product.amount - (product.discount || 0) + (product.delivery_amount || 0)}
                                    source={'offer_amount'} />
                            </div>
                        </div>
                    }}
                </FormDataConsumer>

            </SimpleForm>
        </Create>
    </div>
};



// ====================== End Assign Test Series ===================================

const TestSeriesShow = () => {
    const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = (index: any) => {
        setActiveTab(index);
    };
    let { testSeriesId, mode } = useParams();
    if (!testSeriesId) {
        return <LoadingSection />
    }

    const tabData = [
        {
            label: 'Details',
            content: <div><Show title={<Title />} resource={'test_serieses'} id={testSeriesId} >

                <SimpleShowLayout>
                    <ThumbnailField size={'large'} source="image" />
                    <TextField source="title" fullWidth />
                    <TextField source="type" fullWidth />
                    <ReferenceField reference={'programs'} source={'program_id'}>
                        <TextField fullWidth source="name" />
                    </ReferenceField>
                    <TextField source="status" />
                    <TextField source="text_description" />
                    <RichTextField source="html_description" />
                </SimpleShowLayout>
            </Show></div>,
        },

        {
            label: 'Product Details',
            content: <Show id={testSeriesId} resource={'test_serieses'}>
                <SimpleShowLayout>
                    <NonCategoryProductList testSeriesId={testSeriesId} />
                </SimpleShowLayout>
            </Show>
        },
        {
            label: 'Students',
            content: <div>
                <Show title={<Title />} resource={'test_serieses'} id={testSeriesId} >
                    <SimpleShowLayout>
                        <ReferenceManyField
                            label={'Test Series Students'}
                            reference="student_test_series"
                            target="test_series_id"
                            pagination={<Pagination />}
                        >
                            <Datagrid
                                sx={{
                                    width: '100%',
                                    '& .column-comment': {
                                        maxWidth: '20em',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    },
                                }}
                                sort={{ field: 'start_time', order: 'ASC' }}
                            >
                                <ReferenceField reference={'users'} source={'student_id'}>
                                    <FullNameField />
                                </ReferenceField>
                                <ReferenceField reference={'users'} source={'created_by_id'}>
                                    <FullNameField />
                                </ReferenceField>

                                <FormattedDateTimeField source="created_at" />
                                <DateField source='ends_on' />
                                <SoftDeleteWithDialogAction />
                            </Datagrid>
                        </ReferenceManyField>
                    </SimpleShowLayout>
                </Show>
            </div>
        },
    ];

    return <>
        <div className='flex justify-end mx-10 gap-8 pt-5 pb-2'>
            <EditTestSeries />
            <AssignTestSeries />
        </div>
        <div className='  border rounded-md mb-14'>
            <div className=" border-collapse flex text-lg felx-row gap-16 px-10 pt-4 ">
                {tabData.map((tab, index) => (
                    <div
                        key={index}
                        onClick={() => handleTabClick(index)}
                        className={`tab ${index === activeTab ? 'active text-[#4f3cc9]  underline decoration-4 underline-offset-[20px]' : ''}`}
                    >
                        {tab.label}
                    </div>
                ))}
            </div>
            <div className="tab-content">
                {tabData[activeTab].content}
            </div>
        </div>

    </>
};

const Title = () => {
    const record = useRecordContext<Test_Serieses>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.test_serieses.name', { smart_count: 1 })} &quot;
            {record.title}
        </span>
    ) : null;
};

export default TestSeriesShow;
