import * as React from 'react';
import {Link, FieldProps, useRecordContext, ReferenceManyCount} from 'react-admin';
import {Chapters, Ecom_Categories, Ecom_Product_Templates, Subjects,} from "../../custom";

const AttributesLinkField = (props: FieldProps<Ecom_Categories>) => {
    const record = useRecordContext<Ecom_Categories>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/ecom_categories/${record.id}/ecom_attributes`}>
            Attributes
            (
            <ReferenceManyCount
                label="Attributes"
                reference="ecom_attributes"
                target="category_id"
            />
            )
        </Link>
    );
};

AttributesLinkField.defaultProps = {
    label: 'Attributes'
};

export default AttributesLinkField;
