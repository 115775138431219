import OrganizationList from './OrganizationList';
import OrganizationEdit from './OrganizationEdit';
import {CorporateFare} from "@mui/icons-material";
import OrganizationCreate from "./OrganizationCreate";

export default {
    list: OrganizationList,
    edit: OrganizationEdit,
    create: OrganizationCreate,
    icon: CorporateFare,
};
