import * as React from 'react';
import {memo} from 'react';
import {SxProps} from '@mui/material';

import {FieldProps, useRecordContext} from 'react-admin';
import {Batch_Schedules,} from "../custom";
import {format} from "date-fns";

interface Props extends FieldProps<Batch_Schedules> {
    size?: string;
    sx?: SxProps;
}
// objectToFlatDotSeparated
const multiLevelObjectToSingleLevelObject = (obj: any)=>{
    const _obj: any = {};
    Object.keys(obj).forEach((key)=>{
        if(obj[key] && typeof obj[key] === 'object'){
            Object.keys(obj[key])?.forEach((_key)=>{
                _obj[`${key}.${_key}`] = obj[key][_key];
            })
        }else{
            _obj[key] = obj[key];
        }
    })
    return _obj;

}
const FormattedDateTimeField = (props: Props) => {
    const record = useRecordContext<any>();
    if(props.source !== 'created_at'){
        const _re: any = multiLevelObjectToSingleLevelObject(record);
        return  <div className={'flex flex-col text-sm space-y-1'}>
            {
                _re[props.source || ''] && <div className={'flex items-center space-x-2'}>
                    <div className={'whitespace-nowrap'}>
                        {format(new Date(_re[props.source || ''] || ''), 'dd-MMM-yyyy hh:mm a')}
                    </div>
                </div>
            }
        </div>
    }
    return record ? (
        <div className={'flex flex-col text-sm space-y-1'}>
            {
                record.created_at && <div className={'flex items-center space-x-2'}>
                    <div className={'w-4 h-4 flex flex-col items-center justify-center rounded-full bg-amber-200'}>
                        C
                    </div>
                    <div className={'whitespace-nowrap'}>
                        {format(new Date(record.created_at), 'dd-MMM-yyyy hh:mm a')}
                    </div>
                </div>
            }
            {
                record.updated_at && <div className={'flex items-center  space-x-2'}>
                    <div className={'w-4 h-4 flex flex-col items-center justify-center rounded-full bg-pink-200'}>
                        U
                    </div>
                    <div className={'whitespace-nowrap'}>
                        {format(new Date(record.updated_at), 'dd-MMM-yyyy hh:mm a')}
                    </div>
                </div>
            }
        </div>
    ) : null;
};

FormattedDateTimeField.defaultProps = {
    source: 'created_at',
    label: 'Created - Updated',
};

export default memo<Props>(FormattedDateTimeField);
