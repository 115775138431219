import {format} from "date-fns";

export const Capitalize = (str: string) => {
    if (!str) {
        return ''
    }
    const words = str?.split(" ") || [];

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(' ');
}
export const Serialize: any = (obj: any, prefix: string) => {
    const str = [];
    let p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                Serialize(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}
export function getWeekday(dateString: string) {
    const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    const date = new Date(dateString);
    return days[date.getDay()];
}

// Example usage:

export const displayDuration = (seconds: number) => {
    seconds = seconds || 0;
    let result: string[] = [];
    result.unshift(String(seconds % 60).padStart(2, '0'));
    seconds = parseInt(seconds / 60 + '')
    result.unshift(String(seconds % 60).padStart(2, '0'));
    seconds = parseInt(seconds / 60 + '')
    result.unshift(String(seconds % 24).padStart(2, '0'));
    seconds = parseInt(seconds / 24 + '')
    return result.join(':');
}
export const displayDateAndMonth = (date: string) => {
    return format(new Date(date), 'dd-MMM');
}

export function extractYoutubeVideoId(url: string) {
    const regex = /(?:v=)([^&]+)/;
    const result = url.match(regex);

    return result ? result[1] : null;
}

export function displayPrice(price: number) {
    return price.toLocaleString('en-IN', {style: 'currency', currency: 'INR'});
}

export function getFullName(firstName: string, lastName?: string) {
    return `${firstName} ${lastName}`;
}
