import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  TopToolbar,
  CreateButton,
  useRecordContext,
  BooleanField,
  useCreate,
  useNotify,
  useRefresh,
  Create,
  SimpleForm,
  Toolbar,
  SaveButton,
  TextInput,
  required,
  useDataProvider,
  minValue,
  NullableBooleanInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
} from "react-admin";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { NoDataSection } from "../../common/NoDataSection";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import PDFInput from "../../common/inputs/PDFInput";
import HTMLEditorInput, {
  validHtml,
} from "../../common/inputs/HTMLEditorInput";
import { Subjects } from "../../custom";
import { useQuery } from "react-query";
import ImageInput from "../../common/inputs/ImageInput";
import TotalAmountInput from "../../common/inputs/TotalAmountInput";

const ListActions = ({
  programId,
  testSeriesId,
  subjectId,
  batchId,
}: ParentInput) => {
  const navigate = useNavigate();
  const dataProvider = useDataProvider();
  const {
    data: productTemplate,
    isLoading,
    error,
  } = useQuery(
    [
      "product_template_" + programId + subjectId + testSeriesId,
      "getList",
      {
        program_id: programId,
        subject_id: subjectId,
        batch_id: batchId,
        test_series_id: testSeriesId,
      },
    ],
    () =>
      dataProvider.getList("ecom_product_templates", {
        filter: {
          program_id: programId,
          subject_id: subjectId,
          batch_id: batchId,
          test_series_id: testSeriesId,
        },
        pagination: { page: 1, perPage: 1 },
        sort: { field: "created_at", order: "ASC" },
      })
  );
  const template = productTemplate?.data?.[0];
  if (!template) {
    return (
      <Toolbar>
        <div />
      </Toolbar>
    );
  }
  // console.log(template)
  // console.log('template')
  return (
    <TopToolbar>
      <CreateButton
        onClick={(e) => {
          e.preventDefault();
          navigate(
            `/ecom_product_templates/${template?.id}/ecom_products/create`
          );
        }}
      />
    </TopToolbar>
  );
};
const EditButton = () => {
  const navigate = useNavigate();
  const record = useRecordContext();
  return (
    <Button
      onClick={() => {
        navigate(
          `/ecom_product_templates/${record.product_template_id}/ecom_products/${record.id}/edit`
        );
      }}
    >
      Edit
    </Button>
  );
};

interface ParentInput {
  programId?: string;
  subjectId?: string;
  batchId?: string;
  testSeriesId?: string;
}

const NonCategoryProductList = ({
  programId,
  testSeriesId,
  subjectId,
  batchId,
}: ParentInput) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const record = useRecordContext<any>();

  const filter: any = {};
  if (programId) {
    filter["program_id"] = { _eq: programId };
  }
  if (batchId) {
    filter["batch_id"] = { _eq: batchId };
  }
  if (subjectId) {
    filter["subject_id"] = { _eq: subjectId };
  }
  if (testSeriesId) {
    filter["test_series_id"] = { _eq: testSeriesId };
  }
  const defaultValues = {
    subject_id: subjectId,
    batch_id: batchId,
    program_id: programId,
    test_series_id: testSeriesId,
    name: record.name,
    height_in_mtr: 0,
    width_in_mtr: 0,
    length_in_mtr: 0,
    weight_in_grams: 0,
    text_description: "",
    html_description: "",
  };
  const [create, { isLoading }] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  console.log(filter);
  const postSave = (data: any) => {
    try {
      if (isLoading) {
        return;
      }
      create(
        "ecom_product_templates",
        { data },
        {
          onSuccess: (e) => {
            create(
              "ecom_products",
              { data: { ...data, product_template_id: e.id } },
              {
                onSuccess: (e) => {
                  notify("Product added successfully", {
                    type: "success",
                  });
                  setOpen(false);
                  refresh();
                },
              }
            );
          },
        }
      );
    } catch (e) {
      return false;
    }
  };
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle style={{ paddingBottom: "0" }}>
          Add store item to "{record.name || record.title}"
        </DialogTitle>
        <DialogContent sx={{ width: "500px", padding: "0" }}>
          <Create redirect={"/ecom_products"}>
            <SimpleForm
              onSubmit={postSave}
              toolbar={
                <Toolbar className={"w-full"}>
                  <div className={"flex space-between w-full"}>
                    <SaveButton />
                    <Button disabled={isLoading} onClick={() => setOpen(false)}>
                      Close
                    </Button>
                  </div>
                </Toolbar>
              }
              defaultValues={defaultValues}
            >
              <ImageInput
                source="images"
                multiple
                fullWidth
                validate={[required()]}
              />
              <PDFInput source="preview_pdf" fullWidth label={"Preview PDF"} />
              <TextInput source="name" fullWidth validate={[required()]} />
              <NullableBooleanInput
                source={"address_required"}
                fullWidth={true}
                name={"Address Required"}
              />
              <ReferenceInput
                reference={"ecom_product_templates"}
                source={"product_template_id"}
              >
                <SelectInput fullWidth disabled optionText="name" />
              </ReferenceInput>
              <TextInput
                type={"number"}
                source="mrp"
                prefix={"₹"}
                fullWidth
                validate={[required(), minValue(0)]}
              />
              <TextInput
                type={"number"}
                source="discount"
                prefix={"₹"}
                fullWidth
                validate={[required(), minValue(0)]}
              />
              <TextInput
                type={"number"}
                source="gst_percentage"
                prefix={"%"}
                fullWidth
                validate={[required(), minValue(0)]}
              />
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <div className="w-full">
                      <TotalAmountInput formData={formData} source="amount" />
                    </div>
                  );
                }}
              </FormDataConsumer>
              <TextInput
                type={"number"}
                source="apple_amount"
                prefix={"₹"}
                fullWidth
                validate={[required(), minValue(0)]}
              />
              <TextInput
                type={"number"}
                source="validity_in_days"
                defaultValue={0}
                fullWidth
                validate={[required(), minValue(0)]}
              />
              <TextInput
                source="text_description"
                multiline
                rows={4}
                fullWidth
                validate={[required()]}
              />
              <HTMLEditorInput
                source={"html_description"}
                fullWidth
                validate={[required(), validHtml]}
              />
            </SimpleForm>
          </Create>
        </DialogContent>
      </Dialog>
      <List
        sort={{ field: "created_at", order: "DESC" }}
        resource={"ecom_products"}
        actions={
          <ListActions
            subjectId={subjectId}
            batchId={batchId}
            testSeriesId={testSeriesId}
            programId={programId}
          />
        }
        empty={
          <NoDataSection
            resource={"ecom_products"}
            onCreate={() => {
              setOpen(true);
            }}
          />
        }
        filter={{
          product_template: {
            format: "hasura-raw-query",
            value: filter,
          },
        }}
      >
        <Datagrid>
          <TextField source="name" />
          <TextField source="status" />
          <NumberField source="available_quantity" />
          <NumberField source="apple_amount" />
          <NumberField source="amount" />
          <NumberField source="discount" />
          <NumberField source="validity_in_days" />
          <NumberField source="gst_percentage" />
          <NumberField source="mrp" />
          <BooleanField source="address_required" />
          <FormattedDateTimeField source="created_at" />
          <EditButton />
          <DeleteWithDialogAction />
        </Datagrid>
      </List>
    </>
  );
};
export default NonCategoryProductList;
