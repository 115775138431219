import * as React from 'react';
import {
    Create,
    TextInput,
    NullableBooleanInput,
    SimpleForm,
    DateInput,
    SelectInput,
    minValue,
    ReferenceInput, AutocompleteInput, DateTimeInput, required
} from 'react-admin';
import {Programs} from "../../custom";
export const SegmentRender = (choice: Programs) => `${choice.name}`;


const CampaignCreate = () => (
    <Create redirect={'/campaigns'}>
        <SimpleForm >
            <TextInput source="name" fullWidth validate={[minValue(0)]}/>
            <TextInput source="message" fullWidth validate={[minValue(0)]}/>
            <TextInput source="title" fullWidth validate={[minValue(0)]}/>
            <TextInput source="description" fullWidth validate={[minValue(0)]}/>
            <DateTimeInput source="starts_at" validate={[required()]} fullWidth />
            <ReferenceInput name={'Segment'} source={'segment_id'} reference={'segmentations'}
                            fullwidth>
                <AutocompleteInput optionText={SegmentRender}
                                   debounce={500} fullWidth />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export default CampaignCreate;
