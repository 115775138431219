import React, {useMemo} from 'react';
import {useGetList} from 'react-admin';
import {subDays, startOfDay, startOfMonth} from 'date-fns';

import Welcome from './Welcome';
import MonthlyRevenue from './MonthlyRevenue';
import NewOrders from './NewOrders';
import PendingOrders from './PendingOrders';
import PendingReviews from './PendingReviews';
import NewStudents from './NewStudents';
import OrderChart from './OrderChart';
import {Ecom_Orders, Organizations} from '../../custom';
import TotalUsers from "./TotalUsers";
import ActiveUsers from "./ActiveUsers";
import NewUsers from "./NewUsers";
import TotalSale from "./TotalSale";
import MailBalance from "./MailBalance";
import NotificationBalance from "./NotificationBalance";
import SmsBalance from "./SmsBalance";
import OtpBalance from "./OtpBalance";
import StudentsPerClassChart from "./StudentsPerClassChart";
import MonthlyNewUsersChart from "./MonthlyNewUsersChart";
import TotalVideoSessionsToday from './TotalVideoSessionsToday';
import TotalVideoDurationToday from "./TotalVideoDurationToday";
import TotalVideoAddedToday from './TotalVideoAddedToday';
import OpenIssues from "./OpenIssues";
import LatestClasses from "./PastClasses";
import UpcomingClasses from "./UpcomingClasses";
import Last30DaysWatchSessions from "./Last30DaysWatchSessions";


interface OrderStats {
    revenue: number;
    nbNewOrders: number;
    pendingOrders: Ecom_Orders[];
}

interface State {
    nbNewOrders?: number;
    pendingOrders?: Ecom_Orders[];
    recentOrders?: Ecom_Orders[];
    revenue?: string;
}

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '0.5em'},
    rightCol: {flex: 1, marginLeft: '0.5em'},
    singleCol: {marginTop: '1em', marginBottom: '1em'},
};

const Spacer = () => <span style={{width: '1em'}}/>;

const Dashboard = () => {
    // const aMonthAgo = useMemo(() => startOfMonth(new Date()), []);
    //
    // const {data: orders} = useGetList<Ecom_Orders>('ecom_orders', {
    //     filter: {
    //         created_at: {
    //             format: 'hasura-raw-query',
    //             value: {
    //                 _gte: aMonthAgo.toISOString()
    //             }
    //         },
    //         status: {
    //             format: 'hasura-raw-query',
    //             value: {
    //                 _in: ['DELIVERED']
    //             }
    //         }
    //     },
    //     sort: {field: 'created_at', order: 'DESC'},
    //     pagination: {page: 1, perPage: 10000},
    // });
    // const aggregation = useMemo<State>(() => {
    //     if (!orders) return {};
    //     const aggregations = orders
    //         .filter(order => order.status !== 'CANCELLED')
    //         .reduce(
    //             (stats: OrderStats, order) => {
    //                 if (order.status === 'DELIVERED') {
    //                     stats.revenue += order.amount;
    //                     stats.nbNewOrders++;
    //                 }
    //                 if (order.status === 'PENDING') {
    //                     stats.pendingOrders.push(order);
    //                 }
    //                 return stats;
    //             },
    //             {
    //                 revenue: 0,
    //                 nbNewOrders: 0,
    //                 pendingOrders: [],
    //             }
    //         );
    //     return {
    //         recentOrders: orders,
    //         revenue: aggregations.revenue.toLocaleString(undefined, {
    //             style: 'currency',
    //             currency: 'INR',
    //             minimumFractionDigits: 0,
    //             maximumFractionDigits: 0,
    //         }),
    //         nbNewOrders: aggregations.nbNewOrders,
    //         pendingOrders: aggregations.pendingOrders,
    //     };
    // }, [orders]);
    //
    // const {data: organization} = useGetList<any>('organizations');
    //
    // const {nbNewOrders, pendingOrders, revenue, recentOrders} = aggregation;
    return <>
        <Welcome/>
        {/*<div className={'flex gap-4 mb-4'}>*/}
        {/*    /!*<LatestClasses/>*!/*/}
        {/*    /!*<UpcomingClasses/>*!/*/}
        {/*</div>*/}
        {/*<div className="flex gap-2">*/}
        {/*    <div className={'flex-1'}>*/}
        {/*        <div style={styles.flex}>*/}
        {/*            <TotalUsers/>*/}
        {/*            <Spacer/>*/}
        {/*            <ActiveUsers/>*/}
        {/*            <Spacer/>*/}
        {/*            <NewUsers/>*/}
        {/*            <Spacer/>*/}
        {/*            <TotalSale/>*/}
        {/*        </div>*/}
        {/*        <div style={styles.singleCol}/>*/}
        {/*        <div style={styles.flex}>*/}
        {/*            <TotalVideoSessionsToday/>*/}
        {/*            <Spacer/>*/}
        {/*            <TotalVideoAddedToday/>*/}
        {/*        </div>*/}

        {/*        <div style={styles.singleCol}/>*/}

        {/*        <div style={styles.flex}>*/}
        {/*            <MailBalance value={organization && organization[0].mail_balance}/>*/}
        {/*            <Spacer/>*/}
        {/*            <NotificationBalance value={organization && organization[0].notification_balance}/>*/}
        {/*            <Spacer/>*/}
        {/*            <SmsBalance value={organization && organization[0].sms_balance}/>*/}
        {/*            <Spacer/>*/}
        {/*            <OtpBalance value={organization && organization[0].otp_balance}/>*/}
        {/*        </div>*/}

        {/*        <div style={styles.singleCol}/>*/}

        {/*        <div style={styles.flex}>*/}
        {/*            <MonthlyRevenue value={revenue}/>*/}
        {/*            <Spacer/>*/}
        {/*            <NewOrders value={nbNewOrders}/>*/}
        {/*            <Spacer/>*/}
        {/*            <OpenIssues/>*/}
        {/*            /!*<Spacer/>*!/*/}
        {/*            /!*<NewStudents/>*!/*/}
        {/*        </div>*/}

        {/*        <div style={styles.singleCol}/>*/}

        {/*        <div style={styles.flex}>*/}
        {/*            <div style={styles.leftCol}>*/}
        {/*                <div style={styles.singleCol}>*/}
        {/*                    <Last30DaysWatchSessions/>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div style={styles.rightCol}>*/}
        {/*                <div style={styles.singleCol}>*/}
        {/*                    <MonthlyNewUsersChart/>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}

        {/*        <div style={styles.flex}>*/}
        {/*            <div style={styles.leftCol}>*/}
        {/*                <div style={styles.singleCol}>*/}
        {/*                    <OrderChart orders={recentOrders}/>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </>;
};

export default Dashboard;
