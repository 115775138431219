import React from 'react'
import { AutocompleteInput, Edit, ReferenceInput, SimpleForm, TextInput, required } from 'react-admin'
import { BatchesRender, ProgramRender } from './DemoVideosCategoriesCreate'

const DemoVideosCategoriesEdit = () => {
  return (
    <Edit
      mutationMode={'pessimistic'}>
      <SimpleForm
      >
        <TextInput source="name" fullWidth validate={[required()]} />
        <ReferenceInput name={'Programs'} source={'program_id'} reference={'programs'}
          fullwidth>
          <AutocompleteInput optionText={ProgramRender}
            debounce={500} fullWidth />
        </ReferenceInput>
        <ReferenceInput name={'batches'} source={'batch_id'} reference={'batches'}
          fullwidth>
          <AutocompleteInput optionText={BatchesRender}
            debounce={500} fullWidth />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  )
}

export default DemoVideosCategoriesEdit
