import VideoList from './VideoList';
import VideoEdit from './VideoEdit';
import {Image} from "@mui/icons-material";
import VideoCreate from "./VideoCreate";
import VideoShow from "./VideoShow";

export default {
    list: VideoList,
    edit: VideoEdit,
    show: VideoShow,
    create: VideoCreate,
    icon: Image,
};
