import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext,
    required,
    ReferenceInput,
    SelectInput,
    Show,
    SimpleShowLayout,
    ReferenceField,
    TextField,
    Labeled,
    Datagrid, EditButton, List, useDataProvider, useGetOne, Loading,
} from 'react-admin';
import { Sections, Student_Test_Mapping } from "../../custom";
import { EditToolbar } from "../../common/EditToolbar";
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import FullNameField from "../../common/FullNameField";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { Button, Divider, Typography } from "@mui/material";
import { useQuery } from "react-query";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { Check, Close } from "@mui/icons-material";
import { displayDuration } from "../../common/string-utilities";

const QuestionWiseShow = (props: any) => {
    const dataProvider = useDataProvider();
    const { testSeriesId, programId, testId, studentResponseId } = useParams();
    const { data: results, refetch, isLoading, error } = useQuery(
        ['results_' + studentResponseId, 'getList', { test_id: testId }],
        () => dataProvider.getList('student_test_mapping_results', {
            filter: { student_test_mapping_id: studentResponseId },
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'created_at', order: 'DESC' }
        })
    );
    const { data, isLoading: loading, error: isError } = useGetOne("tests", { id: testId });
    if (isLoading || !results?.data) {
        return <LoadingSection />
    }
    const result = results?.data?.[0];
    if (!result) {
        return <div />
    }
    const questionWise = result?.question_wise || [];
    const id = result.id
    const markedAnswerSheet = result?.marked_answer_sheet
    return data && data?.category === "SUBJECTIVE" ?
    <>
        <div className="flex flex-col">
        <Typography variant={'h6'}>Score Card</Typography>
            <Typography> Marks Obtained
                : <strong>{`${result.marks_obtained}`}</strong></Typography>
            <a className="text-blue-500  background-transparent font-bold outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                href={markedAnswerSheet} download target="blank">
                Download Marked  Answer sheet
            </a>
            <div className="flex justify-center">
            <Button className="w-1/2">Reset Score Card</Button>
            </div>
        </div>
    </> :
     <div style={{ width: '100%' }}>
        <Typography variant={'h5'} >Score Card</Typography>
        <div style={{ padding: '20px 0' }}>
            <Typography>Time taken in minutes : <strong>{result.total_time_in_minutes ?? 0}</strong></Typography>
            <Typography>Questions Attempted
                : <strong>{`${result.total_questions_attempted ?? 0}/${result.total_questions ?? 0}`}</strong></Typography>
            <Typography>Correct Questions
                : <strong>{`${result.total_correct_questions ?? 0}/${result.total_questions_attempted ?? 0}`}</strong></Typography>
            <Typography>Marked as review : <strong>{`${result.total_marked_as_review ?? 0}`}</strong></Typography>
            <Typography>Accuracy : <strong>{`${result.accuracy ?? 0.0}%`}</strong></Typography>
            <Typography>Marks Obtained
                : <strong>{`${result.marks_obtained}/${result.total_marks}`}</strong></Typography>
        </div>
        <Labeled style={{ width: '100%' }} label={'Question Wise Responses'}>
            <TableContainer style={{ width: '100%' }} component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Question #</TableCell>
                            <TableCell>Is Correct</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Marks Obtained</TableCell>
                            <TableCell>Time Spent</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {questionWise.map((row: any, index: number) => {

                            return <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {
                                        index + 1
                                    }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {
                                        row.is_correct ? <Check /> : (row.status === 'ATTEMPTED' ? <Close /> :
                                            <span>-</span>)
                                    }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {
                                        row.status
                                    }
                                </TableCell>

                                <TableCell component="th" scope="row">
                                    {
                                        row.marks_obtained
                                    }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {
                                        displayDuration(row.time_spend_in_seconds)
                                    }
                                </TableCell>

                            </TableRow>

                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Labeled>

    </div>

}

const StudentResponseShow = () => {
    let { programId, testSeriesId, testId, studentResponseId } = useParams();
    if (!testId) {
        return <LoadingSection />
    }
    return <Show title={<SectionTitle />} resource={'student_test_mapping'} id={studentResponseId}>
        <SimpleShowLayout>
            <ReferenceField reference={'users'} source={'created_by_id'}>
                <FullNameField source="first_name" />
            </ReferenceField>
            <ReferenceField reference={'tests'} source={'test_id'}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="status" />
            <FormattedDateTimeField source="started_at" />
            <FormattedDateTimeField source="submitted_at" />
            <FormattedDateTimeField source="end_time" />
            <FormattedDateTimeField source="created_at" />

            <Divider />
            <QuestionWiseShow />
        </SimpleShowLayout>
    </Show>
};

const SectionTitle = () => {
    const record = useRecordContext<Student_Test_Mapping>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.student_responses.name', { smart_count: 1 })} &quot;
            {record.started_at}&quot;
        </span>
    ) : null;
};

export default StudentResponseShow;
