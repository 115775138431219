import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, SelectInput, required, ReferenceInput,
} from 'react-admin';

import {Batch_Templates,} from "../../custom";
import {EditToolbar} from "../../common/EditToolbar";
import {BATCH_TEMPLATE_TYPE, STATUSES} from "../../common/constants";
import HTMLEditorInput, {validHtml} from "../../common/inputs/HTMLEditorInput";

const BatchTemplateEdit = () => (
    <Edit title={<BatchTitle/>} mutationMode={'optimistic'}>
        <SimpleForm toolbar={<EditToolbar/>}>
            <TextInput source="title" fullWidth validate={[required()]}/>
            <TextInput source="slug" fullWidth validate={[required()]}/>
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUSES}
            />
            <SelectInput
                source="type"
                label={'Type'}
                fullWidth
                validate={[required()]}
                choices={BATCH_TEMPLATE_TYPE}
            />
            <ReferenceInput label="Program" source="program_id" reference="programs">
                <SelectInput disabled optionText="name" fullWidth validate={[required()]}/>
            </ReferenceInput>
            <HTMLEditorInput source={'description'} fullWidth validate={[required(), validHtml]}/>
        </SimpleForm>
    </Edit>
);

const BatchTitle = () => {
    const record = useRecordContext<Batch_Templates>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.batch_templates.name', {smart_count: 1})} &quot;
            {record.title}&quot;
        </span>
    ) : null;
};

export default BatchTemplateEdit;
