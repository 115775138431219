import * as React from "react";
import { useState, useEffect } from "react";
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  FieldProps,
  FormDataConsumer,
  List,
  minValue,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TabbedShowLayout,
  TextField,
  TextInput,
  Title,
  useGetList,
  useRecordContext,
  useRefresh,
  useTranslate,
} from "react-admin";

import { Batch_Schedules, Programs, Users } from "../../custom";
import ThumbnailField from "../../common/ThumbnailField";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  ChatBubble,
  CopyAll,
  Edit as EditIcon,
  Today,
  Visibility,
} from "@mui/icons-material";
// @ts-ignore
import { LinearProgress, Pagination, Typography } from "@mui/material";
import { NoDataSection } from "../../common/NoDataSection";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { EditToolbar } from "../../common/EditToolbar";
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import FullNameField from "../../common/FullNameField";
import { SoftDeleteWithDialogAction } from "../../common/SoftDeleteWithDialogAction";
import NonCategoryProductList from "../non_category_products/NonCategoryProductList";
import { BulkUploadStudentAction } from "../batches/BulkStudentUpload";
import TotalAmountInput from "../../common/inputs/TotalAmountInput";
import { BASE_URL } from "../../common/constants";
import { addDays } from "date-fns";
import { EndsOnTime, ProductCard } from "../subjects/SubjectShow";
import OrderField from "../../common/OrderField";

// ================================= start product card =========================

export const ProgramProductCard = () => {
  const record = useRecordContext();
  const { data: products, isLoading } = useGetList("ecom_products", {
    pagination: { page: 1, perPage: 1 },
    sort: { field: "created_at", order: "ASC" },
    filter: {
      product_template: {
        format: "hasura-raw-query",
        value: {
          program_id: { _eq: record.id },
        },
      },
    },
  });

  if (isLoading) {
    return <LinearProgress />;
  }
  if (!products?.length) {
    return <NoDataSection resource={"ecom_products"} />;
  }
  const product = products[0];
  return (
    <div>
      <div style={{ display: "flex" }}>
        <img src={product.images?.[0]} width={200} />
        <div style={{ marginLeft: "10px" }}>
          <Typography>{product.name}</Typography>
          <div>
            <Button
              disabled={!product["reference"]}
              startIcon={<CopyAll />}
              onClick={() =>
                navigator.clipboard.writeText(product["reference"])
              }
            >
              Copy Reference
            </Button>
          </div>
          <Typography variant={"caption"}>
            {product.text_description}
          </Typography>
          <Typography>
            <div style={{ display: "flex" }}>
              Apple amount:&nbsp;
              <strong>
                <span>₹</span>
                {product.apple_amount}
              </strong>
            </div>
          </Typography>
          <Typography>
            <div style={{ display: "flex" }}>
              Amount:&nbsp;
              <strong>
                <span>₹</span>
                {product.amount}
              </strong>
            </div>
          </Typography>
          <Typography>
            <div style={{ display: "flex" }}>
              Discount:&nbsp;
              <strong>
                <span>₹</span>
                {product.discount || 0}
              </strong>
            </div>
          </Typography>
          <ProgramProductEditButton product={product} />
        </div>
      </div>
      <Typography
        dangerouslySetInnerHTML={{ __html: product.html_description }}
      />
    </div>
  );
};

ProgramProductCard.defaultProps = {
  source: "batch_id",
  label: "Product",
};

const ProgramProductEditButton = ({ product }: { product: any }) => {
  const [formOpened, setFormOpened] = useState(false);
  const refresh = useRefresh();
  return (
    <div className={"py-2"}>
      <Button onClick={() => setFormOpened(true)}>Edit Amount</Button>
      <Dialog
        open={formOpened}
        title={"Edit product form"}
        onClose={(_refresh: boolean) => {
          setFormOpened(false);
        }}
      >
        <Edit
          redirect={window.location.href}
          className={" w-[400px]"}
          mutationMode={"pessimistic"}
          resource={"ecom_products"}
          mutationOptions={{
            onSuccess: () => {
              setFormOpened(false);
              refresh();
            },
          }}
          id={product.id}
        >
          <SimpleForm toolbar={<EditToolbar disableDelete={true} />}>
            <TextInput
              type={"number"}
              source="mrp"
              prefix={"₹"}
              fullWidth
              validate={[required(), minValue(0)]}
            />
            <TextInput
              type={"number"}
              source="discount"
              prefix={"₹"}
              fullWidth
              validate={[required(), minValue(0)]}
            />
            <TextInput
              type={"number"}
              source="gst_percentage"
              prefix={"%"}
              fullWidth
              validate={[required(), minValue(0)]}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  <div className="w-full">
                    <TotalAmountInput formData={formData} source="amount" />
                  </div>
                );
              }}
            </FormDataConsumer>
            <TextInput
              type={"number"}
              source="apple_amount"
              prefix={"₹"}
              fullWidth
              validate={[required(), minValue(0)]}
            />
          </SimpleForm>
        </Edit>
      </Dialog>
    </div>
  );
};
// ================================= end product card =========================

// ================================= start student card =========================

export const ProgramStudentCard = ({ programId }: { programId: any }) => {
  const [batchStudentQuery, setBatchStudentQuery] = useState("");

  // Combined filters: program_id and the batchStudentQuery
  const combinedFilters = {
    program_id: programId,
    ...(batchStudentQuery
      ? {
          user: {
            format: "hasura-raw-query",
            value: {
              _or: [
                { phone: { _eq: batchStudentQuery } },
                { email: { _eq: batchStudentQuery } },
                { first_name: { _ilike: `%${batchStudentQuery}%` } },
                { last_name: { _ilike: `%${batchStudentQuery}%` } },
              ],
            },
          },
        }
      : {}),
  };

  return (
    <>
      <input
        className="border rounded p-2 my-3"
        value={batchStudentQuery}
        onChange={(e) => setBatchStudentQuery(e.target.value)}
        placeholder="Search Student using mobile"
      />
      <List
        title="Program Students"
        resource="user_programs"
        perPage={10}
        filter={combinedFilters}
        sort={{ field: "created_at", order: "ASC" }}
        actions={false}
      >
        <Datagrid
          sx={{
            width: "100%",
            "& .column-comment": {
              maxWidth: "20em",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          }}
          sort={{ field: "start_time", order: "ASC" }}
        >
          <ReferenceField reference="users" source="student_id">
            <FullNameField />
          </ReferenceField>
          <ReferenceField reference="users" source="created_by_id">
            <FullNameField />
          </ReferenceField>
          <ReferenceField reference="ecom_orders" source="order_id">
            <OrderField />
          </ReferenceField>
          <FormattedDateTimeField source="created_at" />
          <EndsOnTime source="ends_on" />
          <SoftDeleteWithDialogAction />
        </Datagrid>
      </List>
    </>
  );
};
ProgramStudentCard.defaultProps = {
  source: "student_id",
  label: "Program Student",
};

// ================================= end student card =========================

// ====================== Start Assign Program ===================================

export const studentProfileRender = (choice: Users) =>
  `${choice.first_name} ${choice.last_name || ""} | ${choice.phone || "No Phone"
  } | ${choice.email || "No Email"}`;
export const filterToQueryStudent = (searchText: string) => ({
  phone: `${searchText}%`,
});

const AssignProgram = () => {
  const record = useRecordContext();
  const [openStudentModal, setOpenStudentModal] = useState(false);
  const refresh = useRefresh();
  return (
    <>
      <BulkUploadStudentAction path={"programs"} />
      <Button
        onClick={() => {
          setOpenStudentModal(true);
        }}
        startIcon={<Today />}
      >
        Assign Programs
      </Button>

      <Dialog
        open={openStudentModal}
        onClose={(_refresh: boolean) => {
          setOpenStudentModal(false);
        }}
      >
        <AssignProgramsForm
          programId={record?.id}
          onClose={(_refresh: boolean) => {
            if (_refresh) {
              refresh();
            }
            setOpenStudentModal(false);
          }}
        />
      </Dialog>
    </>
  );
};

const AssignProgramsForm = (props: any) => {
  const programId = props?.programId;
  const [loading, setLoading] = useState(false);
  const { data: productsData, isLoading } = useGetList("ecom_products", {
    pagination: { page: 1, perPage: 1 },
    sort: { field: "created_at", order: "ASC" },
    filter: {
      product_template: {
        format: "hasura-raw-query",
        value: {
          program_id: { _eq: programId },
        },
      },
    },
  });

  const postSave = (data: any) => {
    if (loading) {
      return;
    }
    setLoading(true);
    fetch(BASE_URL + "/admin/orders/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        ...data,
        offer_amount: parseInt(data.offer_amount),
      }),
    })
      .then((q) => q.json())
      .then((d) => {
        console.log(d);
      })
      .finally(() => {
        setLoading(false);
        props.onClose(true);
      });
  };

  const products = productsData || [];
  console.log("products", productsData);
  if (!programId) {
    return <LoadingSection />;
  }
  return (
    <div style={{ width: "500px", maxWidth: "90vw" }}>
      <Create
        resource={"user_programs"}
        redirect={undefined}
        mutationOptions={{
          onSuccess: () => {
            if (props.onClose) {
              props.onClose(true);
            }
          },
        }}
      >
        <SimpleForm
          onSubmit={postSave}
          defaultValues={{ program_id: programId }}
        >
          <ReferenceInput
            name={"Student by phone"}
            source={"student_id"}
            reference={"users"}
            filter={{ role: "STUDENT" }}
          >
            <AutocompleteInput
              optionText={studentProfileRender}
              filterToQuery={filterToQueryStudent}
              debounce={500}
              fullWidth
              validate={[required()]}
            />
          </ReferenceInput>
          <SelectInput
            source="product_id"
            label={"Plan"}
            fullWidth
            validate={[required()]}
            choices={products}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const product = products.find(
                (p: any) => p.id === formData.product_id
              );
              if (!product) {
                return <></>;
              }
              return (
                <div className={"w-full"}>
                  <DateInput
                    source="ends_on"
                    label="Ends On"
                    fullWidth
                    defaultValue={addDays(
                      new Date(),
                      product?.validity_in_days
                    )}
                    validate={[required()]}
                  />
                  <ProductCard
                    key={product.id}
                    compactView={true}
                    productId={product.id}
                  />

                  <div className={"pt-2"}>
                    <TextInput
                      fullWidth
                      key={product.id + "price"}
                      type={"number"}
                      defaultValue={
                        product.amount -
                        (product.discount || 0) +
                        (product.delivery_amount || 0)
                      }
                      source={"offer_amount"}
                    />
                  </div>
                </div>
              );
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    </div>
  );
};

// ====================== End Assign Program ===================================

const Actions = (props: FieldProps<Programs>) => {
  return (
    <div className={"flex justify-end py-3 px-2"}>
      <AssignProgram />
      <EditButton />
    </div>
  );
};

const ProgramShow = () => {
  const params = useParams();
  return (
    <Show title={<ProgramTitle />} actions={<Actions />} id={params?.id}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="resources.programs.tabs.details">
          <ThumbnailField size={"large"} source="image" />
          <TextField source="name" fullWidth />
          <TextField source="slug" fullWidth />
          <TextField source="display_order" fullWidth />
          <TextField source="lectures_count" fullWidth />
          <TextField source="exercises_count" fullWidth />
          <TextField source="homeworks_count" fullWidth />
          <TextField source="notes_count" fullWidth />
          <TextField source="total_exercises" fullWidth />
          <TextField source="total_lecture_durations_in_seconds" fullWidth />
          <TextField source="videos_count" fullWidth />
          <TextField source="total_videos" fullWidth />
          <FormattedDateTimeField source="created_at" fullWidth />
          <FormattedDateTimeField source="updated_at" fullWidth />
          <TextField source="status" />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab
          path={"product"}
          label="resources.programs.tabs.product"
        >
          <NonCategoryProductList programId={params.id} />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab
          path={"Students"}
          label="resources.programs.tabs.user"
        >
          <ProgramStudentCard programId={params.id} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const ProgramTitle = () => {
  const record = useRecordContext<Programs>();
  const translate = useTranslate();

  return record ? (
    <span>
      {translate("resources.programs.name", { smart_count: 1 })} &quot;
      {record.name}
    </span>
  ) : null;
};

export default ProgramShow;
