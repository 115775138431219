import * as React from 'react';
import {useRecordContext} from 'react-admin';
import clsx from "clsx";

const ContentCounts = () => {
    const record = useRecordContext<any>();
    if (!record) return null;
    return <div className={'text-xs'}>
        <div className={'whitespace-nowrap'}>
            Lectures: <b>{record.lectures_count}</b>
        </div>
        <div className={'whitespace-nowrap'}>
            Videos: <b>{record.videos_count}</b>
        </div>
        <div className={'whitespace-nowrap'}>
            Notes: <b>{record.notes_count}</b>
        </div>
        <div className={'whitespace-nowrap'}>
            Homeworks: <b>{record.homeworks_count}</b>
        </div>
        <div className={'whitespace-nowrap'}>
            Exercises: <b>{record.exercises_count}</b>
        </div>
    </div>;
};
ContentCounts.defaultProps = {
    label: 'Counts'
}
export default ContentCounts;
