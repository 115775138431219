import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';

import { TableCellRight } from './TableCellRight';
import { Ecom_Orders } from "../../custom";

const Totals = () => {
    const record = useRecordContext<Ecom_Orders>();
    const translate = useTranslate();

    return (
        <Table sx={{ minWidth: '35em' }}>
            <TableBody>
                <TableRow>
                    <TableCell>
                        Amount
                    </TableCell>
                    <TableCellRight>
                        {record?.amount.toLocaleString(undefined, {
                            style: 'currency',
                            currency: 'INR',
                        })}
                    </TableCellRight>
                </TableRow>
                <TableRow>
                    <TableCell>
                        Discount
                    </TableCell>
                    <TableCellRight>
                        {record?.discount.toLocaleString(undefined, {
                            style: 'currency',
                            currency: 'INR',
                        })}
                    </TableCellRight>
                </TableRow>
                <TableRow>
                    <TableCellRight>
                        {record?.tax.toLocaleString(undefined, {
                            style: 'currency',
                            currency: 'INR',
                        })}
                    </TableCellRight>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                        Total
                    </TableCell>
                    <TableCellRight sx={{ fontWeight: 'bold' }}>
                        {((record?.amount) - (record.tax || 0)).toLocaleString(undefined, {
                            style: 'currency',
                            currency: 'INR',
                        })}
                    </TableCellRight>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default Totals;
