import CampaignList from './CampaignList';
import CampaignEdit from './CampaignEdit';
import {Image} from "@mui/icons-material";
import CampaignCreate from "./CampaignCreate";

export default {
    list: CampaignList,
    edit: CampaignEdit,
    create: CampaignCreate,
    icon: Image,
};
