import * as React from 'react';
import {

    List,
    Datagrid,
    TextField,
    NumberField,
    ImageField, EditButton,
} from 'react-admin';
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import {SoftDeleteWithDialogAction} from "../../common/SoftDeleteWithDialogAction";

const PodcastTopicList = () => {
    return (
        <List
            sort={{field: 'display_order', order: 'ASC'}}>
            <Datagrid>
                <ImageField source={'thumbnail'}/>
                <TextField source="name"/>
                <NumberField source={'display_order'}/>
                <FormattedDateTimeField source="created_at"/>
                
                <EditButton/>
                <SoftDeleteWithDialogAction/>
            </Datagrid>
        </List>
    );
};
export default PodcastTopicList;
