import * as React from 'react';
import { Loading, useGetOne } from 'react-admin';

const ItemName = ({ productId }: { productId: any }) => {

    const { data, isLoading, error } = useGetOne('ecom_products', { id:productId });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
        <div>
            {data?.name}
        </div>
    );
};


export default ItemName;
