import * as React from 'react';
import {

    List,
    EditButton,
    Datagrid,
    TextField, ReferenceField, NumberField, BooleanField,
    useRecordContext,
} from 'react-admin';
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import FullNameField from "../../common/FullNameField";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import { format } from 'date-fns';

const  FormattedStartEndField = (props: any) => {
    const record = useRecordContext<any>();
    return record ? (
        <div className={'flex flex-col text-sm space-y-1'}>
            {
                record.started_at && <div className={'flex items-center space-x-2'}>
                    <div className={'whitespace-nowrap'}>
                        {format(new Date(record.started_at), 'dd/MM/yyyy hh:mm a')}
                    </div>
                </div>
            }
            {
                record.expiring_at && <div className={'flex items-center  space-x-2'}>
                    <div className={'whitespace-nowrap'}>
                        {format(new Date(record.expiring_at), 'dd/MM/yyyy hh:mm a')}
                    </div>
                </div>
            }
        </div>
    ) : null;
};

FormattedStartEndField.defaultProps = {
    source: 'started_at',
    label: 'Start - End',
};

const  CouponDetails = (props: any) => {
    const record = useRecordContext<any>();
    return record ? (
        <div className={'flex flex-col text-sm space-y-1'}>
             {
                record?.discount_in_percentage && <div className={'flex items-center space-x-2'}>
                    <p className='font-bold'>Discount in percentage :-</p>
                    <p className=''>{record?.discount_in_percentage}</p>
                </div>
            }
            {
                record.expiring_at && <div className={'flex items-center  space-x-2'}>
                    <div className={'whitespace-nowrap'}>
                        {format(new Date(record.expiring_at), 'dd/MM/yyyy hh:mm a')}
                    </div>
                </div>
            }
        </div>
    ) : null;
};

CouponDetails.defaultProps = {
    source: 'max_discount_price',
    label: 'Coupon Details',
};
const CouponList = () => {
    return (
        <List sort={{ field: 'created_at', order: 'DESC' }}>
            <Datagrid>
                <TextField source="code" />
                {/* <CouponDetails  /> */}
                <NumberField source="discount_in_percentage" />
                <NumberField source="max_discount_price" />
                <NumberField source="discount_in_price" />
                <NumberField source="max_total_usages" />
                <NumberField source="max_usage_by_per_student" />
                <BooleanField source={'hidden'} />
                <ReferenceField reference={'ecom_product_templates'} label={'Product Template'} source={'product_template_id'}>
                    <TextField fullWidth source="name" />
                </ReferenceField>
                <ReferenceField reference={'ecom_categories'} label={'Category'} source={'category_id'}>
                    <TextField fullWidth source="name" />
                </ReferenceField>
                <ReferenceField reference={'users'} label={'Created By'} source={'created_by_id'}>
                    <FullNameField fullWidth source="first_name" />
                </ReferenceField>
                <FormattedStartEndField source="started_at" />
                <FormattedDateTimeField source="created_at" />

                <EditButton />
                <DeleteWithDialogAction />
            </Datagrid>
        </List>
    );
};
export default CouponList;
