import * as React from 'react';
import {

    List,
    Datagrid,
    TextField,
    TopToolbar,
    CreateButton,
    ExportButton, useRecordContext, NumberField, FieldProps, Link, BooleanField,
} from 'react-admin';
import ThumbnailField from "../../common/ThumbnailField";
import {useNavigate} from "react-router-dom";
import {LoadingSection} from "../../common/LoadingSection";
import {useParams} from "react-router";
import Button from "@mui/material/Button";
import {
    ContentCopy,
    DocumentScanner,
    Edit,
    FormatListNumbered,
    HourglassBottom,
    Settings,
    ViewCozy
} from "@mui/icons-material";
import {Tests} from "../../custom";
import SectionsLinkField from "./SectionsLinkField";
import {NoDataSection} from "../../common/NoDataSection";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import {DeleteWithDialogAction} from "../../common/DeleteWithDialogAction";
import QuestionResponsesLinkField from "./QuestionResponsesLinkField";
import {BulkUploadQuestionsAction} from "./BulkUploadQuestions";
import {format} from "date-fns";
import {Tooltip} from "@mui/material";

const ListActions = ({programId, testSeriesId}: { programId: string, testSeriesId: string }) => {
    const navigate = useNavigate();
    return <TopToolbar>
        <CreateButton onClick={(e) => {
            e.preventDefault();
            navigate(`/programs/${programId}/test_serieses/${testSeriesId}/tests/create`)
        }}/>
        <ExportButton/>
    </TopToolbar>
};

const CloneBatch = (props: any) => {
    const record = useRecordContext();
    const {programId, testSeriesId} = useParams();

    const keysToCopy = ['image', "name", 'slug', 'status', 'type', 'test_series_id', 'start_time', 'end_time', 'max_duration_in_minutes'];
    const newRecord: any = {}
    keysToCopy.forEach((b) => {
        newRecord[b] = record[b];
    })
    const navigate = useNavigate();
    return <Tooltip title="Clone Test">
        <Button onClick={() => {
            navigate(`/programs/${programId}/test_serieses/${testSeriesId}/tests/create?source=${encodeURIComponent(JSON.stringify(newRecord))}`);
        }}>
            <ContentCopy/>
        </Button>
    </Tooltip>
}

const EditButton = () => {
    const navigate = useNavigate();
    const record: Tests = useRecordContext<Tests>();
    return <Tooltip title="Edit Test">
        <Button onClick={(e) => {
            navigate(`/programs/${record.program_id}/test_serieses/${record.test_series_id}/tests/${record.id}/edit`)
        }}>
            <Edit/>
        </Button>
    </Tooltip>
};

const SectionButtonVisibility = () => {
    const record: any = useRecordContext<any>();
    if (record.category == 'OBJECTIVE') {
        return (
            <SectionsLinkField/>
        );
    } else return (<div>-</div>)
}
const BulkUploadButtonVisibility = () => {
    const record: any = useRecordContext<any>();
    if (record.category == 'OBJECTIVE') {
        return (
            <BulkUploadQuestionsAction/>
        );
    } else return (<div>-</div>)
}

const QuestionPaperViewPage = (props: FieldProps<Tests>) => {
    const record = useRecordContext<Tests>();
    if (!record) {
        return null;
    }
    return (
        <Link
            to={`/programs/${record.program_id}/test_serieses/${record.test_series_id}/tests/${record.id}/question_paper`}>
            <Tooltip title="Show question paper">
                <Button onClick={(e) => {
                }}>
                    <DocumentScanner/>
                </Button>
            </Tooltip>
        </Link>
    );
};

QuestionPaperViewPage.defaultProps = {};

const Actions = (props: FieldProps<Tests>) => {
    return (
        <div className={'flex'}>
            <QuestionPaperViewPage/>
            <BulkUploadButtonVisibility/>
            <CloneBatch/>
            <EditButton/>
            <DeleteWithDialogAction/>
        </div>
    );
};

Actions.defaultProps = {
    label: 'Actions'
};

export const TestMeta = () => {
    const record = useRecordContext();
    return <div className={'flex space-x-2'}>
        {
            <div className={'w-14 text-center'}>
                {
                    record.type === 'LIVE' && <div className={'text-xs px-1 py-0.5 rounded-md bg-red-500 text-white'}>
                        Live
                    </div>
                }
                {
                    record.type === 'EXERCISE' &&
                    <div className={'text-xs px-1 py-0.5 rounded-md bg-amber-500 text-white'}>
                        Exercise
                    </div>
                }
                {
                    record.type === 'NORMAL' &&
                    <div className={'text-xs px-1 py-0.5 rounded-md bg-blue-500 text-white'}>
                        Normal
                    </div>
                }

                {
                    record.type === 'MOCK' &&
                    <div className={'text-xs px-1 py-0.5 rounded-md bg-purple-500 text-white'}>
                        Mock
                    </div>
                }

            </div>
        }
        <div className={'flex flex-col '}>
            <div className={'flex space-x-1 items-center'}>
                <FormatListNumbered style={{fontSize: '16px'}}/>
                <div className={'whitespace-nowrap'}>
                    {record.total_questions} questions
                </div>
            </div>
            <div className={'flex space-x-1 items-center'}>
                <HourglassBottom style={{fontSize: '16px'}}/>
                {record.max_duration_in_minutes} min
            </div>


        </div>
    </div>
}
TestMeta.defaultProps = {
    label: 'Metadata'
}
const FormattedStartEndTime = (props: any) => {
    const record = useRecordContext<any>();
    return record ? (
        <div className={'flex flex-col text-sm space-y-1'}>
            {
                record.start_time && <div className={'flex items-center space-x-2'}>
                    <div className={'w-4 h-4 flex flex-col items-center justify-center rounded-full bg-blue-200'}>
                        S
                    </div>
                    <div className={'whitespace-nowrap'}>
                        {format(new Date(record.start_time), 'dd/MM/yyyy hh:mm a')}
                    </div>
                </div>
            }
            {
                record.end_time && <div className={'flex items-center  space-x-2'}>
                    <div className={'w-4 h-4 flex flex-col items-center justify-center rounded-full bg-red-200'}>
                        E
                    </div>
                    <div className={'whitespace-nowrap'}>
                        {format(new Date(record.end_time), 'dd/MM/yyyy hh:mm a')}
                    </div>
                </div>
            }
        </div>
    ) : null;
};

FormattedStartEndTime.defaultProps = {
    source: 'start_time',
    label: 'Start - End',
};


const TestList = () => {
    const {programId, testSeriesId} = useParams();
    const navigate = useNavigate();
    if (!testSeriesId) {
        return <LoadingSection/>
    }
    return (
        <List sort={{field: 'created_at', order: 'DESC'}}
              empty={<NoDataSection resource={'tests'} onCreate={() => {
                  navigate(`/programs/${programId}/test_serieses/${testSeriesId}/tests/create`)
              }}/>}
              resource={'tests'} actions={
            <ListActions programId={programId + ''} testSeriesId={testSeriesId || ''}/>
        } filter={{
            program_id: programId,
            test_series_id: testSeriesId,
        }}>
            <Datagrid>

                {/*<ThumbnailField source="image"/>*/}
                <TextField source="name"/>
                <BooleanField source="is_demo"/>
                <TextField source="slug"/>
                <NumberField source="display_order"/>
                <TestMeta/>
                <FormattedStartEndTime source={'start_time'}/>
                <QuestionResponsesLinkField />
                <SectionButtonVisibility/>
                <Actions/>
            </Datagrid>
        </List>
    );
};
export default TestList;
