import * as React from 'react';
import {
    TextInput,
    Create,
    required, BooleanInput, SimpleForm, SelectInput, useCreate, BooleanField, useUpdate, ReferenceInput,
} from 'react-admin';


import ImageInput from "../../common/inputs/ImageInput";
import {STATUSES} from "../../common/constants";
import {Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import HTMLEditorInput, {validHtml} from "../../common/inputs/HTMLEditorInput";

const OrganizationCreate = () => {
    const [create, {isLoading}] = useCreate();
    const [update, {data, isLoading: updating, error}] = useUpdate();
    const navigate = useNavigate();

    const postSave = (data: any) => {
        if (isLoading || updating) {
            return;
        }
        const d: any = {
            ...data
        }
        const user = {
            ...data.primary_user,
            status: 'ACTIVE',
        }

        delete d.primary_user;
        create('organizations', {data: d}, {
            onSuccess: (e) => {
                create('users', {data: {...user, organization_id: e.id}}, {
                    onSuccess: (ee) => {
                        update('organizations', {id: e.id, data: {primary_user_id: ee.id}, previousData: e}, {
                            onSuccess: (eee) => {
                                navigate(`/organizations`)
                            }
                        });
                    }
                });
            }
        });
    }
    return <Create>
        <SimpleForm onSubmit={postSave}>
            <ImageInput source="logo" fullWidth/>
            <TextInput source="name" validate={[required()]} fullWidth/>
            <SelectInput source="status" choices={STATUSES} fullWidth/>
            <BooleanInput source={'enable_public_register'} validate={[required()]}/>
            <BooleanInput source={'block_multi_device_login'} validate={[required()]}/>
            <div style={{height: '1px', width: '100%', backgroundColor: '#cccccc', margin: '20px 0'}}/>
            <Typography>Primary Admin</Typography>
            <TextInput source="primary_user.first_name" validate={[required()]} fullWidth/>
            <TextInput source="primary_user.last_name" validate={[required()]} fullWidth/>
            <TextInput source="primary_user.phone" validate={[required()]} fullWidth/>
            <TextInput source="primary_user.email" fullWidth/>
            <div style={{height: '1px', width: '100%', backgroundColor: '#cccccc', margin: '20px 0'}}/>
            <Typography>Social Details</Typography>

            <TextInput source="instagram" fullWidth/>
            <TextInput source="telegram" fullWidth/>
            <TextInput source="discord" fullWidth/>
            <TextInput source="facebook" fullWidth/>
            <TextInput source="telegram" fullWidth/>
            <TextInput source="support_additional_phone" fullWidth/>
            <TextInput source="linkedin" fullWidth/>
            <TextInput source="youtube" fullWidth/>
            <div style={{height: '1px', width: '100%', backgroundColor: '#cccccc', margin: '20px 0'}}/>
            <Typography>Contact Details</Typography>


            <TextInput source="support_email" fullWidth/>
            <TextInput source="support_phone" fullWidth/>
            <TextInput source="support_whatsapp" fullWidth/>
            <TextInput source="address_text" fullWidth/>
            <TextInput source="address_google_map_link" fullWidth/>
            <div style={{height: '1px', width: '100%', backgroundColor: '#cccccc', margin: '20px 0'}}/>

            <HTMLEditorInput source={'about_us'} validate={[required(), validHtml]} fullWidth/>
            <HTMLEditorInput source={'terms_and_conditions'} validate={[required(), validHtml]} fullWidth/>
            <HTMLEditorInput source={'privacy_policies'} validate={[required(), validHtml]} fullWidth/>
        </SimpleForm>
    </Create>
};


export default OrganizationCreate;
