import * as React from 'react';
import {

    List,
    EditButton,
    Datagrid,
    TextField, NumberField, ReferenceField,
} from 'react-admin';
import ThumbnailField from "../../common/ThumbnailField";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import ProductTemplatesLinkField from "./ProductTemplateLinkField";
import AttributesLinkField from "./AttributesLink";
import {DeleteWithDialogAction} from "../../common/DeleteWithDialogAction";


const CategoryList = () => {
    return (
        <List sort={{field: 'display_order', order: 'ASC'}}>
            <Datagrid>
                {/* <ThumbnailField source="image"/> */}
                <TextField source="name"/>
                <TextField source="slug"/>
                <NumberField source="display_order"/>
                <ProductTemplatesLinkField/>
                <AttributesLinkField/>
                <ReferenceField reference={'ecom_categories'} source={'parent_id'}>
                    <TextField source={'name'}/>
                </ReferenceField>
                <TextField source="status"/>
                <FormattedDateTimeField source="created_at"/>
                
                <DeleteWithDialogAction/>
                <EditButton/>
            </Datagrid>
        </List>
    );
};
export default CategoryList;
