import * as React from 'react';
import {Create, ReferenceInput, TextInput, SimpleForm, required, SelectInput,} from 'react-admin';
import ImageInput from '../../common/inputs/ImageInput';
import {LoadingSection} from "../../common/LoadingSection";

import {useParams} from "react-router";
import HTMLEditorInput, {validHtml} from '../../common/inputs/HTMLEditorInput';
import {STATUSES} from "../../common/constants";


const SubjectCreate = () => {
    let {programId} = useParams();

    if (!programId) {
        return <LoadingSection/>
    }
    return <Create redirect={(e: any, id: any) => {
        return `programs/${programId}/subjects`
    }} resource={'subjects'}>
        <SimpleForm defaultValues={{program_id: programId}}>
            <ImageInput source="image" fullWidth/>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <TextInput source="slug" fullWidth validate={[required()]}/>
            <TextInput source="display_order" type={'number'} fullWidth/>

            <ReferenceInput label="Program" source="program_id" reference="programs">
                <SelectInput optionText="name" disabled fullWidth validate={[required()]}/>
            </ReferenceInput>
            <HTMLEditorInput source={'description'} fullWidth validate={[required(), validHtml]}/>
        </SimpleForm>
    </Create>
};

export default SubjectCreate;
