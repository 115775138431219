import * as React from 'react';
import {

    List,
    Datagrid,
    TextField,
    DateField,
    TopToolbar,
    ReferenceField,
    CreateButton,
    ExportButton, useRecordContext, ImageField,
    NumberField,
} from 'react-admin';
import { useNavigate } from "react-router-dom";
import { LoadingSection } from "../../common/LoadingSection";
import { useParams } from "react-router";
import Button from "@mui/material/Button";
import { Add, Description, Edit, PictureAsPdf, Videocam } from "@mui/icons-material";
import { Sections } from "../../custom";
import { NoDataSection } from "../../common/NoDataSection";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";

const Actions = ({
    programId,
    testSeriesId,
    sectionId,
    testId
}: { programId: string, testId: string, sectionId: string, testSeriesId: string }) => {
    const navigate = useNavigate();
    return <div style={{ display: 'flex' }}>
        {
            ['SINGLE', 'MULTIPLE', 'NUMERIC'].map((type, index) => {

                return <Button
                    key={index}
                    startIcon={<Add />}
                    onClick={() => {
                        const data = { type };
                        navigate(`/programs/${programId}/test_serieses/${testSeriesId}/tests/${testId}/sections/${sectionId}/questions/create?source=${encodeURIComponent(JSON.stringify(data))}`)
                    }}
                    sx={{ padding: '0 5px' }}>
                    Add a {type}
                </Button>
            })
        }
    </div>
}
const ListActions = (props: { programId: string, testId: string, sectionId: string, testSeriesId: string }) => {
    return <TopToolbar>
        <Actions {...props} />
        <ExportButton />
    </TopToolbar>
};

const EditButton = () => {
    const navigate = useNavigate();
    const record: Sections = useRecordContext<Sections>();
    const { testSeriesId, programId, sectionId, testId } = useParams();

    return <Button startIcon={<Edit />} onClick={(e) => {
        navigate(`/programs/${programId}/test_serieses/${testSeriesId}/tests/${testId}/sections/${sectionId}/questions/${record.id}/edit`)
    }}>
        Edit
    </Button>

};

const QuestionList = () => {
    const navigate = useNavigate();
    const { testSeriesId, programId, sectionId, testId } = useParams();
    if (!testId) {
        return <LoadingSection />
    }
    return (
        <List

            empty={<NoDataSection resource={'questions'}
                actions={<Actions programId={programId || ''} testId={testId || ''}
                    sectionId={sectionId || ''} testSeriesId={testSeriesId || ''} />}
                onCreate={() => {
                    navigate(`/programs/${programId}/test_serieses/${testSeriesId}/tests/${testId}/sections/${sectionId}/questions/create`)
                }} />}
            sort={{ field: 'display_order', order: 'ASC' }} resource={'questions'} actions={
                <ListActions sectionId={sectionId || ''} programId={programId || ''} testSeriesId={testSeriesId || ''}
                    testId={testId || ''} />
            } filter={{
                section_id: sectionId,
            }}>
            <Datagrid>
                <ImageField source="image" />
                <TextField source="content" />
                <NumberField source="display_order" />
                <TextField source="type" />
                <FormattedDateTimeField source="created_at" />

                <EditButton />
                <DeleteWithDialogAction />
            </Datagrid>
        </List>
    );
};
export default QuestionList;
