import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required, NullableBooleanInput, ReferenceInput, SelectInput,
} from 'react-admin';

import ImageInput from "../../common/inputs/ImageInput";
import PDFInput from "../../common/inputs/PDFInput";
import AudioInput from "../../common/inputs/AudioInput";
import HTMLEditorInput from "../../common/inputs/HTMLEditorInput";
import {EditToolbar} from "../../common/EditToolbar";

const PodcastEdit = () => {
    return <Edit title={<Title/>} redirect={'/podcasts'}>
        <SimpleForm  toolbar={<EditToolbar/>}>
            <TextInput source="title" fullWidth/>
            <ImageInput source={'thumbnail'} validate={[required()]}/>
            <AudioInput source={'url'} validate={[required()]}/>
            <TextInput source="display_order" type={'number'} fullWidth/>
            <ReferenceInput source="topic_id" reference="podcast_topics" fullWidth>
                <SelectInput optionText={'name'} fullWidth/>
            </ReferenceInput>
            <HTMLEditorInput source={'description'} fullWith/>
        </SimpleForm>
    </Edit>
};

const Title = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.download_categories.name', {smart_count: 1})} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default PodcastEdit;
