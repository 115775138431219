import { Create, TextInput, required, SimpleForm, DateInput, SelectInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { Batches, Programs } from '../../custom';


export const ProgramRender = (choice: Programs) => `${choice.name}`;
export const BatchesRender = (choice: Batches) => `${choice.title
    }`;

const DemoVideosCategoriesCreate = () => {

    return <>
        <Create redirect={'/demo_video_categories'}>
            <SimpleForm>
                <TextInput source="name" fullWidth validate={[required()]} />
                <ReferenceInput name={'Programs'} source={'program_id'} reference={'programs'}
                    fullwidth>
                    <AutocompleteInput optionText={ProgramRender}
                        debounce={500} fullWidth />
                </ReferenceInput>
                <ReferenceInput name={'batches'} source={'batch_id'} reference={'batches'}
                    fullwidth>
                    <AutocompleteInput optionText={BatchesRender}
                        debounce={500} fullWidth />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    </>

};

export default DemoVideosCategoriesCreate;
