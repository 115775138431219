import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required, SelectInput, ReferenceInput, minValue,
} from 'react-admin';

import {Ecom_Product_Templates,} from "../../custom";
import ImageInput from "../../common/inputs/ImageInput";
import {EditToolbar} from "../../common/EditToolbar";

import {useParams} from "react-router";
import {LoadingSection} from "../../common/LoadingSection";
import HTMLEditorInput, {validHtml} from "../../common/inputs/HTMLEditorInput";
import {USER_STATUSES} from "../../common/constants";

const ProductTemplateEdit = () => {
    let {categoryId, productTemplateId} = useParams();
    if (!productTemplateId) {
        return <LoadingSection/>
    }
    return <Edit title={<ProductTemplateTitle/>} redirect={() => {
        return `ecom_categories/${categoryId}/ecom_product_templates`
    }} resource={'ecom_product_templates'} id={productTemplateId}>
        <SimpleForm toolbar={<EditToolbar/>}>
            <ImageInput source="images" multiple fullWidth validate={[required()]}/>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <ReferenceInput reference={'ecom_categories'} source={'category_id'}>
                <SelectInput fullWidth disabled optionText="name"/>
            </ReferenceInput>
            <TextInput type={'number'} source="height_in_mtr" prefix={'In Meters'} fullWidth validate={[required(),minValue(0)]}/>
            <TextInput type={'number'} source="width_in_mtr" prefix={'In Meters'} fullWidth validate={[required(),minValue(0)]}/>
            <TextInput type={'number'} source="length_in_mtr" prefix={'In Meters'} fullWidth validate={[required(),minValue(0)]}/>
            <TextInput type={'number'} source="weight_in_grams" prefix={'In Grams'} fullWidth validate={[required(),minValue(0)]}/>
            <SelectInput source="status" choices={USER_STATUSES} fullWidth/>
            <TextInput source="text_description" multiline rows={4} fullWidth validate={[required()]}/>
            <HTMLEditorInput source={'html_description'} fullWidth validate={[required(), validHtml]}/>
        </SimpleForm>
    </Edit>
};

const ProductTemplateTitle = () => {
    const record = useRecordContext<Ecom_Product_Templates>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.product_templates.name', {smart_count: 1})} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default ProductTemplateEdit;
