import * as React from 'react';
import {

    List,
    Datagrid,
    TextField,
    DateField,
    TopToolbar,
    ReferenceField,
    CreateButton,
    ExportButton, useRecordContext, FieldProps, Link, ReferenceManyCount, useDataProvider, Loading, BooleanField,
} from 'react-admin';
import ThumbnailField from "../../common/ThumbnailField";
import {useNavigate} from "react-router-dom";
import {LoadingSection} from "../../common/LoadingSection";
import {useParams} from "react-router";
import Button from "@mui/material/Button";
import {Edit, Visibility} from "@mui/icons-material";
import {Batches, Subjects, Test_Serieses} from "../../custom";
import {NoDataSection} from "../../common/NoDataSection";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import {DeleteWithDialogAction} from "../../common/DeleteWithDialogAction";
import {useQuery} from "react-query";
import {ProductTemplateFormModal} from "../subjects/SubjectList";
import StatusTags from "../../common/StatusTags";

const ListActions = ({programId}: { programId: string }) => {
    const navigate = useNavigate();
    return <TopToolbar>
        <CreateButton onClick={(e) => {
            e.preventDefault();
            navigate(`/programs/${programId}/test_serieses/create`)
        }}/>
        <ExportButton/>
    </TopToolbar>
};

const EditButton = () => {
    const navigate = useNavigate();
    const record: Test_Serieses = useRecordContext<Test_Serieses>();
    return <Button  onClick={(e) => {
        navigate(`/programs/${record.program_id}/test_serieses/${record.id}/edit`)
    }}>
        <Edit/>
    </Button>

};

const TestsLinkField = (props: FieldProps<Test_Serieses>) => {
    const record = useRecordContext<Test_Serieses>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/programs/${record.program_id}/test_serieses/${record.id}/tests`}>
            Tests
            (
            <ReferenceManyCount
                label="Tests"
                reference="tests"
                target="test_series_id"
            />
            )
        </Link>
    );
};
const ShowTestSeries = (props: FieldProps<Test_Serieses>) => {
    const record = useRecordContext<Test_Serieses>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/programs/${record.program_id}/test_serieses/${record.id}/show`}>
            <Button>
                <Visibility/>
            </Button>
        </Link>
    );
};

export const ProductTemplateField = (props: FieldProps<Batches>) => {
    const dataProvider = useDataProvider();
    const record = useRecordContext<Batches>();
    const {data: productsData, isLoading, error: resultError} = useQuery(
        ['products_test_series_' + record.id, 'getList', {test_series_id: record.id}],
        () => dataProvider.getList('ecom_products', {
            filter: {
                product_template:  {
                    format: 'hasura-raw-query',
                    value: {
                        test_series_id:{
                            _eq: record?.id
                        }
                    },
                }
            },
            pagination: {page: 1, perPage: 20},
            sort: {field: 'created_at', order: 'DESC'}
        })
    );
    const products = productsData?.data;
    if(isLoading){
        return <div>...</div>
    }
    if(products?.length){
        return <div className={'min-w-[90px] text-center px-2 py-0.5 rounded-md bg-blue-500 text-white no-wrap text-xs'}>
            Products ({products.length})
        </div>
    }
    return <ProductTemplateFormModal test_series_id={record.id}/>;
};

ProductTemplateField.defaultProps = {
    label: 'Product Template'
};
const Actions = (props: FieldProps<Batches>) => {
    return <div className={'flex'}>
        <ShowTestSeries/>
        <EditButton/>
        <DeleteWithDialogAction/>
    </div>;
};

Actions.defaultProps = {
    label: 'Actions'
};
const TestSeriesList = () => {
    const params = useParams();
    const navigate = useNavigate();
    if (!params['programId']) {
        return <LoadingSection/>
    }
    return (
        <List
            empty={<NoDataSection resource={'test_serieses'} onCreate={() => {
                navigate(`/programs/${params['programId']}/test_serieses/create`)
            }}/>}
            sort={{field: 'created_at', order: 'DESC'}} resource={'test_serieses'} actions={
            <ListActions programId={params['programId'] + ''}/>
        } filter={{
            program_id: params['programId']
        }}>
            <Datagrid>

                <ThumbnailField source="image"/>
                <TextField source="title"/>
                <TextField source="display_order"/>
                <ProductTemplateField/>
                <TextField source="slug"/>
                <StatusTags source="status" label={'Status'}/>
                <TextField source="type"/>
                <BooleanField source="is_demo"/>
                <ReferenceField reference={'programs'} source={'program_id'}>
                    <TextField source="name"/>
                </ReferenceField>

                <TestsLinkField/>
                <FormattedDateTimeField source="created_at"/>
                <Actions/>
            </Datagrid>
        </List>
    );
};
export default TestSeriesList;
