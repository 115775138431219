import React from 'react'
import { Datagrid, EditButton, List, NumberField, TextField } from 'react-admin'
import { DeleteWithDialogAction } from '../../common/DeleteWithDialogAction'
import DemoVideoLinkField from './ProgramDemoVideoLinkField'

const DemoVideosCategoriesList = () => {
    return (
        <List
        >
            <Datagrid>
                <TextField source="name" />
                <DemoVideoLinkField />
                <EditButton />
                <DeleteWithDialogAction />
            </Datagrid>
        </List>
    )
}

export default DemoVideosCategoriesList
