import * as React from 'react';
import {

    List,
    Datagrid,
    TextField,
    DateField,
    TopToolbar,
    ReferenceField,
    CreateButton,
    ExportButton, useRecordContext, ImageField, RichTextField,
} from 'react-admin';
import { useNavigate } from "react-router-dom";
import { LoadingSection } from "../../common/LoadingSection";
import { useParams } from "react-router";
import Button from "@mui/material/Button";
import { Add, Description, Edit, PictureAsPdf, Videocam } from "@mui/icons-material";
import { Sections } from "../../custom";
import { NoDataSection } from "../../common/NoDataSection";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";

const Actions = ({
    programId,
    exerciseId,
    testId,batchId
}: { programId: string, testId: string, exerciseId: string ,batchId:string}) => {
    const navigate = useNavigate();
    return <div style={{ display: 'flex' }}>
        {
            ['SINGLE', 'MULTIPLE', 'NUMERIC'].map((type, index) => {

                return <Button
                    key={index}
                    startIcon={<Add />}
                    onClick={() => {
                        const data = { type };
                        navigate(`/batches/${batchId}/programs/${programId}/exercise/${exerciseId}/tests/${testId}/questions/create?source=${encodeURIComponent(JSON.stringify(data))}`)
                    }}
                    sx={{ padding: '0 5px' }}>
                    Add a {type}
                </Button>
            })
        }
    </div>
}
const ListActions = (props: { batchId:string, programId: string, testId: string, exerciseId: string }) => {
    return <TopToolbar>
        <Actions {...props} />
        <ExportButton />
    </TopToolbar>
};

const EditButton = () => {
    const navigate = useNavigate();
    const record: Sections = useRecordContext<Sections>();
    const {batchId, exerciseId, programId, testId } = useParams();

    return <Button startIcon={<Edit />} onClick={(e) => {
        navigate(`/batches/${batchId}/programs/${programId}/exercise/${exerciseId}/tests/${testId}/questions/${record.id}/edit`)
    }}>
        Edit
    </Button>

};

const BatchesQuestionsList = () => {
    const navigate = useNavigate();
    const {batchId, exerciseId, programId, testId } = useParams();
    if (!testId) {
        return <LoadingSection />
    }
    return (
        <List
            empty={<NoDataSection resource={'questions'}
                actions={<Actions batchId={batchId || ''} programId={programId || ''} testId={testId || ''}
                    exerciseId={exerciseId || ''} />}
                onCreate={() => {
                    navigate(`/batches/${batchId}/programs/${programId}/exercise/${exerciseId}/tests/${testId}/questions/create`)
                }} />}
            sort={{ field: 'created_at', order: 'DESC' }} resource={'questions'} actions={
                <ListActions batchId={batchId || ''} programId={programId || ''} exerciseId={exerciseId || ''}
                    testId={testId || ''} />
            } filter={{
                test_id: testId,
            }}>
            <Datagrid>
                <ImageField source="image" />
                <RichTextField source="content"/>
                <TextField source="type" />
                <TextField source="negative_marks" />
                <TextField source="positive_marks" />
                <FormattedDateTimeField source="created_at" />
                <EditButton />
                <DeleteWithDialogAction />
            </Datagrid>
        </List>
    );
};
export default BatchesQuestionsList;
