import * as React from 'react';
import {
    AppBar,
    Logout, useDataProvider, useGetIdentity,
    UserMenu,
    useTranslate,
    useUserMenu,
} from 'react-admin';
import { Link, useNavigate } from 'react-router-dom';
import {
    Box,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery,
    Theme,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import Logo from './Logo';
import Button from "@mui/material/Button";
import { ArrowLeft, KeyboardArrowLeft } from "@mui/icons-material";
import { useQuery } from "react-query";

const ConfigurationMenu = React.forwardRef((props, ref) => {
    const translate = useTranslate();
    const { onClose, } = useUserMenu();
    return (
        <MenuItem
            component={Link}
            // @ts-ignore
            ref={ref}
            {...props}
            to="/configuration"
            onClick={onClose}
        >
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText>{translate('pos.configuration')}</ListItemText>
        </MenuItem>
    );
});
const CustomUserMenu = () => (
    <UserMenu>
        <ConfigurationMenu />
        <Logout />
    </UserMenu>
);
const OrganizationName = React.memo(() => {
    const dataProvider = useDataProvider();
    
    const TOKEN = localStorage.getItem("token");
    const [organization, setOrganization] = React.useState({ name: "" });

    React.useEffect(() => {
        if (TOKEN && dataProvider) {
            dataProvider.getList('organizations', {
                filter: {},
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'created_at', order: 'DESC' }
            }).then(response => {
                setOrganization(response.data?.[0] || { name: "decode" });
            }).catch(error => {
                console.error("Error fetching organization data:", error);
            });
        }
    }, [TOKEN, dataProvider]);

    return <div>{organization.name}</div>;
});


const CustomAppBar = (props: any) => {
    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );
    const navigate = useNavigate();
    return (
        <AppBar
            {...props}
            color="secondary"
            elevation={1}
            userMenu={<CustomUserMenu />}
        >
            <Button startIcon={<KeyboardArrowLeft />}
                onClick={() => navigate(-1)}>Back</Button>
            <div className={'w-4'} />
            <Typography
                variant="h6"
                color="inherit"
                sx={{
                    flex: 1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
                id="react-admin-title"
            />
            {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
            <OrganizationName />
        </AppBar>
    );
};

export default CustomAppBar;
