import * as React from 'react';
import {Create, TextInput, required, SimpleForm, DateInput, SelectInput, ReferenceInput, minValue} from 'react-admin';
import ImageInput from "../../common/inputs/ImageInput";

import {useParams} from "react-router";
import {LoadingSection} from "../../common/LoadingSection";
import HTMLEditorInput, {validHtml} from "../../common/inputs/HTMLEditorInput";


const ProductTemplatesCreate = () => {
    let {categoryId} = useParams();
    if (!categoryId) {
        return <LoadingSection/>
    }
    return <Create resource={'ecom_product_templates'} redirect={(e: any, id: any) => {
        return `ecom_categories/${categoryId}/ecom_product_templates`
    }}>
        <SimpleForm  defaultValues={{category_id: categoryId}}>
            <ImageInput source="images" multiple fullWidth validate={[required()]}/>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <ReferenceInput reference={'ecom_categories'} source={'category_id'}>
                <SelectInput disabled fullWidth optionText="name"/>
            </ReferenceInput>
            <TextInput type={'number'} source="height_in_mtr" prefix={'In Meters'} fullWidth validate={[required(),minValue(0)]}/>
            <TextInput type={'number'} source="width_in_mtr" prefix={'In Meters'} fullWidth validate={[required(),minValue(0)]}/>
            <TextInput type={'number'} source="length_in_mtr" prefix={'In Meters'} fullWidth validate={[required(),minValue(0)]}/>
            <TextInput type={'number'} source="weight_in_grams" prefix={'In Grams'} fullWidth validate={[required(),minValue(0)]}/>
            <TextInput source="text_description" multiline rows={4} fullWidth validate={[required()]}/>
            <HTMLEditorInput source={'html_description'} fullWidth validate={[required(), validHtml]}/>
        </SimpleForm>
    </Create>
};

export default ProductTemplatesCreate;
