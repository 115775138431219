import * as React from 'react';
import {useRecordContext} from 'react-admin';
import clsx from "clsx";

const StatusTags = (props: { source: string; label?: string }) => {
    const record = useRecordContext<any>();
    if (!record) return null;
    if(props.source === 'chapter_status' && !record['chapter_id']){
        return <></>
    }
    if(props.source === 'subject_status' && !record['subject_id']){
        return <></>
    }
    if(props.source === 'program_status' && !record['program_id']){
        return <></>
    }
    return <div className={clsx('text-white text-xs font-semibold px-2 inline-block py-0.5 capitalize text-center rounded-md whitespace-nowrap', {
        ['bg-blue-500 ']: record[props.source] === 'NEW',
        ['bg-green-500 ']: record[props.source] === 'ACTIVE',
        ['bg-red-500 ']: record[props.source] === 'IN_ACTIVE',
        ['bg-black']: record[props.source] === 'BLOCKED' || record[props.source] === 'LOCKED',
    })}>
        {
            record[props.source]?.replace('_', ' ')?.toLowerCase()
        }
    </div>;
};
export default StatusTags;
