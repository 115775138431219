import SegmentList from './SegmentList';
import SegmentEdit from './SegmentEdit';
import {Image} from "@mui/icons-material";
import SegmentCreate from "./SegmentCreate";

export default {
    list: SegmentList,
    edit: SegmentEdit,
    create: SegmentCreate,
    icon: Image,
};
