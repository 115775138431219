import * as React from 'react';
import {Link, FieldProps, useRecordContext, ReferenceManyCount} from 'react-admin';
import {Topics,} from "../../custom";

const ContentsLinkField = (props: FieldProps<Topics>) => {
    const record = useRecordContext<Topics>();
    if (!record) {
        return null;
    }
    return (
        <Link
            to={`/programs/${record.program_id}/subjects/${record.subject_id}/chapters/${record.chapter_id}/topics/${record.id}/contents`}>
            Contents
            (
            <ReferenceManyCount
                label="Contents"
                reference="contents"
                target="topic_id"
            />
            )
        </Link>
    );
};

ContentsLinkField.defaultProps = {
    label: 'Contents'
};

export default ContentsLinkField;
