import {CircularProgress} from "@mui/material";

import React, {useState, useCallback, useEffect} from 'react';
import {useCreate, useNotify} from "react-admin";
import {CheckCircle, HourglassTop, RefreshRounded} from "@mui/icons-material";
import {getPreSignedUrl, uploadFileToS3} from "../../common/inputs/VideoInput";

const DragDropImageUploader = () => {
    const notify = useNotify();
    const [dragging, setDragging] = useState(false);
    const [videos, setVideos] = useState([] as any[]);
    const [counter, setCounter] = useState(0);

    const dragOverHandler = (e: any) => {
        e.preventDefault();
        setDragging(true);
    };

    const dragLeaveHandler = (e: any) => {
        e.preventDefault();
        setDragging(false);
    };
    const [create, {isLoading}] = useCreate();
    useEffect(() => {
        localStorage.removeItem('uploadingVideos');
    }, []);
    useEffect(() => {
        for (let i = 0; i < videos.length; i++) {
            const uploadingVideosLocal = localStorage.getItem('uploadingVideos');
            let uploadingVideos: any = {};
            if (uploadingVideosLocal) {
                uploadingVideos = JSON.parse(uploadingVideosLocal) || {}
            }
            if (uploadingVideos[videos[i].id] === 'PENDING') {
                uploadingVideos[videos[i].id] = 'PROCESSING';
                setCounter(new Date().getTime())
                localStorage.setItem('uploadingVideos', JSON.stringify(uploadingVideos));
                const _video = videos[i];
                getPreSignedUrl('VIDEOS', _video.file?.name,).then((response) => {
                    let uploadUrl = response?.url;
                    uploadFileToS3(_video.file, _video.file?.type, uploadUrl, ()=>{}).then((res) => {
                        const url = `${uploadUrl?.split('?')[0]}`;
                        create('videos', {
                            data: {
                                qualities: ['240', '360', '480', '720'],
                                encodings: ['HLS'],
                                drm_enabled: false,
                                type: 'SECURE',
                                raw_url: url,
                                title: _video.file?.name
                            }
                        }, {
                            onSuccess: (e) => {
                                const uploadingVideosLocal = localStorage.getItem('uploadingVideos');
                                let uploadingVideos: any = {};
                                if (uploadingVideosLocal) {
                                    uploadingVideos = JSON.parse(uploadingVideosLocal) || {}
                                }
                                uploadingVideos[_video.id] = 'COMPLETED';
                                localStorage.setItem('uploadingVideos', JSON.stringify(uploadingVideos));
                                setCounter(new Date().getTime())
                            },
                            onError: () => {
                                const uploadingVideosLocal = localStorage.getItem('uploadingVideos');
                                let uploadingVideos: any = {};
                                if (uploadingVideosLocal) {
                                    uploadingVideos = JSON.parse(uploadingVideosLocal) || {}
                                }
                                uploadingVideos[_video.id] = 'FAILED';
                                localStorage.setItem('uploadingVideos', JSON.stringify(uploadingVideos));
                                setCounter(new Date().getTime())
                            }
                        });
                    });
                });
            }
        }
    }, [videos]);
    const dropHandler = useCallback((e) => {
        e.preventDefault();
        setDragging(false);
        let files = [...e.dataTransfer.files].filter(file => file.type.startsWith('video/mp4'));

        // @ts-ignore
        setVideos((prevImages: any) => {
            if ([...prevImages, ...files].length > 10) {
                notify(
                    'Max 10 videos can be selected at once',
                    {
                        type: 'error'
                    }
                );
                return prevImages;
            }
            return [...prevImages, ...files.map((f, i) => {
                const uploadingVideosLocal = localStorage.getItem('uploadingVideos');
                let uploadingVideos: any = {};
                if (uploadingVideosLocal) {
                    uploadingVideos = JSON.parse(uploadingVideosLocal) || {}
                }
                const id = new Date().getTime() + i;
                uploadingVideos[id] = 'PENDING';
                localStorage.setItem('uploadingVideos', JSON.stringify(uploadingVideos));
                return {
                    id,
                    status: 'PENDING',
                    file: f
                }
            })]
        });
    }, []);
    const getName = (f: any) => {
        return f.file?.name?.replace('.mp4', '')?.replace('.MP4', '')
    }
    const getStatus = (f: any) => {
        const id = f.id;
        const uploadingVideosLocal = localStorage.getItem('uploadingVideos');
        let uploadingVideos: any = {};
        if (uploadingVideosLocal) {
            uploadingVideos = JSON.parse(uploadingVideosLocal) || {}
        }
        if (uploadingVideos[id] === 'PENDING') {
            return <HourglassTop fontSize={'large'}/>
        }
        if (uploadingVideos[id] === 'PROCESSING') {
            return <CircularProgress/>
        }
        if (uploadingVideos[id] === 'COMPLETED') {
            return <CheckCircle fontSize={'large'}/>
        }
        if (uploadingVideos[id] === 'FAILED') {
            return <RefreshRounded fontSize={'large'}/>
        }
        return 'NA';
    }
    return (
        <div
            className={`uploader flex flex-col items-center justify-center min-h-[500px] p-4 bg-gray-100 rounded-md mt-4 ${dragging ? 'dragging' : ''}`}
            onDragOver={dragOverHandler}
            onDragLeave={dragLeaveHandler}
            onDrop={dropHandler}
        >
            {
                videos.length > 0 && <div className={'grid grid-cols-4 gap-4 w-full'}>
                    {
                        videos.map((video, index) =>
                            <div
                                className={'flex flex-col justify-between aspect-video shadow-md bg-white overflow-hidden rounded-md'}>
                                <div/>
                                <div className={'text-center text-gray-500'}>
                                    {
                                        getStatus(video)
                                    }
                                </div>
                                <div className={'bg-purple-500 text-white px-2 py-1'}>
                                    {getName(video)}
                                </div>
                            </div>
                        )
                    }
                </div>
            }
            {
                videos.length == 0 && <div className={'flex flex-col h-full items-center flex-1 justify-center'}>
                    <p>Drag & Drop Videos Here</p>

                </div>
            }
        </div>
    );
}

const VideoBulkUpload = () => {
    return <div>
        <DragDropImageUploader/>
    </div>
};

export default VideoBulkUpload;

