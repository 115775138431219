import CategoryList from './CategoryList';
import CategoryEdit from './CategoryEdit';
import CategoryCreate from "./CategoryCreate";
import {Category} from "@mui/icons-material";

export default {
    list: CategoryList,
    edit: CategoryEdit,
    create: CategoryCreate,
    icon: Category
};
