import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useController } from "react-hook-form";
import { BASE_URL } from "../constants";
import { Delete, PhotoCamera } from "@mui/icons-material";
import { useRecordContext } from "react-admin";

interface ImageInputWithTypeProps {
    source: string;
    type: string;
    typeId: string;
    label?: string;
    multiple?: boolean;
    placeholder?: string;
}

export const uploadFile = (file: File, type: string, typeId: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
    formData.append("typeId", typeId);

    return fetch(BASE_URL + "/admin/files/upload", {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Cookie': document.cookie // Include cookies
        }
    });
};

const ImageInputWithType = (props: ImageInputWithTypeProps) => {
    const { source, type, typeId, label, multiple, placeholder } = props;
    const { field } = useController({ name: source });
    const [loading, setLoading] = useState(false);
    const record = useRecordContext();

    // Function to check if images should be shown
    const showImages = () => {
        if (loading) {
            return false;
        }
        if (multiple && (field.value && field.value.length > 0)) {
            return true;
        }
        else if (!multiple && field.value) {
            return true;
        }
        return false;
    };

    // Function to render the uploaded images
    const renderImages = () => {
        const result: JSX.Element[] = [];
        if (multiple) {
            field.value?.forEach((v: string, index: number) => {
                result.push(
                    <div key={index} className="flex justify-between items-center">
                        <img src={v} alt={v} className="h-[100px]" />
                        <Button
                            onClick={() => {
                                const _images = JSON.parse(JSON.stringify(field.value));
                                _images.splice(index, 1);
                                field.onChange(_images);
                            }}
                            color="error"
                        >
                            <Delete />
                        </Button>
                    </div>
                );
            });
        } else if (field.value) {
            result.push(
                <div key="image" className="flex justify-between items-center">
                    <img src={field?.value} alt={field?.value} className="h-[100px]" />
                    <Button onClick={() => field.onChange("")} color="error">
                        <Delete />
                    </Button>
                </div>
            );
        }
        return result;
    };

    // Main render function
    return (
        <>
            {showImages() && <div className="p-2 rounded-md border w-full bg-gray-50 relative mb-4">{renderImages()}</div>}
            {loading && <CircularProgress />}
            <div className="w-full">
                <div className="p-2 rounded-md border w-full bg-gray-50 relative mb-2 flex items-center">
                    <PhotoCamera className="text-gray-500" fontSize="large" />
                    <Button>
                        Add {label || 'Image'}
                        <input
                            placeholder={placeholder}
                            style={{
                                position: "absolute",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                top: "0",
                                opacity: "0",
                            }}
                            accept={"image/*"}
                            multiple={multiple || false}
                            {...field}
                            value={""}
                            onChange={async (e) => {
                                const file = e.target.files?.[0];
                                if (!file) {
                                    return;
                                }
                                try {
                                    setLoading(true);
                                    const res = await uploadFile(file, type, typeId);
                                    if (res?.status === 201 || res?.status === 200) {
                                        const data = await res.json();
                                        if (data) {
                                            if (multiple) {
                                                const v = field.value || [];
                                                field.onChange([...v, `${data.url}`]);
                                            } else {
                                                field.onChange(`${data.url}`);
                                            }
                                        }
                                    } else {
                                        console.log(res);
                                        alert("Unable to upload Image, check console for error");
                                    }
                                } catch (error) {
                                    console.log(error);
                                    alert("Unable to upload Image, check console for error");
                                }
                                setLoading(false);
                            }}
                            type="file"
                        />
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ImageInputWithType;
