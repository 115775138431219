import * as React from 'react';
import {

    List,
    Datagrid,
    TextField, NumberField, ReferenceField, TopToolbar, CreateButton, ExportButton, useRecordContext, BooleanField,
} from 'react-admin';
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {LoadingSection} from "../../common/LoadingSection";
import {NoDataSection} from "../../common/NoDataSection";
import {Ecom_Attributes, Ecom_Product_Templates} from "../../custom";
import Button from "@mui/material/Button";
import {Edit} from "@mui/icons-material";
import AttributeValuesLinkField from "./AttributeValuesLink";

const ListActions = ({categoryId, productTemplateId}: { productTemplateId?: string, categoryId?: string }) => {
    const navigate = useNavigate();
    return <TopToolbar>
        <CreateButton onClick={(e) => {
            e.preventDefault();
            if (!productTemplateId) {
                navigate(`/ecom_categories/${categoryId}/ecom_attributes/create`)
            } else {
                navigate(`/ecom_categories/${categoryId}/product_templates/${productTemplateId}/ecom_attributes/create`)
            }
        }}/>
        <ExportButton/>
    </TopToolbar>
};

const EditButton = () => {
    const navigate = useNavigate();
    const record: Ecom_Attributes = useRecordContext<Ecom_Attributes>();
    return <Button startIcon={<Edit/>} onClick={(e) => {
        if (!record.product_template_id) {
            navigate(`/ecom_categories/${record.category_id}/ecom_attributes/${record.id}/edit`)
        } else {
            // navigate(`/ecom_categories/${record.category_id}/product_templates/${record.product_template_id}/ecom_attributes/create`)
        }
    }}>
        Edit
    </Button>
};

const AttributeList = () => {
    const {categoryId, productTemplateId} = useParams();
    const navigate = useNavigate();
    if (!categoryId && !productTemplateId) {
        return <LoadingSection/>
    }
    return (
        <List
            empty={<NoDataSection resource={'ecom_attributes'} onCreate={() => {
                if (!productTemplateId) {
                    navigate(`/ecom_categories/${categoryId}/ecom_attributes/create`)
                } else {
                    navigate(`/ecom_categories/${categoryId}/product_templates/${productTemplateId}/ecom_attributes/create`)
                }
            }}/>}
            actions={
                <ListActions categoryId={categoryId} productTemplateId={productTemplateId}/>
            } filter={
            !productTemplateId ? {
                category_id: categoryId
            } : {
                product_template_id: productTemplateId
            }}
            sort={{field: 'created_at', order: 'DESC'}} resource={'ecom_attributes'}>
            <Datagrid>
                <TextField source="name"/>
                <TextField source="slug"/>
                <BooleanField source={'is_filterable'}/>
                <NumberField source={'display_order'}/>
                <ReferenceField reference={'ecom_categories'} source={'category_id'}>
                    <TextField source={'name'}/>
                </ReferenceField>
                {
                    productTemplateId &&
                    <ReferenceField reference={'ecom_product_templates'} source={'product_template_id'}>
                        <TextField source={'name'}/>
                    </ReferenceField>
                }
                <FormattedDateTimeField source="created_at"/>
                
                <AttributeValuesLinkField/>
                <EditButton/>
            </Datagrid>
        </List>
    );
};
export default AttributeList;
