import * as React from 'react';
import {
    Edit,
    SimpleForm,
    useTranslate,
    useRecordContext, required, SelectInput, TextInput, minValue, ArrayInput, SimpleFormIterator, DateInput,
} from 'react-admin';

import {EditToolbar} from "../../common/EditToolbar";
import {SEGMENTATION_MODULE,} from "../../common/constants";
import {operatorChoices} from "./SegmentCreate";

const SegmentEdit = () => (
    <Edit title={<BannerTitle/>}>
        <SimpleForm toolbar={<EditToolbar/>}>
            <TextInput source="name" fullWidth validate={[minValue(0)]}/>

            <SelectInput
                source="module"
                label={'Module'}
                fullWidth
                defaultValue={'BATCH'}
                validate={[required()]}
                choices={SEGMENTATION_MODULE}
            />
            <ArrayInput source="condition">
                <SimpleFormIterator>
                    <TextInput source="type" label="Type"/>
                    <TextInput source="method" label="Method"/>
                    <TextInput source="value" label="Value"/>
                    <DateInput source="initialDate" label="Initial Date"/>
                    <DateInput source="finalDate" label="Final Date"/>
                    <SelectInput source="operator" label="Operator" choices={operatorChoices}/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

const BannerTitle = () => {
    const record = useRecordContext<any>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('Segment', {smart_count: 1})} &quot;
            {record.module}&quot;
        </span>
    ) : null;
};

export default SegmentEdit;
