import {Typography} from "@mui/material";
import {Add, Outbox} from "@mui/icons-material";
import Button from "@mui/material/Button";

export const NoDataSection = ({
                                  onCreate,
                                  resource,
                                  actions
                              }: { actions?: any, resource: string, onCreate?: () => void }) => {
    return <div
        style={{
            color: 'rgba(0,0,0,0.5)',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '100px 20px',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
        <Outbox style={{fontSize: '150px'}}/>
        <Typography variant={'h3'}>
            No {resource?.replace(/_/g, ' ') || 'data'} yet!
        </Typography>
        {
            actions ? actions : (onCreate && <Button onClick={() => onCreate()} startIcon={<Add/>}>
                Create
            </Button>)
        }
    </div>
}