import FAQList from './FAQList';
import FAQEdit from './FAQEdit';
import {Image} from "@mui/icons-material";
import FAQCreate from "./FAQCreate";

export default {
    list: FAQList,
    edit: FAQEdit,
    create: FAQCreate,
    icon: Image,
};
