import * as React from 'react';
import {Link, FieldProps, useRecordContext, ReferenceManyCount} from 'react-admin';
import {Programs,} from "../../custom";

const SubjectsLinkField = (props: FieldProps<Programs>) => {
    const record = useRecordContext<Programs>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/programs/${record.id}/subjects`}>
            Subjects
            (
            <ReferenceManyCount
                label="Subjects"
                reference="subjects"
                target="program_id"
            />
            )
        </Link>
    );
};

SubjectsLinkField.defaultProps = {
    label: 'Subjects'
};

export default SubjectsLinkField;
