import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    RichTextField,
    ReferenceField,
    EditButton,
    Labeled,
    Pagination,
    Datagrid,
    ReferenceManyField,
    useRecordContext,
    Create,
    SimpleForm,
    required, useRefresh, useCreate, useUpdate, useNotify, TextInput, Edit, NullableBooleanInput, SelectInput
} from 'react-admin';
import FullNameField from "../../common/FullNameField";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import * as React from "react";
import {SoftDeleteWithDialogAction} from "../../common/SoftDeleteWithDialogAction";
import {Divider} from "@mui/material";
import {RESOLUTION_STATUS} from "../../common/constants";
import {JsonToTagsView} from "./IssuesList";

const Comments = () => {
    const record = useRecordContext();
    const refresh = useRefresh();
    const [create, {isLoading}] = useCreate();
    const [update, {data, isLoading: updating, error}] = useUpdate();
    const notify = useNotify();

    const postSave = (data: any, event: any) => {
        if (isLoading || updating) {
            return;
        }
        data.comment = data.comment?.trim();
        const d: any = {
            ...data,
            issue_id: record.id
        }


        if (!d.comment) {
            notify(
                'Comment needed',
                {
                    type: 'error',
                    messageArgs: {},
                }
            );
            return;
        }
        create('cs_issue_comments', {data: d}, {
            onSuccess: (e) => {
                update('cs_issues', {
                    id: record.id,
                    data: {resolution_status: data.resolution_status,},
                    previousData: record
                }, {
                    onSuccess: () => {
                        notify(
                            'Updated Successfully',
                            {
                                type: 'success'
                            }
                        );
                        refresh();
                    },
                    onError: () => {
                        notify(
                            'Unable to update',
                            {
                                type: 'error'
                            }
                        );
                    }
                })
            }
        });
    }
    return <div className={'w-full'}>
        <ReferenceManyField
            label={'Comments'}
            reference="cs_issue_comments"
            target="issue_id"
            sort={{field: 'created_at', order: 'ASC'}}
            filter={{
                issue_id: record.id,
            }}
            fullWidth
            pagination={<Pagination/>}
        >
            <Datagrid>
                <TextField source={'comment'}/>
                <TextField source={'resolution_status'}/>
                <SoftDeleteWithDialogAction/>
            </Datagrid>
        </ReferenceManyField>
        <Create redirect={undefined}>
            <SimpleForm defaultValues={{
                resolution_status: record.resolution_status
            }} id={'comment-form'} onSubmit={postSave}>
                <TextInput source={'comment'} fullWidth multiline/>
                <SelectInput
                    source="resolution_status"
                    label={'Resolution Status'}
                    fullWidth
                    choices={RESOLUTION_STATUS}
                />
            </SimpleForm>
        </Create>
    </div>
}
export const IssuesShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="issue_description"/>
            <JsonToTagsView source={'device_meta'}/>
            <ReferenceField reference={'cs_issue_topics'} source={'issue_topic_id'}>
                <TextField fullWidth source="name"/>
            </ReferenceField>
            <ReferenceField label={'Student'} source={'created_by_id'} reference={'users'}>
                <FullNameField/>
            </ReferenceField>
            <TextField source="resolution_status"/>
            <FormattedDateTimeField source="created_at"/>
            <Divider/>
            <Labeled fullWidth label={'Comments'}>
                <Comments/>
            </Labeled>
        </SimpleShowLayout>
    </Show>
);
