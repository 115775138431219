import {

    List,
    Datagrid,
    TextField, NumberField, ReferenceField, TopToolbar, CreateButton, ExportButton, useRecordContext, BooleanField,
} from 'react-admin';
import FormattedDateTimeField from "../../../common/FormattedDateTimeField";
import { NoDataSection } from "../../../common/NoDataSection";
import FullNameField from '../../../common/FullNameField';
import { format } from 'date-fns';
import { downloadCSV, jsonToCSV } from '../../../common/CSVExport';
import MonthSelector from '../../../common/MonthSelector';
import { useState } from 'react';

const myCustomExporter = (records: any) => {
    const data = records.map((record: any) => {
        return {
            ...record,
            joined_at: format(new Date(record.joined_at), 'dd-MMM-yyyy hh:mm a'),
            expired_at: format(new Date(record.expired_at), 'dd-MMM-yyyy hh:mm a'),
        };
    });
    const csv = jsonToCSV(data); // Convert the modified data to CSV format
    downloadCSV(csv, 'Batch Students Report');  // Download the CSV
};


const ReportBatchStudentList = () => {
    const [selectedMonthRange, setSelectedMonthRange] = useState<{ firstDay: any, lastDay: any } | null>(null);
    const handleDateChange = ({ firstDay, lastDay }: { firstDay: any, lastDay: any }) => {
        setSelectedMonthRange({ firstDay, lastDay });
    };
    const ListActions = () => (
        <TopToolbar className="">
            <MonthSelector monthsToShow={12} onDateChange={handleDateChange} />
            <ExportButton />
        </TopToolbar>
    );

    return (

        <List
            actions={<ListActions />}
            filter={{
                joined_at: {
                    format: 'hasura-raw-query',
                    value: {
                        _gt: selectedMonthRange?.firstDay,
                        _lt: selectedMonthRange?.lastDay
                    }
                },
            }}
            exporter={myCustomExporter}
            resource={'report_batch_students'}
            empty={<NoDataSection resource={'report_batch_students'} />}
        >
            <Datagrid>
                <ReferenceField reference={'report_batch_students'} source={'id'}>
                    <FullNameField />
                </ReferenceField>
                <TextField source="batch" />
                <TextField source="price" />
                <TextField source="discount" />
                <TextField source="email" />
                <TextField source="payment_source" />
                <FormattedDateTimeField source="joined_at" />
                <FormattedDateTimeField source="expired_at" />
                <TextField source="phone" />
            </Datagrid>
        </List>
    );
};
export default ReportBatchStudentList;
