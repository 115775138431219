import * as React from 'react';
import {
    Create,
    required,
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    BooleanInput,
    SimpleFormIteratorContext,
    SimpleForm,
    SelectInput,
    useCreateContext, useCreate, useNotify, ReferenceInput, AutocompleteInput
} from 'react-admin';
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import ImageInput from "../../common/inputs/ImageInput";

import { QUESTION_TYPES, VIDEO_TYPES } from "../../common/constants";
import { useNavigate } from "react-router-dom";
import HTMLEditorInput, { validHtml } from '../../common/inputs/HTMLEditorInput';
import { filterToQueryVideo, videoNameRender } from '../batch_schedules/BatchScheduleEdit';
import PDFInput from '../../common/inputs/PDFInput';
import ImageInputWithType from '../../common/inputs/ImageInputWithType';

const Options = ({testId}:any) => {
    const { record } = useCreateContext();
    if (record?.type === 'NUMERIC') {
        return <TextInput fullWidth source={'solution_text'} type={'number'} />
    } else if (record?.type) {
        return <ArrayInput source="options" sx={{
            border: '1px solid #aaaaaa',
            padding: '10px',
            borderRadius: '10px',
            marginBottom: '20px'
        }}>
            <SimpleFormIterator>
            <ImageInputWithType
                source="image"
                type="test"
                typeId={testId}
                // label="Upload Image"
                // multiple
            />
                {/* <ImageInput fullWidth source={`image`} /> */}
                <TextInput fullWidth source={`display_order`} defaultValue={0} />

                <HTMLEditorInput label={'Content'} fullWidth source={`content`} validate={[validHtml]} />
                <BooleanInput defaultValue={false} fullWidth label={'Is Correct'}
                    source={`is_correct`} />
            </SimpleFormIterator>
        </ArrayInput>

    }

    return <>

    </>
}
const QuestionCreate = () => {
    let { programId, testSeriesId, testId, sectionId } = useParams();
    const [create, { isLoading }] = useCreate();
    const navigate = useNavigate();
    const notify = useNotify();

    const   postSave = (data: any) => {
        try {
            if (isLoading) {
                return;
            }
            const d: any = {
                ...data
            }
            if (d.options) {
                d.options = {
                    data: d.options
                }
            }

            if (d.type === 'NUMERIC') {
                delete d.options;
            }
            if (d.type === 'SINGLE') {
                const correctOptions = d.options?.data?.filter((option: any) => !!option.is_correct);
                if (correctOptions?.length !== 1) {

                    notify(
                        'Exactly one correct answer should be selected',
                        {
                            type: 'error'
                        }
                    );
                    return;
                }
            }
            if (d.type === 'MULTIPLE') {
                const correctOptions = d.options?.data?.filter((option: any) => !!option.is_correct);
                if (!correctOptions?.length) {
                    notify(
                        'At least one correct answer should be selected',
                        {
                            type: 'error'
                        }
                    );
                    return;
                }
            }
            create('questions', { data: d }, {
                onSuccess: (e) => {
                    navigate(`/programs/${programId}/test_serieses/${testSeriesId}/tests/${testId}/sections/${sectionId}/questions`)
                }
            });
        } catch (e) {
            return false;
        }
    };
    if (!sectionId) {
        return <LoadingSection />
    }
    return <Create resource={'questions'}>
        <SimpleForm onSubmit={postSave}
            defaultValues={{ section_id: sectionId, test_id: testId, test_series_id: testSeriesId }}>
                <ImageInputWithType
                source="image"
                type="test"
                typeId={testId? testId: ""}
                // label="Upload Image"
                // multiple
            />
            {/* <ImageInput source={"image"} /> */}
            <HTMLEditorInput fullWidth source={'content'} />
            <SelectInput
                source="type"
                label={'Type'}
                fullWidth
                disabled
                validate={[required()]}
                choices={QUESTION_TYPES}
            />

            <Options testId={testId}/>
            <TextInput source={'positive_marks'} fullWidth />
            <TextInput source={'negative_marks'} fullWidth />
            <HTMLEditorInput fullWidth source={'solution_description'} />
             <ReferenceInput name={'Video'} source={'video_id'} reference={'videos'}>
               <AutocompleteInput optionText={videoNameRender} filterToQuery={filterToQueryVideo}
                                      limitChoicesToValue={true} debounce={500} fullWidth />
            </ReferenceInput>
            <ImageInputWithType
                source="solution_image"
                type="test"
                typeId={testId? testId: ""}
                // label="Upload Image"
                // multiple
            />
            {/* <ImageInput source={"solution_image"} label={"solution image"}/> */}
        </SimpleForm>
    </Create>
};

export default QuestionCreate;
