import * as React from 'react';
import {

    List,
    EditButton,
    Datagrid,
    TextField,
} from 'react-admin';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';
import {DeleteWithDialogAction} from "../../common/DeleteWithDialogAction";


const SegmentList = () => {
    return (
        <div>
            <List>
                <Datagrid>
                    <TextField source="name"/>
                    <TextField source="module"/>
                    <TextField source="condition"/>
                    <FormattedDateTimeField source="created_at"/>
                    <EditButton/>
                    <DeleteWithDialogAction/>
                </Datagrid>
            </List>
        </div>
    );
};
export default SegmentList;
