import * as React from 'react';
import {

    List,
    EditButton,
    Datagrid,
    TextField, useGetIdentity, ReferenceField,
} from 'react-admin';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';
import usePermissionMiddleware from "../../common/usePermissionMiddleware";
import FullNameField from "../../common/FullNameField";


const OrganizationList = () => {
    const {hasPermission} = usePermissionMiddleware();
    return (
        <List hasCreate={hasPermission('insert_organizations_one')}>
            <Datagrid rowClick={'edit'}>
                <TextField source="name"/>
                <TextField source="status"/>
                <TextField source="min_ios_version"/>
                <TextField source="min_android_version"/>
                <ReferenceField reference={'users'} source={'primary_user_id'}>
                    <FullNameField source="first_name"/>
                </ReferenceField>
                <FormattedDateTimeField source="created_at"/>

                <EditButton/>
            </Datagrid>
        </List>
    );
};
export default OrganizationList;
