import  React, {useState} from 'react';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {ListItem} from "@mui/material";
import {MenuItemLink, useTranslate} from "react-admin";
import Tooltip from "@mui/material/Tooltip";

const MultiLevelMenu = ({ option }: {option: Record<string, any>}) => {
    const translate = useTranslate();
    const { children } = option;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    return (
        <React.Fragment>
            <Tooltip title={translate(option.name)} placement="right">
                <ListItem button onClick={handleClick}>
                    <ListItemIcon>{option.icon}</ListItemIcon>
                    <ListItemText primary={translate(option.name)} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            </Tooltip>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {children && children.length > 0 && children.map((option: Record<string, any>) => (
                        <MenuItemLink
                            key={option.name}
                            to={option.to}
                            state={{_scrollToTop: true}}
                            primaryText={translate(option.name, {
                                smart_count: 2,
                            })}
                            leftIcon={option.icon}
                        />
                    ))}
                </List>
            </Collapse>
        </React.Fragment>
    );
};

export default MultiLevelMenu;
