import {Batch_Schedules} from "./custom";

export const isScheduleLive = (schedule: Batch_Schedules)=>{
  return (schedule && schedule.start_time && new Date(schedule.start_time) < new Date() && new Date(schedule.end_time) > new Date())
}
export const isScheduleUpcoming = (schedule: Batch_Schedules)=>{
  return (schedule.start_time && new Date(schedule.start_time) > new Date())
}
export const getScheduleChatChannel = (schedule: Batch_Schedules)=>{
  return `schedule_${schedule.id}`
}

export const getScheduleChatUrl = (schedule: Batch_Schedules)=>{
  return `lcbfc706.ala.us-east-1.emqxsl.com:8084/mqtt`
}
