import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    Filter,
} from 'react-admin';

// Custom Filter component with TextInputs for phone and email search
const QueriesFilter = (props:any) => (
    <Filter {...props}>
        <TextInput label="Search by Phone" source="phone" alwaysOn />
        <TextInput label="Search by Email" source="email" alwaysOn />
    </Filter>
);

const QueriesList = (props:any) => {
    return (
        <div>
            <List sort={{ field: 'name', order: 'DESC' }} filters={<QueriesFilter />} {...props}>
                <Datagrid>
                    <TextField source="name" />
                    <TextField source="email" />
                    <TextField source="phone" />
                    <TextField source="code" />
                    <TextField source="message" />
                </Datagrid>
            </List>
        </div>
    );
};

export default QueriesList;
