import * as React from "react";
import {
  Create,
  TextInput,
  required,
  SimpleForm,
  DateInput,
  SelectInput,
  regex,
} from "react-admin";
import { COUNTRY_CODES, USER_STATUSES } from "../../common/constants";
import ImageInput from "../../common/inputs/ImageInput";
import { useSearchParams } from "react-router-dom";
import { LoadingSection } from "../../common/LoadingSection";

const UserCreate = () => {
  const [searchParams] = useSearchParams();
  if (!searchParams.get("role")) {
    return <LoadingSection />;
  }
  const validateNameNoNumbers = regex(
    /^[A-Za-z\s]+$/,
    "Name cannot contain numbers."
  );

  const capitalizeFirstLetter = (value: string) => {
    if (!value) return value;
    return value.replace(/\b\w/g, (char:any) => char.toUpperCase());
  };
  return (
    <Create>
      <SimpleForm defaultValues={{ role: searchParams.get("role") }}>
        <ImageInput source="profile_image" fullWidth />
        <TextInput
          source="first_name"
          validate={[required(), validateNameNoNumbers]}
          format={capitalizeFirstLetter}
          fullWidth
        />
        <TextInput source="last_name"  validate={[required(), validateNameNoNumbers]}
          format={capitalizeFirstLetter}
          fullWidth />
        <SelectInput source="country_code" choices={COUNTRY_CODES} fullWidth />
        <TextInput source="phone" validate={[required()]} fullWidth />
        <TextInput source="registration_number" fullWidth />
        <TextInput source="email" fullWidth />
        {/* {
                searchParams.get('role') == "STUDENT" ?
                    <TextInput source="admission_number" fullWidth />
                    : ""
            } */}
        <TextInput source="admission_number" fullWidth />
        <TextInput disabled source="role" fullWidth />
        <SelectInput source="status" choices={USER_STATUSES} fullWidth />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
