import * as React from 'react';
import {Link, FieldProps, useRecordContext, ReferenceManyCount} from 'react-admin';
import {Subjects,} from "../../custom";

const ChaptersLinkField = (props: FieldProps<Subjects>) => {
    const record = useRecordContext<Subjects>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/programs/${record.program_id}/subjects/${record.id}/chapters`}>
            Chapters
            (
            <ReferenceManyCount
                label="Chapters"
                reference="chapters"
                target="subject_id"
            />
            )
        </Link>
    );
};

ChaptersLinkField.defaultProps = {
    label: 'Chapters'

};

export default ChaptersLinkField;
