import * as React from 'react';
import {Create, TextInput, required, SimpleForm, ReferenceInput, SelectInput, AutocompleteInput, minValue,} from 'react-admin';
import {ORGANIZATION_RESOURCE_TYPES, VIDEO_TYPES} from "../../common/constants";
import {filterToQueryVideo, videoNameRender} from "../batch_schedules/BatchScheduleEdit";

const OrganizationResourcesCreate = () => (
    <Create redirect={'/organization_resources'}>
        <SimpleForm defaultValues={{url_type: 'YOUTUBE'}}>
            <TextInput source="title" fullWidth validate={[required()]}/>
            <SelectInput
                source="type"
                label={'Resource Type'}
                fullWidth
                validate={[required()]}
                choices={ORGANIZATION_RESOURCE_TYPES}
            />
            <ReferenceInput name={'Video'} source={'video_id'} reference={'videos'}>
                <AutocompleteInput optionText={videoNameRender} filterToQuery={filterToQueryVideo}
                                   limitChoicesToValue={true} debounce={500} fullWidth validate={[required()]}/>
            </ReferenceInput>
            <TextInput source="display_order" type={'number'} fullWidth validate={[required(),minValue(0)]}/>
        </SimpleForm>
    </Create>
);

export default OrganizationResourcesCreate;
