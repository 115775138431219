import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required, ReferenceInput, SelectInput, DateTimeInput, BooleanInput, minValue,
} from 'react-admin';
import {Subjects} from "../../custom";
import ImageInput from "../../common/inputs/ImageInput";
import {EditToolbar} from "../../common/EditToolbar";

import {useParams} from "react-router";
import {LoadingSection} from "../../common/LoadingSection";
import {STATUSES, TEST_SERIES_TYPES} from "../../common/constants";
import HTMLEditorInput, {validHtml} from '../../common/inputs/HTMLEditorInput';


const TestSeriesEdit = () => {
    let {programId, testSeriesId} = useParams();
    if (!testSeriesId) {
        return <LoadingSection/>
    }
    return <Edit title={<TestSeriesTitle/>} redirect={() => {
        return `programs/${programId}/test_serieses`
    }} resource={'test_serieses'} id={testSeriesId}>
        <SimpleForm toolbar={<EditToolbar/>}>
            <ImageInput source="image" fullWidth validate={[required()]}/>
            <TextInput source="title" fullWidth validate={[required()]}/>
            <TextInput source="slug" fullWidth validate={[required()]}/>
            <BooleanInput source={'is_demo'} defaultValue={false}/>
            <TextInput type={'number'} source="display_order" fullWidth validate={[required(),minValue(0)]}/>
            <ReferenceInput label="Program" source="program_id" reference="programs">
                <SelectInput optionText="name" disabled fullWidth validate={[required()]}/>
            </ReferenceInput>
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUSES}
            />
            <SelectInput
                source="type"
                label={'Type'}
                fullWidth
                defaultValue={'REGULAR'}
                validate={[required()]}
                choices={TEST_SERIES_TYPES}
            />
            <TextInput source="text_description" fullWidth validate={[required()]}/>
            <HTMLEditorInput source={'html_description'} fullWidth validate={[required(), validHtml]}/>
        </SimpleForm>
    </Edit>
};

const TestSeriesTitle = () => {
    const record = useRecordContext<Subjects>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.test_serieses.name', {smart_count: 1})} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default TestSeriesEdit;
