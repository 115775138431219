import * as React from 'react';
import {

    List,
    Datagrid,
    TextField, ReferenceField, useRecordContext,
} from 'react-admin';
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import FullNameField from "../../common/FullNameField";
import {SoftDeleteWithDialogAction} from "../../common/SoftDeleteWithDialogAction";

function jsonToStringArray(json: any) {
    const data = json;
    return Object.keys(data).map((key) => {
        return `${key}: ${data[key]}`;
    });
}

export function JsonToTagsView({source}: { source: string }) {
    const record = useRecordContext();
    const data = record[source];
    if (!data) {
        return <></>
    }
    return <div className={'flex flex-wrap gap-1'}>
        {
            jsonToStringArray(data).map((item, index) => {
                return <span key={index} className={'tag px-1 py-0.5 text-xs rounded bg-gray-500 text-white capitalize'}>{item}</span>
            })
        }
    </div>
}

const DeletedUsersList = () => {
    return (
        <List sort={{field: 'updated_at', order: 'DESC'}}>
            <Datagrid>
                <TextField source="id"/>
                <FullNameField/>
                <TextField source="role"/>
                <FormattedDateTimeField source="updated_at" label={'Deleted At'}/>
                <ReferenceField reference={'users'} source={'updated_by_id'} label={'Deleted By'}>
                    <FullNameField/>
                </ReferenceField>
            </Datagrid>
        </List>
    );
};
export default DeletedUsersList;
