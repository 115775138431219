import * as React from 'react';
import {Link, FieldProps, useRecordContext, ReferenceManyCount} from 'react-admin';
import {Chapters, Subjects,} from "../../custom";

const TopicsLinkField = (props: FieldProps<Chapters>) => {
    const record = useRecordContext<Chapters>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/programs/${record.program_id}/subjects/${record.subject_id}/chapters/${record.id}/topics`}>
            Topics
            (
            <ReferenceManyCount
                label="Topics"
                reference="topics"
                target="chapter_id"
            />
            )
        </Link>
    );
};

TopicsLinkField.defaultProps = {};

export default TopicsLinkField;
