import * as React from 'react';
import { useRecordContext } from 'react-admin';

const DimensionsField = () => {
    const record = useRecordContext();
    if (!record) return null;

    const { height_in_mtr, width_in_mtr, length_in_mtr, weight_in_grams } = record;

    return (
        <div className={'text-xs'}>
        <div className={'whitespace-nowrap'}>
            Height: <b>{height_in_mtr}m</b>
        </div>
        <div className={'whitespace-nowrap'}>
        Width: <b>{width_in_mtr}m</b>
        </div>
        <div className={'whitespace-nowrap'}>
        Length: <b>{length_in_mtr}m</b>
        </div>
        <div className={'whitespace-nowrap'}>
        Weight: <b>{weight_in_grams}g</b>
        </div>
        </div>
    );
};

DimensionsField.defaultProps = {
    label: 'Dimensions'
}
export default DimensionsField;
