import * as React from 'react';
import {

    List,
    EditButton,
    Datagrid,
    TextField, ReferenceField, NumberField,
} from 'react-admin';
import ThumbnailField from "../../common/ThumbnailField";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import FullNameField from "../../common/FullNameField";


const BlogTopicsList = () => {
    return (
        <List sort={{field: 'display_order', order: 'ASC'}}>
            <Datagrid>
                <ThumbnailField source="image"/>
                <TextField source="name"/>
                <NumberField source="display_order"/>
                <ReferenceField reference={'users'} label={'Author'} source={'created_by_id'}>
                    <FullNameField source={'first_name'}/>
                </ReferenceField>
                <FormattedDateTimeField source="created_at"/>
                
                <EditButton/>
            </Datagrid>
        </List>
    );
};
export default BlogTopicsList;
