import * as React from 'react';
import {Layout, LayoutProps} from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import AppBreadcrumbs from "./AppBreadcrumbs";


export default (props: LayoutProps) => {
    return <Layout
     {...props}
      appBar={AppBar} 
      menu={Menu}
      >
        {/*<AppBreadcrumbs/>*/}
        {
            props.children
        }
    </Layout>
};
