import * as React from 'react';
import {Create, TextInput, NullableBooleanInput, SimpleForm, SelectInput, ReferenceInput, required,} from 'react-admin';
import ImageInput from "../../common/inputs/ImageInput";


const PodcastTopicCreate = () => {
    return <Create redirect={'/podcast_topics'}>
        <SimpleForm>
            <TextInput source="name" fullWidth name={'name'}/>
            <ImageInput source={'thumbnail'} validate={[required()]}/>
            <TextInput source="display_order" type={'number'} fullWidth/>
        </SimpleForm>
    </Create>
};

export default PodcastTopicCreate;
