export function timeAgo(date: Date) {
  if (!(date instanceof Date)) {
    console.error("Invalid date");
    return;
  }

  const now = new Date();
  const diffInSeconds = Math.max(Math.floor((now.getTime() - date.getTime()) / 1000), 1);


  if (Number.isNaN(diffInSeconds) || !Number.isFinite(diffInSeconds)) {
    console.error("Invalid difference in seconds:", diffInSeconds);
    return;
  }

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'week', seconds: 604800 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];

  for (let i = 0; i < intervals.length; i++) {
    const currentInterval = intervals[i];
    const count = Math.floor(diffInSeconds / currentInterval.seconds);
    if (count !== 0) {
      return `${count} ${currentInterval.label}${count !== 1 ? 's' : ''} ago`;
    }
  }
}






