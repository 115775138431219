import * as React from 'react';
import {
    Datagrid, List, ReferenceField, TextField,
    useDataProvider, useRecordContext, useRefresh,
} from 'react-admin';
import mqtt from "precompiled-mqtt";

import {Batch_Schedules, Student_Video_Stats} from "../../custom";
import {isScheduleLive} from "../../class-utilities";
import {useMutation, useQuery} from "react-query";
import {useParams} from "react-router";
import {Skeleton, Tab, Tabs} from "@mui/material";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import FullNameField from "../../common/FullNameField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useCallback, useEffect, useState} from "react";
import {SoftDeleteWithDialogAction} from "../../common/SoftDeleteWithDialogAction";
import {SendRounded} from "@mui/icons-material";
import {timeAgo} from "../../date.utilitites";

const BlockedStatusField = ({source}: { source: keyof Student_Video_Stats }) => {
    const record = useRecordContext<Student_Video_Stats>();
    const [open, setOpen] = React.useState(false);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const {mutate, isLoading} = useMutation(
        () => dataProvider.update('student_video_stats', {
                id: record.id,
                data: {[source]: !record[source]},
                previousData: {[source]: record[source]},

            },
        ),
        {
            onSuccess: () => {
                refresh();
                setOpen(false);
            }
        }
    );
    return <div className={'cursor-pointer'}>
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {record[source] ? `Unblock ${source?.split('_')?.[0]}` : `Block ${source?.split('_')?.[0]}`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Do you really want to {record[source] ? `Unblock` : `Block`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={() => {
                    setOpen(false);
                }}>Cancel</Button>
                <Button disabled={isLoading} onClick={() => {
                    mutate();
                }} autoFocus>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
        {
            record[source] ? <div onClick={() => {
                setOpen(true);
            }} className={'bg-red-500 text-white rounded-md px-1.5 py-0.5  text-center'}>
                Blocked
            </div> : <div onClick={() => {
                setOpen(true);
            }} className={'bg-green-700 text-white rounded-md px-1.5  py-0.5 text-center'}>
                Active
            </div>
        }
    </div>
}
const StudentJoined = ({scheduleId}: { scheduleId: string }) => {
    const [searchQuery, setSearchQuery] = useState('');

    return <div className={'flex-1 p-2 overflow-y-auto'}>
        <input className={'border rounded w-full mb-2 p-2'} value={searchQuery}
               onChange={(e) => setSearchQuery(e.target.value)} placeholder={'Search Student'}/>
        {/*@ts-ignore*/}
        <List actions={null} sort={{field: 'created_at', order: 'DESC'}} resource={'student_video_stats'}
              filter={{
                  batch_schedule_id: scheduleId, ...(searchQuery ? {
                      student: {
                          format: 'hasura-raw-query',
                          value: {
                              _or: [
                                  {phone: {_eq: searchQuery}},
                                  {email: {_eq: searchQuery}},
                                  {first_name: {_ilike: `%${searchQuery}%`}},
                                  {last_name: {_ilike: `%${searchQuery}%`}},
                              ]
                          },
                      }
                  } : {})
              }}>
            <Datagrid>
                <ReferenceField label={'Student'} source={'student_id'} reference={'users'}>
                    <FullNameField/>
                </ReferenceField>
                <BlockedStatusField source={'chat_blocked'}/>
                {/*<BlockedStatusField source={'comments_blocked'}/>*/}
                <BlockedStatusField source={'access_blocked'}/>
                <FormattedDateTimeField source="created_at"/>
            </Datagrid>
        </List>
    </div>
}
const AllChats = ({scheduleId}: { scheduleId: string }) => {
    const [searchQuery, setSearchQuery] = useState('');

    return <div className={'flex-1 p-2 overflow-y-auto'}>
        <input className={'border rounded w-full mb-2 p-2'} value={searchQuery}
               onChange={(e) => setSearchQuery(e.target.value)} placeholder={'Search Student'}/>
        {/*@ts-ignore*/}
        <List actions={null} sort={{field: 'created_at', order: 'DESC'}} resource={'schedule_chats'}
              filter={{
                  schedule_id: scheduleId, ...(searchQuery ? {
                      user: {
                          format: 'hasura-raw-query',
                          value: {
                              _or: [
                                  {phone: {_eq: searchQuery}},
                                  {email: {_eq: searchQuery}},
                                  {first_name: {_ilike: `%${searchQuery}%`}},
                                  {last_name: {_ilike: `%${searchQuery}%`}},
                              ]
                          },
                      }
                  } : {})
              }}>
            <Datagrid>
                <TextField source={'message'}/>
                <TextField source={'user_name'}/>
                <FormattedDateTimeField source="created_at"/>
                <SoftDeleteWithDialogAction/>
            </Datagrid>
        </List>
    </div>
}
const BatchSchedulePreview = () => {
    const dataProvider = useDataProvider();
    const params = useParams();
    const [value, setValue] = React.useState(0);
    const [mqttCreds, setMqttCreds] = useState(undefined as any);
    const [client, setClient] = useState(undefined as any);
    const [connectStatus, setConnectStatus] = useState('Connect' as any)
    const [message, setMessage] = useState('');
    const [liveChats, setLiveChats] = useState([] as any[]);
    const {data: scheduleData, refetch, error} = useQuery(
        ['batch_schedules', 'getList', {}],
        () => dataProvider.getList('batch_schedules', {
            filter: {id: params['scheduleId']},
            pagination: {page: 1, perPage: 1},
            sort: {field: 'created_at', order: 'ASC'}
        })
    );
    const mqttDisconnect = useCallback(() => {
        if (client) {
            try {
                client.end(false, () => {
                    setConnectStatus('DisConnect')
                    console.log('disconnected successfully')
                })
            } catch (error) {
                console.log('disconnect error:', error)
            }
        }
    },[client])

    const mqttPublish = (context: { topic: any, qos: any, payload: any }) => {
        if (client) {
            // topic, QoS & payload for publishing message
            const {topic, qos, payload} = context
            client.publish(topic, payload, {qos}, (error: any) => {
                if (error) {
                    console.log('Publish error: ', error)
                }
            })
        }
    }

    const mqttSub = useCallback((subscription: { topic: any, qos: any }) => {
        if (client) {
            // topic & QoS for MQTT subscribing
            const {topic, qos} = subscription
            client.subscribe(topic, {qos}, (error: any) => {
                if (error) {
                    console.log('Subscribe to topics error', error)
                    return
                }
                console.log(`Subscribe to topics: ${topic}`)
            })
        }
    }, [client]);

    const record = scheduleData?.data?.[0];
    const isLive = isScheduleLive(record as Batch_Schedules);

    useEffect(() => {
        if (scheduleData?.data?.[0] && isScheduleLive(scheduleData?.data?.[0] as Batch_Schedules)) {
            const creds = {
                clientId: "emqx_react_" + Math.random().toString(16).substring(2, 8),
                username: 'demon',
                password: '111111'
            };
            setMqttCreds(creds)
            setClient(mqtt.connect("wss://lcbfc706.ala.us-east-1.emqxsl.com:8084/mqtt", {
                ...creds
            }));
            return ()=>{
                mqttDisconnect();
            }
        }

    }, [params, record]);
    useEffect(() => {
        if (client) {
            mqttSub({topic: `schedule_${scheduleData?.data?.[0].id}`, qos: 0});
        }
    }, [client]);
    useEffect(() => {
        if (client) {
            client.on('connect', () => {
                setConnectStatus('Connected')
                console.log('connection successful')
            })

            client.on('error', (err: any) => {
                console.error('Connection error: ', err)
                client.end()
            })

            client.on('reconnect', () => {
                setConnectStatus('Reconnecting')
            })

            client.on('message', (topic: any, message: any) => {
                const payload = {topic, message: message.toString()}
                console.log(`received message: ${message} from topic: ${topic}`)
                try {
                    const {data} = JSON.parse(message.toString());
                    setLiveChats((prevState) => {
                        return [data,...prevState];
                    })
                } catch (e) {
                    console.log(e)
                }
            })
        }
    }, [client])
    if (!record) {
        return <Skeleton/>
    }
    console.log(liveChats)
    return <div className={'p-2 flex flex-col gap-2 h-[calc(100vh-50px)] overflow-y-hidden'} id={'details-section'}>

        <div className={'w-full h-[calc(100vh-60px)] p-2 flex gap-2 border relative border-primary-400 rounded-md'}>
            <div className={'flex-1'}>
                <Tabs value={value}>
                    <Tab label="Students Joined" onClick={() => setValue(0)}/>
                    {<Tab label="Chats" onClick={() => setValue(1)}/>}
                </Tabs>

                {
                    value === 0 && <StudentJoined scheduleId={record.id}/>
                }
                {
                    value === 1 && <AllChats scheduleId={record.id}/>
                }
            </div>
            <div className={'w-[400px] h-full flex-col flex'}>
                <div className={' aspect-video bg-zinc-500 rounded-md'}/>
                {
                    mqttCreds && <div className={'flex-1 border rounded-md p-2 overflow-y-auto flex flex-col'}>
                        <div className={'flex-1 overflow-y-auto'}>
                            {
                                liveChats.map((chat , index)=>{
                                    return <div key={index} className={'p-1 border-b'}>
                                        <div className={'font-semibold flex justify-between'}>
                                            <div>{chat.user_name}</div>
                                            <small className={'text-gray-600 font-light'}>{timeAgo(new Date(chat.created_at))}</small>
                                        </div>
                                        <small className={''}>
                                            {chat.message}
                                        </small>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
}
export default BatchSchedulePreview;
