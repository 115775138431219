import * as React from 'react';
import {

    List,
    Datagrid,
    TextField,
    NumberField,
    ImageField, EditButton, ReferenceField, useRecordContext,
} from 'react-admin';
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import {SoftDeleteWithDialogAction} from "../../common/SoftDeleteWithDialogAction";
import ReactAudioPlayer from 'react-audio-player';
function fixComponent<T>(component: T): T {
    return (component as any).default ?? component;
}
const ReactAudioPlayerComponent = fixComponent(ReactAudioPlayer);
export const AudioPlayback = ()=>{
    const record = useRecordContext();
    return <ReactAudioPlayerComponent
        src={record.url}
        autoPlay={false}
        controls
    />
}
const PodcastList = () => {
    return (
        <List
            sort={{field: 'display_order', order: 'ASC'}}>
            <Datagrid>
                <ImageField source={'thumbnail'}/>
                <TextField source="title"/>
                <NumberField source={'display_order'}/>
                <FormattedDateTimeField source="created_at"/>
                
                <ReferenceField reference={'download_categories'} source={'category_id'} >
                    <TextField source={'name'}/>
                </ReferenceField>
                <AudioPlayback/>
                <EditButton/>
                <SoftDeleteWithDialogAction/>
            </Datagrid>
        </List>
    );
};
export default PodcastList;
