import * as React from 'react';
import {Link, FieldProps, useRecordContext, ReferenceManyCount} from 'react-admin';
import {Ecom_Product_Templates,} from "../../custom";

const ProductsLinkField = (props: FieldProps<Ecom_Product_Templates>) => {
    const record = useRecordContext<Ecom_Product_Templates>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/ecom_categories/${record.category_id}/ecom_product_templates/${record.id}/ecom_products`}>
            Products
            (
            <ReferenceManyCount
                label="Products"
                reference="ecom_products"
                target="product_template_id"
            />
            )
        </Link>
    );
};

ProductsLinkField.defaultProps = {
    label: 'Products'
};

export default ProductsLinkField;
