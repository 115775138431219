import BannerList from './BannerList';
import BannerEdit from './BannerEdit';
import {Image} from "@mui/icons-material";
import BannerCreate from "./BannerCreate";

export default {
    list: BannerList,
    edit: BannerEdit,
    create: BannerCreate,
    icon: Image,
};
