import * as React from 'react';
import {Link, FieldProps, useRecordContext, ReferenceManyCount} from 'react-admin';
import {Chapters, Subjects, Tests,} from "../../custom";

const QuestionResponsesLinkField = (props: FieldProps<Tests>) => {
    const record = useRecordContext<any>();
    if (!record) {
        return null;
    }
    return (
        <Link
            to={`/programs/${record.program_id}/test_serieses/${record.test_series_id}/tests/${record.id}/student_responses`}>
            <div style={{whiteSpace: 'nowrap'}}>
                Responses (<ReferenceManyCount
                label="Student Responses"
                reference="student_test_mapping"
                target="test_id"
            />)
            </div>
        </Link>
    );
};

QuestionResponsesLinkField.defaultProps = {
    label: 'Student Responses',
};

export default QuestionResponsesLinkField;
