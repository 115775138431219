import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    Create, useRecordContext, useTranslate, useDataProvider, Toolbar, SaveButton, FileField, FieldProps,
} from 'react-admin';
import { EditToolbar } from "../../common/EditToolbar";
import { useNavigate, useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import PDFInput from '../../common/inputs/PDFInput';
import Button from "@mui/material/Button";
import { Cancel, DocumentScanner, Download } from '@mui/icons-material';
import { Questions, Student_Test_Mapping_Results } from "../../custom";
import { useQuery } from "react-query";
import { result } from 'lodash';
import { Link } from 'react-router-dom';
import { Tooltip } from 'recharts';

const CancelButton = () => {
    const navigate = useNavigate();
    return <Button color={'secondary'} sx={{ marginLeft: '10px' }} onClick={() => {
        navigate(-1);
    }}>
        Cancel
    </Button>
}
const UserEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />
        <CancelButton />
    </Toolbar>
);

const StudentResponseEvaluate = (props: any) => {
    const navigate = useNavigate();
    let { programId, testSeriesId, testId, studentResponseId } = useParams();
    const dataProvider = useDataProvider();
    const { data: results, refetch, isLoading, error } = useQuery(
        ['results_' + studentResponseId, 'getList', { test_id: testId }],
        () => dataProvider.getList('student_test_mapping_results', {
            filter: { student_test_mapping_id: studentResponseId },
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'created_at', order: 'DESC' }
        })
    );
    const answerSheet = results?.data[0]?.answer_sheet
    const id = results?.data[0]?.id
    if (isLoading || !results?.data) {
        return <LoadingSection />
    }
    else
        return id && answerSheet ?
            <Edit
                redirect={() => { navigate(-1) }}
                {...props}
                title={<SectionTitle />}
                resource={'student_test_mapping_results'}
                id={id}>
                <a className="text-blue-500 background-transparent font-bold uppercase px-2  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    href={answerSheet} download target="blank">
                    Download Answer sheet
                </a>
                <SimpleForm toolbar={<UserEditToolbar />}>
                    <TextInput source={'marks_obtained'} fullWidth />
                    <PDFInput source="marked_answer_sheet" label={'Answer sheet'} fullWidth />
                </SimpleForm>
            </Edit> :
            <div className='flex justify-center  h-full items-center'>
                <div>
                    <h3 className='text-5xl text-red-600'>
                        Result not Uploaded!
                    </h3>
                    <h5 className='text-3xl mx-10'>
                        Please try after sometime
                    </h5>


                </div>
            </div>
};

const SectionTitle = () => {
    const record = useRecordContext<Student_Test_Mapping_Results>();
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('Update Response', { smart_count: 1 })} &quot;
            {record.id}&quot;
        </span>
    ) : null;
};



export default StudentResponseEvaluate;
