import * as React from 'react';
import {
    Create,
    TextInput,
    required,
    SimpleForm,
    DateInput,
    SelectInput,
    minValue,
} from 'react-admin';
import {CLASSES, COUNTRY_CODES, EXAMS, LANGUAGES, STATUSES} from "../../common/constants";
import ImageInput from "../../common/inputs/ImageInput";
import HTMLEditorInput, {validHtml} from "../../common/inputs/HTMLEditorInput";



const ProgramCreate = () => (
    <Create redirect={'/programs'}>
        <SimpleForm>
            <ImageInput source="image" fullWidth/>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <TextInput source="slug" fullWidth validate={[required()]}/>
            <TextInput type={'number'} source="display_order" fullWidth validate={[required(),minValue(0)]}/>
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUSES}
            />
            <SelectInput
                source="config.class"
                label={'Class'}
                fullWidth
                validate={[required()]}
                choices={CLASSES}
            />
            <SelectInput
                source="config.language"
                label={'Language'}
                validate={[required()]}
                fullWidth
                choices={LANGUAGES}
            />
            <SelectInput
                source="config.exam"
                validate={[required()]}
                label={'Exam'}
                fullWidth
                choices={EXAMS}
            />
            <HTMLEditorInput source={'description'} fullWidth validate={[required(), validHtml]}/>
        </SimpleForm>
    </Create>
);

export default ProgramCreate;
