import * as React from 'react';
import {Create, TextInput, NullableBooleanInput, SimpleForm, DateInput, SelectInput} from 'react-admin';


const VideoSubjectCreate = () => (
    <Create redirect={'/video_subjects'}>
        <SimpleForm>
            <TextInput source="name" fullWidth/>
        </SimpleForm>
    </Create>
);

export default VideoSubjectCreate;
