import React from 'react'

const DemoVideosCategoriesShow = () => {
  return (
    <div>
      show
    </div>
  )
}

export default DemoVideosCategoriesShow
