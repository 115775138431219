import * as React from 'react';
import {
    Create,
    TextInput,
    required,
    SimpleForm,
    DateTimeInput,
    SelectInput, ReferenceInput, useCreate, minValue,
} from 'react-admin';
import { STATUSES } from "../../common/constants";

import ImageInput from "../../common/inputs/ImageInput";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HTMLEditorInput, { validHtml } from "../../common/inputs/HTMLEditorInput";

const BatchesCreate = () => {
    const [create, { isLoading }] = useCreate();
    const navigate = useNavigate();

    const postSave = (data: any) => {
        if (isLoading) {
            return;
        }
        const d: any = {
            ...data
        }
        const product = {
            amount: data.product.amount,
            apple_amount: data.product.apple_amount,
            gst_percentage: data.product.gst_percentage,
            discount: data.product.discount,
            html_description: data.html_description,
            text_description: data.text_description,
            images: [data.image],
            name: data.title,
            available_quantity: 0,
            height_in_mtr: 0,
            length_in_mtr: 0,
            width_in_mtr: 0,
            weight_in_grams: 0,
        }
        const productTemplate = {
            html_description: data.html_description,
            text_description: data.text_description,
            images: [data.image],
            name: data.title,
            height_in_mtr: 0,
            length_in_mtr: 0,
            width_in_mtr: 0,
            weight_in_grams: 0,
        }


        delete d.product;
        create('batches', { data: d }, {
            onSuccess: (e) => {
                create('ecom_product_templates', { data: { ...productTemplate, batch_id: e.id } }, {
                    onSuccess: (ee) => {
                        create('ecom_products', { data: { ...product, product_template_id: ee.id } }, {
                            onSuccess: (e) => {
                                navigate(`/batches`)
                            }
                        });
                    }
                });
            }
        });
    };
    return <Create redirect={'/batches'}>
        <SimpleForm onSubmit={postSave}>
            <ImageInput source="image" validate={[required()]} fullWidth />
            <TextInput source="title" validate={[required()]} fullWidth />
            <TextInput source="display_order" type={'number'} validate={[required(),minValue(0)]} fullWidth />
            <ReferenceInput reference={'batch_templates'} source={'template_id'}>
                <SelectInput validate={[required()]} fullWidth optionText="title" />
            </ReferenceInput>
            <DateTimeInput source="start_time" validate={[required()]} fullWidth />
            <DateTimeInput source="end_time" validate={[required()]} fullWidth />
            <DateTimeInput source="registration_start_date" validate={[required()]} fullWidth />
            <DateTimeInput source="registration_end_date" validate={[required()]} fullWidth />
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUSES}
            />

            <TextInput rows={4} multiline source={'text_description'} fullWidth validate={[required()]} />
            <HTMLEditorInput source={'html_description'} fullWidth validate={[required(), validHtml]} />
            <Divider />
            <TextInput type={'number'} source="product.gst_percentage" prefix={'%'} fullWidth validate={[required(), minValue(0)]} />
            <TextInput type={'number'} source="product.apple_amount" prefix={'₹'} fullWidth validate={[required(),minValue(0)]} />
            <TextInput source={'product.amount'} fullWidth type={'number'} validate={[required(),minValue(0)]} />
            <TextInput source={'product.discount'} fullWidth type={'number'} validate={[required(),minValue(0)]} />
        </SimpleForm>
    </Create>
};

export default BatchesCreate;
