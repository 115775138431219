import TestimonialList from './TestimonialList';
import TestimonialEdit from './TestimonialEdit';
import {Image} from "@mui/icons-material";
import TestimonialCreate from "./TestimonialCreate";

export default {
    list: TestimonialList,
    edit: TestimonialEdit,
    create: TestimonialCreate,
    icon: Image,
};
