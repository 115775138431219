import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, SelectInput, required, ReferenceInput, DateTimeInput, ValidationError, useNotify, minValue,
} from 'react-admin';

import {Batches,} from "../../custom";
import {EditToolbar} from "../../common/EditToolbar";
import {STATUSES} from "../../common/constants";
import ImageInput from "../../common/inputs/ImageInput";
import HTMLEditorInput, {validHtml} from "../../common/inputs/HTMLEditorInput";


const BatchesEdit = () =>{
    // const notify = useNotify();
    // const validateRegistrationEndTime = (values:any) => {
    //     const { registration_end_date, end_time } = values;
    //     if (end_time && registration_end_date && registration_end_date > end_time) {
    //         notify(
    //             'Registration End Date Should less than End Date',
    //             {
    //                 type: 'error',
    //                 messageArgs: {},
    //             }
    //         );
    //     }
    //   };
  return  <Edit title={<BatchTitle/>}>
        <SimpleForm toolbar={<EditToolbar/>}
        //  validate={validateRegistrationEndTime}
        >
            <ImageInput source="image" validate={[required()]} fullWidth/>
            <TextInput source="display_order" type={'number'} validate={[required(),minValue(0)]} fullWidth/>
            <TextInput source="title" validate={[required()]} fullWidth/>
            <ReferenceInput reference={'batch_templates'} source={'template_id'}>
                <SelectInput validate={[required()]} fullWidth optionText="title"/>
            </ReferenceInput>
            <DateTimeInput source="start_time" validate={[required()]} fullWidth/>
            <DateTimeInput source="end_time" validate={[required()]} fullWidth/>
            <DateTimeInput source="registration_start_date" validate={[required()]} fullWidth/>
            <DateTimeInput source="registration_end_date" validate={[required()]} fullWidth/>
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUSES}
            />
            <TextInput rows={4} multiline source={'text_description'} fullWidth validate={[required()]}/>
            <HTMLEditorInput source={'html_description'} fullWidth validate={[required(), validHtml]}/>
        </SimpleForm>
    </Edit>
};

const BatchTitle = () => {
    const record = useRecordContext<Batches>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.programs.name', {smart_count: 1})} &quot;
            {record.title}&quot;
        </span>
    ) : null;
};

export default BatchesEdit;
