import * as React from 'react';
import {
    Create,
    TextInput,
    required,
    SimpleForm,
    SelectInput,
    ReferenceInput,
    NullableBooleanInput,
    minValue,
    FormDataConsumer
} from 'react-admin';
import ImageInput from "../../common/inputs/ImageInput";
import HTMLEditorInput, { validHtml } from "../../common/inputs/HTMLEditorInput";
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import PDFInput from "../../common/inputs/PDFInput";
import { useLocation, useNavigate } from "react-router-dom";
import TotalAmountInput from '../../common/inputs/TotalAmountInput';


const NonCategoryProductCreate = () => {
    let { productTemplateId } = useParams();
    const navigate = useNavigate();
    if (!productTemplateId) {
        return <LoadingSection />
    }

    // @ts-ignore
    return <Create resource={'ecom_products'} redirect={(e: any, id: any) => {
        navigate(-1);
    }}>
        <SimpleForm defaultValues={{ product_template_id: productTemplateId }}>
            <ImageInput source="images" multiple fullWidth validate={[required()]} />
            {/* <TotalAmountInput source="amount" label="Purchased Price" /> */}
            <PDFInput source="preview_pdf" fullWidth label={'Preview PDF'} />
            <TextInput source="name" fullWidth validate={[required()]} />
            <NullableBooleanInput source={'address_required'} fullWidth={true} name={'Address Required'} />
            <ReferenceInput reference={'ecom_product_templates'} source={'product_template_id'}>
                <SelectInput fullWidth disabled optionText="name" />
            </ReferenceInput>
            <TextInput type={'number'} source="mrp" prefix={'₹'} fullWidth validate={[required(), minValue(0)]} />
            <TextInput type={'number'} source="discount" prefix={'₹'} fullWidth validate={[required(), minValue(0)]} />
            <TextInput type={'number'} source="gst_percentage" prefix={'%'} fullWidth validate={[required(), minValue(0)]} />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return <div className='w-full'>
                        <TotalAmountInput formData={formData} source="amount" />
                    </div>
                }}
            </FormDataConsumer>
            <TextInput type={'number'} source="apple_amount" prefix={'₹'} fullWidth validate={[required(), minValue(0)]} />
            <TextInput type={'number'} source="validity_in_days" defaultValue={0} fullWidth validate={[required(), minValue(0)]} />
            <TextInput source="text_description" multiline rows={4} fullWidth validate={[required()]} />
            <HTMLEditorInput source={'html_description'} fullWidth validate={[required(), validHtml]} />
        </SimpleForm>
    </Create>
};

export default NonCategoryProductCreate;
