import * as React from 'react';
import {
    ArrayInput,
    AutocompleteInput, BooleanField, DateTimeInput, FormDataConsumer, ImageField, NullableBooleanInput, ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    Show, SimpleFormIterator,
    SimpleShowLayout, TextField, TextInput,
    useDataProvider,
    useRecordContext
} from 'react-admin';
import {useQuery} from "react-query";
import {Batch_Schedules, Contents} from "../../custom";
import {Notes, PictureAsPdfOutlined, VideoCameraFront} from "@mui/icons-material";
import {isScheduleLive} from "../../class-utilities";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import LiveSchedule from "../live_schedule/LiveSchedule";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const BatchScheduleShow = () => {
    const navigate = useNavigate();
    useEffect(()=>{
       navigate('/', {replace: true});
    });
    return <div></div>
};
export default BatchScheduleShow;
