import {Button, CircularProgress} from "@mui/material";
import {useState} from "react";
import {useController} from "react-hook-form";
import {BASE_URL} from "../constants";
import {Delete, PhotoCamera} from "@mui/icons-material";


export const uploadFile = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    return fetch(BASE_URL + "/upload", {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });
};
// This is generic image input which will take source as the fiel key and will upload the image to the server
const ImageInput = (props: any = {}) => {
    const i = useController({name: props.source});
    const [loading, setLoading] = useState(false);

    const showImages = () => {
        if (loading) {
            return false;
        }
        if (props.multiple && (i.field.value && i.field.value.length > 0)) {
            return true;
        }
        else if (!props.multiple && i.field.value) {
            return true;
        }
        return false;
    };
    const renderImages = () => {
        const result: any = [];
        if (props.multiple) {
            i.field.value?.forEach((v: string, index: number) => {
                result.push(
                    <div key={index + ""}  className={'flex justify-between items-center'}>
                        <img src={v} alt={v} className={'h-[100px]'}/>
                        <Button
                            onClick={() => {
                                const _images = JSON.parse(JSON.stringify(i.field.value));
                                _images.splice(index, 1);
                                i.field.onChange(_images);
                            }}
                            color="error"
                        >
                            <Delete/>
                        </Button>
                    </div>
                );
            });
        } else if (i.field.value) {
            result.push(
                <div key={"image"} className={'flex justify-between items-center'}>
                    <img src={i.field?.value} alt={i.field?.value} className={'h-[100px]'}/>
                    <Button onClick={() => i.field.onChange("")} color="error">
                        <Delete/>
                    </Button>
                </div>
            );
        }
        return result;
    };
    return (
        <>
            {showImages() && <div className={'p-2 rounded-md border w-full bg-gray-50 relative mb-4'}>{renderImages()}</div>}
            {loading && <CircularProgress/>}
            <div className={'w-full'}>
                {(
                    <div className={'p-2 rounded-md border w-full bg-gray-50 relative mb-2 flex items-center'}>
                        <PhotoCamera className={'text-gray-500'} fontSize={'large'}/>
                        <Button>
                            Add {props.label || 'Image'}
                            <input
                                placeholder={props.placeholder}
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    right: "0",
                                    bottom: "0",
                                    top: "0",
                                    opacity: "0",
                                }}
                                accept={"image/*"}
                                multiple={false}
                                {...i.field}
                                value={""}
                                onChange={async (e) => {
                                    const file = e.target.files?.[0];
                                    if (!file) {
                                        return;
                                    }
                                    try {
                                        setLoading(true);
                                        const res = await uploadFile(file);
                                        if (res?.status === 201 || res?.status === 200) {
                                            const data = await res.json();
                                            console.log(data)
                                            console.log('data')
                                            if (data) {
                                                if (props.multiple) {
                                                    const v = i.field.value || [];
                                                    i.field.onChange([
                                                        ...v,
                                                        `${data.url}`,
                                                    ]);
                                                } else {
                                                    i.field.onChange(
                                                        `${data.url}`
                                                    );
                                                }
                                            }
                                        } else {
                                            console.log(res);
                                            alert("Unable to upload Image, check console for error");
                                        }
                                    } catch (error) {
                                        console.log(error);

                                        alert("Unable to upload Image, check console for error");
                                    }
                                    setLoading(false);
                                }}
                                type={"file"}
                            />
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
};
export default ImageInput;
