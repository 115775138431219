import * as React from 'react';
import {

    List,
    EditButton,
    Datagrid,
    TextField, DateField, BooleanField,
} from 'react-admin';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';
import ThumbnailField from "../../common/ThumbnailField";
import {DeleteWithDialogAction} from "../../common/DeleteWithDialogAction";


const BannerList = () => {
    return (
        <div>
            <List sort={{field: 'display_order', order: 'ASC'}}>
                <Datagrid>

                    <ThumbnailField source="image"/>
                    <TextField source="display_order"/>
                    <BooleanField source="hidden"/>
                    <FormattedDateTimeField source="created_at"/>
                    
                    <EditButton/>
                    <DeleteWithDialogAction/>
                </Datagrid>
            </List>
        </div>
    );
};
export default BannerList;
