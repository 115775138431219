import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

const PurchasedPrice = ({ label }: { label: any }) => {
    const record = useRecordContext();
    if (!record) {
        // Handle case where record is not defined
        return null;
    }

    const finalPrice = ((record?.amount + (record?.delivery_amount || 0))).toLocaleString(undefined, {
        style: 'currency',
        currency: 'INR',
    });
    return (
        <div>
            <div>{finalPrice}</ div>
        </div>
    );
};

PurchasedPrice.propTypes = {
    label: PropTypes.string.isRequired,
};

export default PurchasedPrice;
