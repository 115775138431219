import { Button } from "@mui/material";
import { useState } from "react";
import { UploadFileOutlined } from "@mui/icons-material";
import { useNotify, useRecordContext } from "react-admin";
import { BASE_URL } from "../constants";
import { LoadingSection } from "../LoadingSection";

export const uploadFile = (file: any) => {
    // console.log("path", path)
    const formData = new FormData();
    formData.append("file", file);
    return fetch(BASE_URL + "/users/student-upload", {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });
};
const BulkUsersUploadInput = (props: any = {}) => {
    // const Path = props.path
    const record = useRecordContext();
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null as any);
    const notify = useNotify()

    return (
        <>
            <div style={{ position: "relative", width: '500px' }}>
                {
                    loading ? <LoadingSection /> : <>
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {
                                    file ? <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                        <UploadFileOutlined />
                                        <Button onClick={async (e) => {
                                            try {
                                                setLoading(true);
                                                const res = await uploadFile(file);
                                                if (res?.status === 201 || res?.status === 200) {
                                                    const data = await res.json();
                                                    props.onSuccess();
                                                    notify("uploaded successfully", {
                                                        type: 'success'
                                                    });
                                                } else {
                                                    console.log(res);
                                                    notify("Unable to upload file, check console for error", {
                                                        type: 'error'
                                                    });
                                                    // alert("Unable to upload file, check console for error");
                                                }
                                            } catch (error) {
                                                console.log(error);
                                                notify("Unable to upload file, check console for error", {
                                                    type: 'error'
                                                });
                                                // alert("Unable to upload file, check console for error");
                                            }
                                            setLoading(false);
                                        }}>
                                            Upload Excel File
                                        </Button>
                                    </div> :
                                        <Button disabled={loading}>
                                            Add Excel file

                                            <input
                                                accept={".xlsx,.xls,.csv"}
                                                placeholder={props.placeholder}
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    right: "0",
                                                    bottom: "0",
                                                    top: "0",
                                                    opacity: "0",
                                                }}
                                                multiple={false}
                                                value={""}
                                                onChange={async (e) => {
                                                    const file = e.target.files?.[0];
                                                    setFile(file);
                                                }}
                                                type={"file"}
                                            />
                                        </Button>
                                }
                            </div>

                        </div>
                    </>
                }

            </div>
        </>
    );
};
export default BulkUsersUploadInput;
