import BatchTemplateList from './BatchTemplateList';
import BatchTemplateEdit from './BatchTemplateEdit';
import {ViewInAr} from "@mui/icons-material";
import BatchTemplateCreate from "./BatchTemplateCreate";
import BatchTemplateShow from "./BatchTemplateShow";

export default {
    list: BatchTemplateList,
    edit: BatchTemplateEdit,
    show: BatchTemplateShow,
    create: BatchTemplateCreate,
};
