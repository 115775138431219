import * as React from 'react';
import {Avatar, SxProps} from '@mui/material';
import {FieldProps, useRecordContext} from 'react-admin';
import {Users} from "../custom";

interface Props extends FieldProps<Users> {
    sx?: SxProps;
    size?: string;
}

const AvatarField = ({size = '35', sx}: Props) => {
    const record = useRecordContext<Users>();
    if (!record) return null;
    return (
        <Avatar
            src={`${record.profile_image}?size=${size}x${size}`}
            style={{width: parseInt(size, 10), height: parseInt(size, 10)}}
            sx={sx}
            alt={`${record.first_name} ${record.last_name}`}
        />
    );
};

export default AvatarField;
