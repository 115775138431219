import {
    ArrayInput,
    AutocompleteInput, BooleanInput, Create,
    FormDataConsumer, NullableBooleanInput,
    ReferenceInput,
    required, SelectArrayInput,
    SelectInput,
    SimpleForm, TextInput,
    useCreate, useCreateContext, useDataProvider, useEditContext, useNotify, useRecordContext
} from "react-admin";
import * as React from "react";
import {Videos} from "../../custom";
import {VIDEO_ENCODING, VIDEO_TYPES} from "../constants";
import VideoInput from "./VideoInput";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {useController} from "react-hook-form";
import {InputAdornment} from "@mui/material";
import {extractYoutubeVideoId} from "../string-utilities";
import {Camera, Videocam} from "@mui/icons-material";
import {useQuery} from "react-query";
import useAllowedQualities from "../../hooks/useAllowedQualities";

const videoNameRender = (choice: Videos) => `${choice.title}| ${choice.code}`;
const filterToQueryVideo = (searchText: string) => searchText ? ({code: `${searchText}`}) : ({code: `${'NOT_AVAILABLE'}`});
const VideoModal = (props: any) => {
    const [title, setTitle] = useState(props?.title || '');
    const [type, setType] = useState('');
    const [rawUrl, setRawUrl] = useState('');
    const dataProvider = useDataProvider();
    const [qualities, setQualities] = useState([]);
    const [encodings, setEncodings] = useState(['HLS']);
    const notify = useNotify();
    const [create, {isLoading}] = useCreate();
    const {data: organization, refetch, error} = useQuery(
        ['organization_details', 'getList', {}],
        () => dataProvider.getList('organizations', {
            filter: {},
            pagination: {page: 1, perPage: 1},
            sort: {field: 'created_at', order: 'ASC'}
        })
    );
    const Qualities = useAllowedQualities();
    return <div
        className={'fixed top-0 left-0 right-0 bottom-0 z-[100] bg-gray-400 bg-opacity-50 flex flex-col items-center justify-center'}>
        <div className={'rounded-md bg-white shadow-md max-w-lg'}>
            <SimpleForm toolbar={<></>} defaultValues={{title}}>
                <div>
                    <TextInput fullWidth source={'title'} value={title} onChange={(e) => {
                        setTitle(e.target.value);
                    }}/>
                    <SelectInput
                        source="type"
                        label={'Type'}
                        fullWidth
                        onChange={(e) => {
                            setTimeout(() => {
                                if (document.getElementById('youtube-input')) {
                                    document.getElementById('youtube-input')?.focus();
                                }
                            }, 100);
                            setType(e.target.value);
                        }}
                        choices={VIDEO_TYPES}
                    />
                    <FormDataConsumer>
                        {({formData, ...rest}) =>
                            formData.type === 'YOUTUBE' ? <TextInput
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            https://www.youtube.com/embed/
                                        </InputAdornment>
                                    ),
                                }}
                                id={'youtube-input'}
                                label={'Youtube Embed Code'} fullWidth
                                onChange={(e) => {
                                    setRawUrl(e.target.value)
                                }}
                                validate={[required()]} source="raw_url" {...rest} /> : (formData.type ? <>
                                <ArrayInput source="qualities" defaultValue={qualities}>
                                    <SelectArrayInput
                                        label={'Video Qualities'}
                                        fullWidth
                                        onChange={(e) => setQualities(e.target.value)}
                                        choices={Qualities}/>
                                </ArrayInput>
                                <ArrayInput source="encodings" defaultValue={encodings}>
                                    <SelectArrayInput
                                        label={'Video Encodings'}
                                        fullWidth
                                        onChange={(e) => setEncodings(e.target.value)}
                                        choices={VIDEO_ENCODING}/>
                                </ArrayInput>
                                {/*<BooleanInput  defaultValue={false} fullWidth source={'drm_enabled'}/>*/}
                                <VideoInput
                                    onChange={(e: any) => {
                                        setRawUrl(e)
                                    }}
                                    meta={{
                                        drm_enabled: formData.drm_enabled,
                                        encodings: formData.encodings,
                                        qualities: formData.qualities
                                    }}
                                    type={'VIDEOS'} fullWidth source={'raw_url'}/>

                            </> : <></>)
                        }
                    </FormDataConsumer>
                    <div className={'flex space-x-2 mt-2 border-t pt-4'}>
                        <Button
                            disabled={isLoading || !rawUrl}
                            onClick={() => {
                                console.log(title, type, rawUrl);
                                if (!title || !type || !rawUrl) {
                                    notify(
                                        'Please fill Title, type and Video Url',
                                        {
                                            type: 'error',
                                            messageArgs: {},
                                        }
                                    );
                                    return;
                                }
                                create('videos', {
                                    data: {
                                        processed_url: type === 'YOUTUBE' ? `https://www.youtube.com/embed/${rawUrl}` : '',
                                        title: title,
                                        qualities: qualities,
                                        encodings: encodings,
                                        drm_enabled: false,
                                        type: type,
                                        raw_url: type === 'YOUTUBE' ? `https://www.youtube.com/embed/${rawUrl}` : rawUrl,
                                    }
                                }, {
                                    onSuccess: (e) => {
                                        if (props.onClose) {
                                            props.onClose(e.id);
                                        }
                                    }
                                });
                            }}>
                            Attach Video
                        </Button>
                        <Button color={'error'} onClick={() => {
                            if (props.onClose) {
                                props.onClose('');
                            }
                        }}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </SimpleForm>
        </div>
    </div>
}
const VideoAutocompleteInput = (props: any) => {
    const i = useController({name: props.source});
    const typeController = useController({name: 'type'});
    const createInputController = useController({name: 'createNew'});
    const rawUrlController = useController({name: 'raw_url'});
    const notify = useNotify();
    const [createVideo, setCreateVideo] = useState(false);

    if(i.field.value){
        return <div className={'w-full p-4 rounded-md bg-gray-50 mb-4 border border-gray-200 flex justify-between items-center'}>
            <Videocam className={'text-black'} style={{fontSize: '100px'}}/>
            <Button color={'error'} onClick={()=>{
                i.field.onChange(
                    ''
                );
            }}>Change Video</Button>
        </div>
    }
    return <div className={'w-full'}>
        {
            createVideo && <VideoModal title={props?.formData?.title} onClose={(e: any) => {
                if (e) {
                    i.field.onChange(
                        e
                    );
                }
                setCreateVideo(false);
            }}/>
        }
        <>
            <div className={'flex justify-between items-center'}>
                <div className={'text-xs text-gray-500'}>Search by video code</div>
                <div onClick={() => {
                    setCreateVideo(true)
                }}
                     className={'text-indigo-500 text-sm font-medium py-1 cursor-pointer px-2 hover:bg-indigo-50 transition-all duration-200 rounded'}>Add
                    a New Video
                </div>
            </div>
            <ReferenceInput source={props.source || 'video_id'} label={props.label || 'Video'}
                            reference={'videos'}>
                <AutocompleteInput optionText={videoNameRender} filterToQuery={filterToQueryVideo}
                                   onPaste={async (e: any) => {
                                       const _text = e.clipboardData.getData('Text')?.toLowerCase();
                                       if (_text.startsWith('https://www.youtube.com/embed/')) {
                                           createInputController.field.onChange(
                                               true
                                           );
                                           rawUrlController.field.onChange(
                                               _text.replace('https://www.youtube.com/embed/', '')
                                           );
                                           typeController.field.onChange(
                                               'YOUTUBE'
                                           );
                                       } else if (_text.startsWith('https://www.youtube.com') && extractYoutubeVideoId(_text)) {
                                           createInputController.field.onChange(
                                               true
                                           );
                                           rawUrlController.field.onChange(
                                               extractYoutubeVideoId(_text)
                                           );
                                           typeController.field.onChange(
                                               'YOUTUBE'
                                           );
                                       }
                                   }}
                                   limitChoicesToValue={true} debounce={500} fullWidth/>
            </ReferenceInput>
        </>
    </div>
}
export default VideoAutocompleteInput;
