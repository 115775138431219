import * as React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { useTranslate, useGetMany, useRecordContext, Loading, useGetList, DateTimeInput, DateField } from 'react-admin';

import { TableCellRight } from './TableCellRight';
import { Product } from "../../types";
import { Ecom_Orders } from "../../custom";
import { Link } from 'react-router-dom';
import PurchasedPrice from './PurchasedPrice';
import ItemName from './ItemName';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';

const Basket = () => {
    const record = useRecordContext<Ecom_Orders>();
    const productIds = record ? [] : [];

    const { isLoading, data: products } = useGetMany<Product>(
        'ecom_products',
        { ids: productIds },
        { enabled: !!record }
    );

    const { data, total, isLoading: orderItemsLoading, error } = useGetList(
        'ecom_order_items',
        {
            sort: { field: 'created_at', order: 'DESC' },
            filter: {
                order_id: record?.id

            },
        }
    );

    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    const productsById = products
        ? products.reduce((acc, product) => {
            acc[product.id] = product;
            return acc;
        }, {} as any)
        : {};

    if (isLoading || !record || !products) return null;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Item
                    </TableCell>
                    <TableCellRight>
                        Tracking ID
                    </TableCellRight>
                    <TableCellRight>
                        Amount
                    </TableCellRight>
                    <TableCellRight>
                        Date
                    </TableCellRight>
                    <TableCellRight>
                        Quantity
                    </TableCellRight>
                    <TableCellRight>
                        Total
                    </TableCellRight>
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.map((item: any) => {
                    return <TableRow>
                        <TableCell>
                            <ItemName productId={item?.product_id} />
                        </TableCell>
                        <TableCellRight>
                            {record?.tracking_id}
                        </TableCellRight>
                        <TableCellRight>
                            {record?.amount}
                            {/* <PurchasedPrice label='' /> */}
                        </TableCellRight>
                        <TableCellRight>
                            <DateField source="created_at" />
                        </TableCellRight>
                        <TableCellRight>
                        </TableCellRight>
                    </TableRow>
                })}


                {/* {record?.basket?.map((item: any) => (
                    <TableRow key={item.product_id}>
                        <TableCell>
                            <Link to={`/products/${item.product_id}`}>
                                {productsById[item.product_id].reference}
                            </Link>
                        </TableCell>
                        <TableCellRight>
                            {productsById[item.product_id].price.toLocaleString(
                                undefined,
                                {
                                    style: 'currency',
                                    currency: 'USD',
                                }
                            )}
                        </TableCellRight>
                        <TableCellRight>{item.quantity}</TableCellRight>
                        <TableCellRight>
                            {(
                                productsById[item.product_id].price *
                                item.quantity
                            ).toLocaleString(undefined, {
                                style: 'currency',
                                currency: 'USD',
                            })}
                        </TableCellRight>
                    </TableRow>
                ))} */}
            </TableBody>
        </Table>
    );
};

export default Basket;
