import React, { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { useController } from "react-hook-form";

const TotalAmountInput = (props: any) => {
    const Mrp = props?.formData?.mrp || 0;
    const Discount = props?.formData?.discount || 0;
    const Gst_Percentage = props?.formData?.gst_percentage || 0;
    const Amount = Mrp - Discount;
    const record = useRecordContext()
    const defaultAmount = record?.amount
    const Gst = parseInt(Gst_Percentage) / 100;
    const calculatedValue = (Amount / (1 + Gst)).toFixed(4);
    const [value, setValue] = useState(calculatedValue);
    const { field } = useController({ name: props.source });

    useEffect(() => {
        if (value !== calculatedValue) {
            setValue(calculatedValue);
            field.onChange(calculatedValue);
        }
    }, [calculatedValue, field, value]);

    return (
        <div className='relative mb-4 w-full'>
            <label className='absolute top-0 left-2 text-gray-600 text-xs transition-all duration-200 transform -translate-y-1/2 bg-white px-1' htmlFor='total-amount'>
                Selling Price
            </label>
            <div className='border border-gray-300 rounded py-2 px-2 hover:border-black focus-within:border-blue-600'>
                <input
                    disabled
                    id='total-amount'
                    value={parseInt(value) > 0 ? value : defaultAmount || 0}
                    className='w-full border-none outline-none'
                    readOnly
                />
            </div>
        </div>
    );
};

export default TotalAmountInput;
