import * as React from 'react';
import {
    Create,
    TextInput,
    required,
    SimpleForm,
    DateInput,
    SelectInput, ReferenceInput,
} from 'react-admin';
import {BATCH_TEMPLATE_TYPE, STATUSES} from "../../common/constants";
import ImageInput from "../../common/inputs/ImageInput";
import HTMLEditorInput, {validHtml} from "../../common/inputs/HTMLEditorInput";


const BatchTemplateCreate = () => (
    <Create redirect={'/batch_templates'}>
        <SimpleForm >
            <TextInput source="title" fullWidth validate={[required()]}/>
            <TextInput source="slug" fullWidth validate={[required()]}/>
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUSES}
            />
            <SelectInput
                source="type"
                label={'Type'}
                fullWidth
                validate={[required()]}
                choices={BATCH_TEMPLATE_TYPE}
            />
            <ReferenceInput label="Program" source="program_id" reference="programs">
                <SelectInput optionText="name" fullWidth validate={[required()]}/>
            </ReferenceInput>
            <HTMLEditorInput source={'description'} fullWidth validate={[required(), validHtml]}/>
        </SimpleForm>
    </Create>
);

export default BatchTemplateCreate;
