import {useMutation} from 'react-query';

import {Toolbar, Confirm, SaveButton, useRecordContext, useDataProvider, useResourceContext} from "react-admin";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

const SoftDeleteButton = ({resource}: { resource: string }) => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const {mutate, isLoading} = useMutation(
        () => dataProvider.update(resource, {
                id: record.id,
                data: {deleted: true},
                previousData: {deleted: false},

            },
        ),
        {
            onSuccess: () => {
                navigate(-1);
            }
        }
    );

    return <>
        <Button color={'error'} onClick={() => {
            setOpen(true)
        }}>
            Delete
        </Button>
        <Confirm
            isOpen={open}
            loading={isLoading}
            title={`Delete ${resource}`}
            content="Are you sure you want to delete this item?"
            onConfirm={() => mutate()}
            onClose={() => {
                setOpen(false)
            }}
        />
    </>;
};
const CancelButton = () => {
    const navigate = useNavigate();
    return <Button color={'secondary'} sx={{marginLeft: '10px'}} onClick={() => {
        navigate(-1);
    }}>
        Cancel
    </Button>
}

export const EditToolbar = (props: any) => {
    const resource = useResourceContext();
    return <Toolbar {...props} sx={{
        display: 'flex',
        justifyContent: 'space-between'
    }}>
        <div style={{
            display: 'flex',
            justifyContent: 'space-between'
        }}>
            <SaveButton/>
            <CancelButton/>
        </div>
        {/* {
            !props.disableDelete && resource && <SoftDeleteButton resource={resource}/>
        } */}
    </Toolbar>
};
