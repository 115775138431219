import * as React from 'react';
import { Create, TextInput, required, SimpleForm, DateInput, SelectInput, ReferenceInput, minValue } from 'react-admin';
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import { STATUSES } from "../../common/constants";


const TopicCreate = () => {
    let { programId, subjectId, chapterId } = useParams();
    if (!subjectId) {
        return <LoadingSection />
    }
    return <Create redirect={() => {
        return `programs/${programId}/subjects/${subjectId}/chapters/${chapterId}/topics`
    }} resource={'topics'}>
        <SimpleForm defaultValues={{ program_id: programId, subject_id: subjectId, chapter_id: chapterId }}>
            <TextInput source="name" fullWidth validate={[required()]} />
            <TextInput source="slug" fullWidth validate={[required()]} />
            <ReferenceInput label="Program" source="program_id" reference="programs">
                <SelectInput optionText="name" disabled fullWidth validate={[required()]} />
            </ReferenceInput>
            <ReferenceInput label="Subject" source="subject_id" reference="subjects">
                <SelectInput optionText="name" disabled fullWidth validate={[required()]} />
            </ReferenceInput>
            <ReferenceInput label="Chapter" source="chapter_id" reference="chapters">
                <SelectInput optionText="name" disabled fullWidth validate={[required()]} />
            </ReferenceInput>
            <TextInput source="display_order" type={'number'} fullWidth validate={[minValue(0)]} />

        </SimpleForm>
    </Create>
};

export default TopicCreate;
