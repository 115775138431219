import * as React from 'react';
import {
    Show,
    useTranslate,
    useRecordContext,
    TabbedShowLayout,
    ShowGuesser,
    Pagination,
    Datagrid,
    TextField,
    ReferenceField,
    BooleanField,
    ReferenceManyField,
    useCreate
} from 'react-admin';

import {Batch_Templates, Videos} from "../../custom";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

export const VideoProcessingSuccessStatus: any = {
    REQUEST_RECEIVED: 'REQUEST_RECEIVED',
    S3_FILE_FETCHED: 'S3_FILE_FETCHED',
    FFMPEG_PROCESSED: 'FFMPEG_PROCESSED',
    SHAKA_PROCESSED: 'SHAKA_PROCESSED',
    S3_FILE_UPLOADED: 'S3_FILE_UPLOADED',
}
export const VideoProcessingInProgressStatus: any = {
    S3_FILE_FETCHING: 'S3_FILE_FETCHING',
    FFMPEG_PROCESSING: 'FFMPEG_PROCESSING',
    SHAKA_PROCESSING: 'SHAKA_PROCESSING',
    S3_FILE_UPLOADING: 'S3_FILE_UPLOADING',
}

export const VideoProcessingInFailedStatus: any = {
    S3_FILE_FETCHING_FAILED: 'S3_FILE_FETCHING_FAILED',
    FFMPEG_PROCESSING_FAILED: 'FFMPEG_PROCESSING_FAILED',
    SHAKA_PROCESSING_FAILED: 'SHAKA_PROCESSING_FAILED',
    S3_FILE_UPLOADING_FAILED: 'S3_FILE_UPLOADING_FAILED',
}

const rowStyle = (record: any) => {
    if (VideoProcessingSuccessStatus[record.data.status]) {
        return {backgroundColor: '#3bab72'}
    } else if (VideoProcessingInFailedStatus[record.data.status]) {
        return {backgroundColor: '#d24545'}
    } else if (VideoProcessingInProgressStatus[record.data.status]) {
        return {backgroundColor: '#e3c545'}
    }
    return {}
};

const LogsWrapper = () => {
    return <ReferenceManyField
        label={'Video Processing Logs'}
        reference="video_processing_logs"
        target="video_id"
        sort={{field: 'created_at', order: 'ASC'}}
        pagination={<Pagination perPage={100}/>}
    >
        <Datagrid
            rowStyle={rowStyle}
            sx={{
                width: '100%',
                '& .column-comment': {
                    maxWidth: '20em',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                },
            }}
        >

            <TextField source={'data.status'}/>
            <TextField source={'data.errorMessage'}/>
            <FormattedDateTimeField source={'data.timestamp'}/>
        </Datagrid>
    </ReferenceManyField>
}
const Duplicate = () => {
    const record = useRecordContext<any>();
    const [create, {isLoading}] = useCreate();
    const navigate = useNavigate();
    const keys = ['drm_enabled', 'encodings', 'qualities', 'raw_url', 'restriction_max_duration_multiple', 'restriction_max_number_playback', 'title', 'type', 'video_subject_id',];
    const data: any = {};
    keys.forEach((k: any) => {
        data[k] = record[k];
    });
    data['title'] = data['title'] + ' - D';
    return <Button onClick={() => {
        create('videos', {data}, {
            onSuccess: (e) => {
                navigate(-2);
            }
        });
    }}>
        Duplicate Video
    </Button>
}
const VideoShow = () => (
    <Show title={<Title/>}>
        <TabbedShowLayout>
            <TabbedShowLayout.Tab
                label="resources.videos.tabs.details">
                {/*<ShowGuesser/>*/}
                <Duplicate/>
            </TabbedShowLayout.Tab>
        </TabbedShowLayout>
    </Show>
);

const Title = () => {
    const record = useRecordContext<Batch_Templates>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.videos.name', {smart_count: 1})} &quot;
            {record.title}
        </span>
    ) : null;
};

export default VideoShow;
