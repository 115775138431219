import * as React from 'react';
import {styled} from '@mui/material/styles';
import {useRecordContext} from 'react-admin';

const Img = styled('img')({
    maxWidth: 50,
    width: 50,
    maxHeight: 50,
    verticalAlign: 'middle',
});
const ImgLarge = styled('img')({
    maxWidth: 200,
    width: 200,
    maxHeight: 200,
    verticalAlign: 'middle',
});

const ThumbnailField = (props: { multiple?: boolean, source: string; size?: 'normal' | 'large', label?: string }) => {
    const record = useRecordContext<any>();
    if (!record) return null;
    let Item = Img;
    if (props.size === 'large') {
        Item = ImgLarge;
    }
    if (props.multiple) {
        return <>
            {
                record[props.source]?.map((image: string) => {
                    return <Item src={image} alt=""/>
                })
            }
        </>
    }
    return <Item src={record[props.source]} alt=""/>;
};

export default ThumbnailField;
