import * as React from 'react';
import {
    Create,
    ReferenceInput,
    TextInput,
    SimpleForm,
    required,
    SelectInput,
    useCreate,
    BooleanInput,
    minValue,
} from 'react-admin';
import ImageInput from '../../common/inputs/ImageInput';
import {LoadingSection} from "../../common/LoadingSection";

import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {Divider} from "@mui/material";
import {STATUSES, TEST_SERIES_TYPES} from "../../common/constants";
import HTMLEditorInput, {validHtml} from '../../common/inputs/HTMLEditorInput';


const TestSeriesCreate = () => {
    let {programId} = useParams();
    const [create, {isLoading}] = useCreate();
    const navigate = useNavigate();
    const postSave = (data: any) => {
        if (isLoading) {
            return;
        }
        const d: any = {
            ...data
        }
        const product = {
            amount: data.product.amount,
            discount: data.product.discount,
            html_description: data.html_description,
            text_description: data.text_description,
            images: [data.image],
            name: data.title,
            available_quantity: 0,
            height_in_mtr: 0,
            length_in_mtr: 0,
            width_in_mtr: 0,
            weight_in_grams: 0,
        }
        const productTemplate = {
            html_description: data.html_description,
            text_description: data.text_description,
            images: [data.image],
            name: data.title,
            height_in_mtr: 0,
            length_in_mtr: 0,
            width_in_mtr: 0,
            weight_in_grams: 0,
        }


        delete d.product;
        create('test_serieses', {data: d}, {
            onSuccess: (e) => {
                create('ecom_product_templates', {data: {...productTemplate, test_series_id: e.id}}, {
                    onSuccess: (ee) => {
                        create('ecom_products', {data: {...product, product_template_id: ee.id}}, {
                            onSuccess: (e) => {
                                navigate( `/programs/${programId}/test_serieses`)
                            }
                        });
                    }
                });
            }
        });
    };
    if (!programId) {
        return <LoadingSection/>
    }
    return <Create redirect={(e: any, id: any) => {
        return `programs/${programId}/test_serieses`
    }} resource={'test_serieses'}>
        <SimpleForm defaultValues={{program_id: programId}} onSubmit={postSave}>
            <ImageInput source="image" fullWidth validate={[required()]}/>
            <TextInput source="title" fullWidth validate={[required()]}/>
            <TextInput source="slug" fullWidth validate={[required()]}/>
            <TextInput type={'number'} source="display_order" fullWidth validate={[required(),minValue(0)]}/>
            <ReferenceInput label="Program" source="program_id" reference="programs">
                <SelectInput optionText="name" disabled fullWidth validate={[required()]}/>
            </ReferenceInput>
            <BooleanInput source={'is_demo'} defaultValue={false}/>
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUSES}
            />
            <SelectInput
                source="type"
                label={'Type'}
                fullWidth
                defaultValue={'REGULAR'}
                validate={[required()]}
                choices={TEST_SERIES_TYPES}
            />
            <TextInput multiline source="text_description" fullWidth validate={[required()]}/>
            <HTMLEditorInput source={'html_description'} fullWidth validate={[required(), validHtml]}/>
            <Divider/>
            <TextInput source={'product.amount'} fullWidth type={'number'} validate={[required(),minValue(0)]}/>
            <TextInput source={'product.discount'} fullWidth type={'number'} validate={[required(),minValue(0)]}/>
        </SimpleForm>
    </Create>
};

export default TestSeriesCreate;
