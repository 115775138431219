export const BASE_URL = 'https://api.kaksya.in'
export const GQL_URL = 'https://gql.kaksya.in/v1/graphql'

export enum Roles {
    ADMIN = 'ADMIN',
    ORG_ADMIN = 'ORG_ADMIN',
    STUDENT = 'STUDENT',
    TEACHER = 'TEACHER',
}

export const COUNTRY_CODES = [
    {id: '+91', name: '+91'},
]

export const LANGUAGES = [{
    id: 'HI',
    name: 'Hindi'
}, {
    id: 'EN',
    name: 'English'
}]
export const TEST_SERIES_TYPES = [{
    id: 'REGULAR',
    name: 'Regular'
}, {
    id: 'QUIZ',
    name: 'Quiz'
}]
export const SEGMENTATION_MODULE = [{
    id: 'BATCH',
    name: 'Batch'
}, ]
export const QUESTION_TYPES = [{
    id: 'SINGLE',
    name: 'Single'
}, {
    id: 'MULTIPLE',
    name: 'Multiple'
}, {
    id: 'NUMERIC',
    name: 'Numeric'
}]
export const CONTENT_TYPES = [{
    id: 'LECTURES',
    name: 'Lectures'
}, {
    id: 'EXERCISES',
    name: 'Exercises'
}, {
    id: 'NOTES',
    name: 'Notes'
}]

export const VIDEO_TYPES = [
    {
        id: 'YOUTUBE',
        name: 'Youtube'
    },
    {
        id: 'SECURE',
        name: 'Secure'
    },
    {
        id: 'CF_SECURE',
        name: 'Super Secure'
    }
]
export const RESOLUTION_STATUS = [
    {
        id: 'OPEN',
        name: 'OPEN'
    },
    // {
    //     id: 'CLOSED',
    //     name: 'CLOSED'
    // }, {
    //     id: 'RESOLVE_REQUESTED',
    //     name: 'RESOLVE_REQUESTED'
    // },
    {
        id: 'RESOLVED',
        name: 'RESOLVED'
    }
]
export const CLASSES = [{
    id: '6',
    name: '6'
}, {
    id: '7',
    name: '7'
}, {
    id: '8',
    name: '8'
}, {
    id: '9',
    name: '9'
}, {
    id: '10',
    name: '10'
}, {
    id: '11',
    name: '11'
}, {
    id: '12',
    name: '12'
}, {
    id: '12+',
    name: 'Dropper'
}]

export const TEST_TYPES = [{
    id: 'EXERCISE',
    name: 'Exercise'
}, {
    id: 'LIVE',
    name: 'Live'
}, {
    id: 'NORMAL',
    name: 'Normal'
}, {
    id: 'MOCK',
    name: 'Mock'
}]
export const TEST_CATEGORIES = [{
    id: 'OBJECTIVE',
    name: 'Objective'
}, {
    id: 'SUBJECTIVE',
    name: 'Subjective'
}]
export const EXAMS = [
    {
        id: 'IIT_JEE',
        name: 'IIT JEE'
    }, {
        id: 'NEET',
        name: 'NEET'
    }, {
        id: 'SCHOOL_PREPARATION',
        name: 'School Preparation'
    }, {
        id: 'UPSC',
        name: 'UPSC'
    }, {
        id: 'BOARD_EXAM',
        name: 'Board Exam'
    }, {
        id: 'BPSC',
        name: 'BPSC'
    }, {
        id: 'CA',
        name: 'CA'
    }, {
        id: 'BANKING',
        name: 'Banking'
    }, {
        id: 'CUET UG',
        name: 'CUET UG'
    }, {
        id: 'GATE',
        name: 'GATE'
    }, {
        id: 'OLYMPIAD',
        name: 'Olympiad'
    }, {
        id: 'NSAT',
        name: 'NSAT'
    }
]
export const STATUSES = [{
    id: 'NEW',
    name: 'New'
}, {
    id: 'ACTIVE',
    name: 'Active'
}, {
    id: 'IN_ACTIVE',
    name: 'Inactive'
}, {
    id: 'BLOCKED',
    name: 'Blocked'
}, {
    id: 'LOCKED',
    name: 'Locked'
}]
export const RESOLUTION_STATUSES = [{
    id: 'OPEN',
    name: 'OPEN'
}, {
    id: 'CLOSED',
    name: 'CLOSED'
}]
export const BATCH_TEMPLATE_TYPE = [{
    id: 'ONLINE',
    name: 'ONLINE'
}, {
    id: 'OFFLINE',
    name: 'OFFLINE'
},]
export const USER_STATUSES = [{
    id: 'NEW',
    name: 'New'
}, {
    id: 'ACTIVE',
    name: 'Active'
}, {
    id: 'BLOCKED',
    name: 'Blocked'
}]

export const VIDEO_QUALITIES = [
    {
        id: '360',
        name: '360'
    }, {
        id: '480',
        name: '480'
    }, {
        id: '720',
        name: '720'
    }
]
export const VIDEO_ENCODING = [{
    id: 'HLS',
    name: 'HLS'
}]
export const ORGANIZATION_RESOURCE_TYPES = [{
    id: 'DEMO_VIDEOS',
    name: 'Demo Videos'
}]
export const AllPermissions = [
    {
        "name": "acl_scopes"
    },
    {
        "name": "app_versions"
    },
    {
        "name": "apps"
    },
    {
        "name": "banners"
    },
    {
        "name": "batch_content_counts"
    },
    {
        "name": "batch_schedules"
    },
    {
        "name": "batch_students"
    },
    {
        "name": "batch_template_subjects"
    },
    {
        "name": "batch_templates"
    },
    {
        "name": "batches"
    },
    {
        "name": "ce_blogs"
    },
    {
        "name": "chapters"
    },
    {
        "name": "comments"
    },
    {
        "name": "contents"
    },
    {
        "name": "cs_issue_tags"
    },
    {
        "name": "cs_issue_templates"
    },
    {
        "name": "cs_issue_topics"
    },
    {
        "name": "cs_issues"
    },
    {
        "name": "doubts"
    },
    {
        "name": "ecom_attribute_values"
    },
    {
        "name": "ecom_attributes"
    },
    {
        "name": "ecom_categories"
    },
    {
        "name": "ecom_coupons"
    },
    {
        "name": "ecom_order_items"
    },
    {
        "name": "ecom_order_statuses"
    },
    {
        "name": "ecom_orders"
    },
    {
        "name": "ecom_product_templates"
    },
    {
        "name": "ecom_products"
    },
    {
        "name": "ecom_reviews"
    },
    {
        "name": "my_profile"
    },
    {
        "name": "organization_resources"
    },
    {
        "name": "organizations"
    },
    {
        "name": "otps"
    },
    {
        "name": "packages"
    },
    {
        "name": "programs"
    },
    {
        "name": "questions"
    },
    {
        "name": "sections"
    },
    {
        "name": "student_likes"
    },
    {
        "name": "student_profiles"
    },
    {
        "name": "student_test_mapping"
    },
    {
        "name": "student_test_series"
    },
    {
        "name": "subjects"
    },
    {
        "name": "test_serieses"
    },
    {
        "name": "testimonials"
    },
    {
        "name": "tests"
    },
    {
        "name": "topics"
    },
    {
        "name": "user_bookmarks"
    },
    {
        "name": "user_notifications"
    },
    {
        "name": "user_programs"
    },
    {
        "name": "users"
    },
    {
        "name": "delete_acl_scopes"
    },
    {
        "name": "delete_app_versions"
    },
    {
        "name": "delete_apps"
    },
    {
        "name": "delete_banners"
    },
    {
        "name": "delete_batch_schedules"
    },
    {
        "name": "delete_batch_students"
    },
    {
        "name": "delete_batch_template_subjects"
    },
    {
        "name": "delete_batch_templates"
    },
    {
        "name": "delete_batches"
    },
    {
        "name": "delete_ce_blogs"
    },
    {
        "name": "delete_chapters"
    },
    {
        "name": "delete_comments"
    },
    {
        "name": "delete_contents"
    },
    {
        "name": "delete_cs_issue_tags"
    },
    {
        "name": "delete_cs_issue_templates"
    },
    {
        "name": "delete_cs_issue_topics"
    },
    {
        "name": "delete_cs_issues"
    },
    {
        "name": "delete_doubts"
    },
    {
        "name": "delete_ecom_attribute_values"
    },
    {
        "name": "delete_ecom_attributes"
    },
    {
        "name": "delete_ecom_categories"
    },
    {
        "name": "delete_ecom_coupons"
    },
    {
        "name": "delete_ecom_order_items"
    },
    {
        "name": "delete_ecom_order_statuses"
    },
    {
        "name": "delete_ecom_orders"
    },
    {
        "name": "delete_ecom_product_templates"
    },
    {
        "name": "delete_ecom_products"
    },
    {
        "name": "delete_ecom_reviews"
    },
    {
        "name": "delete_organization_resources"
    },
    {
        "name": "delete_organizations"
    },
    {
        "name": "delete_otps"
    },
    {
        "name": "delete_packages"
    },
    {
        "name": "delete_programs"
    },
    {
        "name": "delete_questions"
    },
    {
        "name": "delete_sections"
    },
    {
        "name": "delete_student_likes"
    },
    {
        "name": "delete_student_profiles"
    },
    {
        "name": "delete_student_test_mapping"
    },
    {
        "name": "delete_student_test_series"
    },
    {
        "name": "delete_subjects"
    },
    {
        "name": "delete_test_serieses"
    },
    {
        "name": "delete_testimonials"
    },
    {
        "name": "delete_tests"
    },
    {
        "name": "delete_topics"
    },
    {
        "name": "delete_user_bookmarks"
    },
    {
        "name": "delete_user_notifications"
    },
    {
        "name": "delete_user_programs"
    },
    {
        "name": "delete_users"
    },
    {
        "name": "get_otp"
    },
    {
        "name": "insert_acl_scopes"
    },
    {
        "name": "insert_acl_scopes_one"
    },
    {
        "name": "insert_app_versions"
    },
    {
        "name": "insert_app_versions_one"
    },
    {
        "name": "insert_apps"
    },
    {
        "name": "insert_apps_one"
    },
    {
        "name": "insert_banners"
    },
    {
        "name": "insert_banners_one"
    },
    {
        "name": "insert_batch_schedules"
    },
    {
        "name": "insert_batch_schedules_one"
    },
    {
        "name": "insert_batch_students"
    },
    {
        "name": "insert_batch_students_one"
    },
    {
        "name": "insert_batch_template_subjects"
    },
    {
        "name": "insert_batch_template_subjects_one"
    },
    {
        "name": "insert_batch_templates"
    },
    {
        "name": "insert_batch_templates_one"
    },
    {
        "name": "insert_batches"
    },
    {
        "name": "insert_batches_one"
    },
    {
        "name": "insert_ce_blogs"
    },
    {
        "name": "insert_ce_blogs_one"
    },
    {
        "name": "insert_chapters"
    },
    {
        "name": "insert_chapters_one"
    },
    {
        "name": "insert_comments"
    },
    {
        "name": "insert_comments_one"
    },
    {
        "name": "insert_contents"
    },
    {
        "name": "insert_contents_one"
    },
    {
        "name": "insert_cs_issue_tags"
    },
    {
        "name": "insert_cs_issue_tags_one"
    },
    {
        "name": "insert_cs_issue_templates"
    },
    {
        "name": "insert_cs_issue_templates_one"
    },
    {
        "name": "insert_cs_issue_topics"
    },
    {
        "name": "insert_cs_issue_topics_one"
    },
    {
        "name": "insert_cs_issues"
    },
    {
        "name": "insert_cs_issues_one"
    },
    {
        "name": "insert_doubts"
    },
    {
        "name": "insert_doubts_one"
    },
    {
        "name": "insert_ecom_attribute_values"
    },
    {
        "name": "insert_ecom_attribute_values_one"
    },
    {
        "name": "insert_ecom_attributes"
    },
    {
        "name": "insert_ecom_attributes_one"
    },
    {
        "name": "insert_ecom_categories"
    },
    {
        "name": "insert_ecom_categories_one"
    },
    {
        "name": "insert_ecom_coupons"
    },
    {
        "name": "insert_ecom_coupons_one"
    },
    {
        "name": "insert_ecom_order_items"
    },
    {
        "name": "insert_ecom_order_items_one"
    },
    {
        "name": "insert_ecom_order_statuses"
    },
    {
        "name": "insert_ecom_order_statuses_one"
    },
    {
        "name": "insert_ecom_orders"
    },
    {
        "name": "insert_ecom_orders_one"
    },
    {
        "name": "insert_ecom_product_templates"
    },
    {
        "name": "insert_ecom_product_templates_one"
    },
    {
        "name": "insert_ecom_products"
    },
    {
        "name": "insert_ecom_products_one"
    },
    {
        "name": "insert_ecom_reviews"
    },
    {
        "name": "insert_ecom_reviews_one"
    },
    {
        "name": "insert_organization_resources"
    },
    {
        "name": "insert_organization_resources_one"
    },
    {
        "name": "insert_organizations"
    },
    {
        "name": "insert_organizations_one"
    },
    {
        "name": "insert_otps"
    },
    {
        "name": "insert_otps_one"
    },
    {
        "name": "insert_packages"
    },
    {
        "name": "insert_packages_one"
    },
    {
        "name": "insert_programs"
    },
    {
        "name": "insert_programs_one"
    },
    {
        "name": "insert_questions"
    },
    {
        "name": "insert_questions_one"
    },
    {
        "name": "insert_sections"
    },
    {
        "name": "insert_sections_one"
    },
    {
        "name": "insert_student_likes"
    },
    {
        "name": "insert_student_likes_one"
    },
    {
        "name": "insert_student_profiles"
    },
    {
        "name": "insert_student_profiles_one"
    },
    {
        "name": "insert_student_test_mapping"
    },
    {
        "name": "insert_student_test_mapping_one"
    },
    {
        "name": "insert_student_test_series"
    },
    {
        "name": "insert_student_test_series_one"
    },
    {
        "name": "insert_subjects"
    },
    {
        "name": "insert_subjects_one"
    },
    {
        "name": "insert_test_serieses"
    },
    {
        "name": "insert_test_serieses_one"
    },
    {
        "name": "insert_testimonials"
    },
    {
        "name": "insert_testimonials_one"
    },
    {
        "name": "insert_tests"
    },
    {
        "name": "insert_tests_one"
    },
    {
        "name": "insert_topics"
    },
    {
        "name": "insert_topics_one"
    },
    {
        "name": "insert_user_bookmarks"
    },
    {
        "name": "insert_user_bookmarks_one"
    },
    {
        "name": "insert_user_notifications"
    },
    {
        "name": "insert_user_notifications_one"
    },
    {
        "name": "insert_user_programs"
    },
    {
        "name": "insert_user_programs_one"
    },
    {
        "name": "insert_users"
    },
    {
        "name": "insert_users_one"
    },
    {
        "name": "resend_otp"
    },
    {
        "name": "update_acl_scopes"
    },
    {
        "name": "update_app_versions"
    },
    {
        "name": "update_apps"
    },
    {
        "name": "update_banners"
    },
    {
        "name": "update_batch_schedules"
    },
    {
        "name": "update_batch_students"
    },
    {
        "name": "update_batch_template_subjects"
    },
    {
        "name": "update_batch_templates"
    },
    {
        "name": "update_batches"
    },
    {
        "name": "update_ce_blogs"
    },
    {
        "name": "update_chapters"
    },
    {
        "name": "update_comments"
    },
    {
        "name": "update_contents"
    },
    {
        "name": "update_cs_issue_tags"
    },
    {
        "name": "update_cs_issue_templates"
    },
    {
        "name": "update_cs_issue_topics"
    },
    {
        "name": "update_cs_issues"
    },
    {
        "name": "update_doubts"
    },
    {
        "name": "update_ecom_attribute_values"
    },
    {
        "name": "update_ecom_attributes"
    },
    {
        "name": "update_ecom_categories"
    },
    {
        "name": "update_ecom_coupons"
    },
    {
        "name": "update_ecom_order_items"
    },
    {
        "name": "update_ecom_order_statuses"
    },
    {
        "name": "update_ecom_orders"
    },
    {
        "name": "update_ecom_product_templates"
    },
    {
        "name": "update_ecom_products"
    },
    {
        "name": "update_ecom_reviews"
    },
    {
        "name": "update_organization_resources"
    },
    {
        "name": "update_organizations"
    },
    {
        "name": "update_otps"
    },
    {
        "name": "update_packages"
    },
    {
        "name": "update_programs"
    },
    {
        "name": "update_questions"
    },
    {
        "name": "update_sections"
    },
    {
        "name": "update_student_likes"
    },
    {
        "name": "update_student_profiles"
    },
    {
        "name": "update_student_test_mapping"
    },
    {
        "name": "update_student_test_series"
    },
    {
        "name": "update_subjects"
    },
    {
        "name": "update_test_serieses"
    },
    {
        "name": "update_testimonials"
    },
    {
        "name": "update_tests"
    },
    {
        "name": "update_topics"
    },
    {
        "name": "update_user_bookmarks"
    },
    {
        "name": "update_user_notifications"
    },
    {
        "name": "update_user_programs"
    },
    {
        "name": "update_users"
    },
    {
        "name": "verify_otp"
    }
]
