import * as React from 'react';
import {
    AutocompleteInput,
    BooleanField, Create, Datagrid,
    ExportButton,
    FormDataConsumer, List, Pagination,
    ReferenceField,
    ReferenceInput, ReferenceManyField,
    required,
    Show, SimpleForm, TabbedShowLayout,
    TextField, TextInput,
    TopToolbar,
    useDataProvider,
    useRecordContext, useRefresh
} from 'react-admin';
import { useQuery } from "react-query";
import { Batch_Schedules, Contents } from "../../custom";
import { PictureAsPdfOutlined, VideoCameraFront } from "@mui/icons-material";
import { isScheduleLive } from "../../class-utilities";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import LiveSchedule from "../live_schedule/LiveSchedule";
import { useEffect, useState } from "react";
import FullNameField from "../../common/FullNameField";
import { homeworkQuery, homeworkRender } from "./BatchScheduleEdit";
import { SoftDeleteWithDialogAction } from "../../common/SoftDeleteWithDialogAction";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { TextField as MUITextInput } from "@mui/material";
import { displayDuration } from "../../common/string-utilities";
import { format } from 'date-fns';
import { downloadCSV, jsonToCSV } from '../../common/CSVExport';
import MonthSelector from '../../common/MonthSelector';

const BatchScheduleShowComponent = () => {
    const record = useRecordContext();
    // console.log(record);
    // console.log('record');
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const [maxHeight, setMaxHeight] = useState(0);
    const { data: notes, refetch, isLoading, error } = useQuery(
        ['contents_' + record.id, 'getList', { batch_schedule_id: record.id }],
        () => dataProvider.getList('contents', {
            filter: {
                batch_schedule_contents: {
                    format: 'hasura-raw-query',
                    value: {
                        batch_schedule_id: { _eq: record.id }
                    },
                }
            },
            pagination: { page: 1, perPage: 20 },
            sort: { field: 'display_order', order: 'ASC' }
        })
    );
    useEffect(() => {
        const ele = document.getElementById('details-section');
        if (ele) {
            setTimeout(() => {
                // @ts-ignore
                setMaxHeight(ele?.offsetHeight - 50);
            }, 200);
            setTimeout(() => {
                // @ts-ignore
                setMaxHeight(ele?.offsetHeight - 50);
            }, 2000);
        }
    }, [record]);
    return <div className={'h-[calc(100vh-180px)]'} id={'details-section'}>
        <div className={'text-2xl border-b leading-10 mb-2 items-center flex justify-between'}>
            <div>
                {record.title}
            </div>
            <Button variant={'contained'} onClick={() => {
                navigate(`/batch_schedules/${record.id}/preview`);
            }}>
                Preview
            </Button>
        </div>

        <div className={'w-full grid grid-cols-3'}>
            <div className={'flex flex-col space-y-2 text-sm'}>
                <div className={'flex items-center flex-wrap'}>
                    {
                        notes?.data.map((note: Contents, index: number) => {
                            return <div key={index}
                                className={'px-3 py-2 mr-2 mb-2 rounded shadow space-x-2 flex items-center border cursor-pointer text-indigo-500'}>
                                <PictureAsPdfOutlined />
                                <div>
                                    {note.title}
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className={'grid grid-cols-2 gap-2 border-b'}>
                    <label className={'font-medium mt-2'}>Batch</label>
                    <ReferenceField label={'Batch'} source={'batch_id'} reference={'batches'}>
                        <TextField label={'Batch'} source={'title'} />
                    </ReferenceField>
                </div>
                <div className={'grid grid-cols-2 gap-2 border-b'}>
                    <label className={'font-medium mt-2'}>Video</label>
                    <ReferenceField label={'Video'} source={'video_id'} reference={'videos'}>
                        <TextField source={'code'} />
                    </ReferenceField>
                </div>
                <div className={'grid grid-cols-2 gap-2 border-b'}>
                    <label className={'font-medium mt-2'}>Subject</label>
                    <ReferenceField label={'Subject'} source={'subject_id'} reference={'subjects'}>
                        <TextField source={'name'} />
                    </ReferenceField>
                </div>
                <div className={'grid grid-cols-2 gap-2 border-b'}>
                    <label className={'font-medium mt-2'}>Primary Teachers</label>
                    <ReferenceField label={'Primary Teacher'} source={'primary_teacher_id'} reference={'users'}>
                        <FullNameField />
                    </ReferenceField>
                </div>
                <div className={'grid grid-cols-2 gap-2 border-b'}>
                    <label className={'font-medium mt-2'}>Chapter</label>
                    <ReferenceField label={'Chapter'} source={'chapter_id'} reference={'chapters'}>
                        <TextField source={'name'} />
                    </ReferenceField>
                </div>
                <div className={'grid grid-cols-2 gap-2 border-b'}>
                    <label className={'font-medium mt-2'}>Chat Enabled</label>
                    <BooleanField source="chat_enabled" />
                </div>
                <div className={'grid grid-cols-2 gap-2 border-b'}>
                    <label className={'font-medium mt-2'}>Is Demo</label>
                    <BooleanField source="is_demo" />
                </div>
                <div className={'grid grid-cols-2 gap-2 border-b'}>
                    <label className={'font-medium mt-2'}>Start Time</label>
                    <FormattedDateTimeField source="start_time" />
                </div>
                <div className={'grid grid-cols-2 gap-2 border-b'}>
                    <label className={'font-medium mt-2'}>End Time</label>
                    <FormattedDateTimeField source="end_time" />
                </div>
                <div className={'grid grid-cols-2 gap-2 border-b'}>
                    <label className={'font-medium mt-2'}>Created At</label>
                    <FormattedDateTimeField source="created_at" />
                </div>
                <div className={'grid grid-cols-2 gap-2 border-b'}>
                    <label className={'font-medium mt-2'}>Updated At</label>
                    <FormattedDateTimeField source="updated_at" />
                </div>
            </div>
            {/*{*/}
            {/*    isScheduleLive(record as Batch_Schedules) &&*/}
            {/*    <div style={{height: `${maxHeight}px`}} className={'flex-1 bg-gray-100'}>*/}
            {/*        <LiveSchedule scheduleId={record.id as string}/>*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
    </div>
}
const Homeworks = () => {
    const record = useRecordContext();
    const refresh = useRefresh();
    return <div className={'w-full'}>
        <div className={'w-full'}>
            <Create resource={'batch_schedule_contents'} redirect={location.href} mutationOptions={{
                onSuccess: () => {
                    refresh();
                }
            }}>
                <SimpleForm defaultValues={{ batch_schedule_id: record.id }}>
                    <ReferenceInput name={'Contents'} source={'content_id'}
                        filter={{ subject_id: record.subject_id, batch_id: record.batch_id }}
                        reference={'contents'}>
                        <AutocompleteInput optionText={homeworkRender}
                            filterToQuery={(q) => homeworkQuery(q, record.subject_id, record.batch_id as string)}
                            debounce={500} fullWidth validate={[required()]} />
                    </ReferenceInput>
                </SimpleForm>
            </Create>
        </div>
        <ReferenceManyField
            label={'Homeworks'}
            reference="batch_schedule_contents"
            target="batch_schedule_id"
            filter={{
                batch_schedule_id: record.id
            }}
            pagination={<Pagination />}
        >
            <Datagrid>
                <ReferenceField label={'Content'} source={'content_id'} reference={'contents'}>
                    <TextField source={'title'} />
                </ReferenceField>
                <ReferenceField label={'Subject'} source={'content_id'} reference={'contents'}>
                    <ReferenceField label={'Subject'} source={'subject_id'} reference={'subjects'}>
                        <TextField source={'name'} />
                    </ReferenceField>
                </ReferenceField>
                <ReferenceField label={'Chapter'} source={'content_id'} reference={'contents'}>
                    <ReferenceField label={'Subject'} source={'chapter_id'} reference={'chapters'}>
                        <TextField source={'name'} />
                    </ReferenceField>
                </ReferenceField>
                <FormattedDateTimeField source={'created_at'} />
                <SoftDeleteWithDialogAction />
            </Datagrid>
        </ReferenceManyField>
    </div>
}
const DurationAllowed = ({ }) => {
    const su = useRecordContext();
    return <div>
        {displayDuration(Math.floor(su.max_allowed_duration_in_seconds))}
    </div>
}
DurationAllowed.defaultProps = {
    label: 'Duration allowed'
}
const DurationWatchedInSeconds = ({ }) => {
    const su = useRecordContext();
    let color: 'red' | 'green' | 'orange' = "green";
    if (su.max_allowed_duration_in_seconds && ((su.max_allowed_duration_in_seconds) * 0.8) < su.duration_watched_in_seconds) {
        color = 'orange';
    }
    if (su.max_allowed_duration_in_seconds && su.max_allowed_duration_in_seconds < su.duration_watched_in_seconds) {
        color = 'red';
    }
    return <div className={'text-white px-2 py-0.5 rounded-md text-center'} style={{
        backgroundColor: color
    }}>
        {displayDuration(Math.floor(su.duration_watched_in_seconds))}
    </div>
}
DurationWatchedInSeconds.defaultProps = {
    label: 'Duration watched'
}
const PlaybackWatched = ({ }) => {
    const su = useRecordContext();
    let color: 'red' | 'green' | 'orange' = "green";
    if (su.max_allowed_playbacks && ((su.max_allowed_playbacks) * 0.8) < su.current_playbacks) {
        color = 'orange';
    }
    if (su.max_allowed_playbacks && su.max_allowed_playbacks < su.current_playbacks) {
        color = 'red';
    }
    return <div className={'text-white px-2 py-0.5 rounded-md text-center'} style={{
        backgroundColor: color
    }}>
        {su.current_playbacks}
    </div>
}
PlaybackWatched.defaultProps = {
    label: 'Playback watched'
}
const ModifyVideoLimit = () => {
    const record = useRecordContext();
    const navigate = useNavigate();
    return <Button onClick={() => {
        navigate(`/student_video_stats/${record.id}/edit`);
    }}>Reset</Button>
}
ModifyVideoLimit.defaultProps = {
    label: 'Action'
}


const WatchStats = () => {
    const ListActions = () => (
        <TopToolbar className="">
            <ExportButton />

        </TopToolbar>
    );
    const [filterValue, setFilterValue] = useState('');

    return (
        <div className={'w-full'}>
            <div className={'p-2'}>
                <MUITextInput
                    label="Search by student name / phone"
                    value={filterValue}
                    variant={'outlined'}
                    fullWidth
                    onChange={(e) => setFilterValue(e.target.value)}
                />
            </div>
            <ReferenceManyField
                label={'Watch Stats'}
                reference="student_video_stats"
                target="batch_schedule_id"
                filter={filterValue ? {
                    student: {
                        format: 'hasura-raw-query',
                        value: { phone: { _eq: filterValue } },
                    }
                } : {}}
                pagination={<Pagination />}

            >
                <List
                    actions={<ListActions />}
                >
                    <Datagrid>
                        <ReferenceField reference={'users'} source={'student_id'}>
                            <FullNameField />
                        </ReferenceField>
                        <DurationAllowed />
                        <DurationWatchedInSeconds />
                        <TextField source={'max_allowed_playbacks'} label={'Playback allowed'} />
                        <PlaybackWatched />
                        <TextField source={'current_timestamp'} label={'Current timestamp'} />
                        <FormattedDateTimeField source={'created_at'} />
                        <ModifyVideoLimit />
                    </Datagrid>
                </List>
            </ReferenceManyField>

        </div >
    );
}


const BatchScheduleShow = () => {
    return <Show>
        <TabbedShowLayout>
            <TabbedShowLayout.Tab
                path={''}
                label="Details">
                <BatchScheduleShowComponent />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab
                path={'homeworks'}
                label="Homeworks">
                <Homeworks />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab
                path={'watch-stats'}
                label="Watch stats">
                <WatchStats />
            </TabbedShowLayout.Tab>
        </TabbedShowLayout>
    </Show>
};
export default BatchScheduleShow;
