import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required, minValue,
} from 'react-admin';

import {Ce_Blog_Topics, Ce_Blogs,} from "../../custom";
import {EditToolbar} from "../../common/EditToolbar";
import ImageInput from "../../common/inputs/ImageInput";

const BlogTopicEdit = () => (
    <Edit title={<BlogTitle/>}>
        <SimpleForm toolbar={<EditToolbar/>}>
            <ImageInput source={'image'} fullWidth validate={[required()]}/>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <TextInput type={'number'} source="display_order" fullWidth validate={[required(),minValue(0)]}/>
        </SimpleForm>
    </Edit>
);

const BlogTitle = () => {
    const record = useRecordContext<Ce_Blog_Topics>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.blog_topics.name', {smart_count: 1})} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default BlogTopicEdit;
