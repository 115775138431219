import * as React from 'react';
import {Create, TextInput, required, SimpleForm, ReferenceInput, SelectInput, minValue,} from 'react-admin';
import ImageInput from "../../common/inputs/ImageInput";

import HTMLEditorInput from "../../common/inputs/HTMLEditorInput";


const BlogCreate = () => (
    <Create redirect={'/ce_blogs'}>
        <SimpleForm>
            <ImageInput source={'thumbnail'} fullWidth validate={[required()]}/>
            <ReferenceInput label="Blog Topic" source="blog_topic_id" reference="ce_blog_topics">
                <SelectInput optionText="name"  fullWidth validate={[required()]}/>
            </ReferenceInput>
            <TextInput source="title" fullWidth validate={[required()]}/>
            <TextInput source="text_description" fullWidth validate={[required()]}/>
            <TextInput source="slug" fullWidth validate={[required()]}/>
            <TextInput type={'number'} source="display_order" fullWidth validate={[required(),minValue(0)]}/>
            <HTMLEditorInput source={'html_content'} fullWidth validate={[required()]}/>
        </SimpleForm>
    </Create>
);

export default BlogCreate;
