import {CheckCircle, Refresh, Save} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {useCreate, useDataProvider, useNotify, useRecordContext, useRefresh, useUpdate} from "react-admin";
import {useQuery} from "react-query";
import {Subjects} from "../../custom";
import {useEffect, useState} from "react";

const BatchScheduleTemplates = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const {data: batchTemplateSubjectsData,} = useQuery(
        ['batch_template_subjects' + record.id, 'getList', {batch_id: record.id}],
        () => dataProvider.getList('batch_template_subjects', {
            filter: {
                batch_template: {
                    format: 'hasura-raw-query',
                    value: {
                        batches: {
                            id: {_eq: record.id}
                        }
                    },
                }
            },
            pagination: {page: 1, perPage: 400},
            sort: {field: 'created_at', order: 'ASC'}
        })
    );
    const batchTemplateSubjects = batchTemplateSubjectsData?.data;
    if (!batchTemplateSubjects?.length) {
        return <div>
            No Subject Added to batch Template
        </div>
    }
    return <BatchScheduleTemplatesComponent
        batchTemplateSubjects={batchTemplateSubjects}/>
}
const BatchScheduleTemplateComponent = ({day, batchTemplateSubjectId, existingValue}: {existingValue: any, day: string, batchTemplateSubjectId: string}) => {
    const [create, {isLoading: isCreating}] = useCreate();
    const [update, {isLoading: isUpdating}] = useUpdate();
    const [id, setId] = useState();
    const isLoading = isUpdating || isCreating
    const [startTime, setStartTime] = useState(null as any);
    const [endTime, setEndTime] = useState(null as any);
    const record = useRecordContext();
    const notify = useNotify();
    useEffect(() => {
        setStartTime(existingValue?.start_time?.split('+')[0]);
        setEndTime(existingValue?.end_time?.split('+')[0]);
        setId(id);
    }, [existingValue]);

    return <>
        <div className={'flex-1'}>
            {
                day
            }
        </div>
        <div className={'border rounded-md overflow-hidden py-0.5 px-2'}>
            <input value={startTime} onChange={(e)=>setStartTime(e.target.value)} placeholder={'Start Time'} type={'time'}/>
        </div>
        <div className={'border rounded-md overflow-hidden py-0.5 px-2'}>
            <input value={endTime} onChange={(e)=>setEndTime(e.target.value)} placeholder={'End Time'} type={'time'}/>
        </div>
        <div>
            <Save onClick={() => {
                if (!isLoading && startTime && endTime) {
                    console.log(id);
                    console.log('id');
                    if(!existingValue && !id){
                        create('batch_schedule_templates', {data: {batch_template_subject_id: batchTemplateSubjectId, day: day, start_time: startTime, end_time: endTime, batch_id: record.id}}, {
                            onSuccess:(dd)=>{
                                notify(
                                    'Schedule template created',
                                    {
                                        type: 'success'
                                    }

                                );
                                setId(dd.id);
                            },
                            onError:()=>{
                                notify(
                                    'Unable to create schedule template',
                                    {
                                        type: 'error'
                                    }
                                );
                            }
                        });
                    }else{
                        update('batch_schedule_templates', { id, data: { start_time: startTime, end_time: endTime,  }, previousData: existingValue }, {
                            onSuccess:()=>{
                                notify(
                                    'Schedule template updated',
                                    {
                                        type: 'success'
                                    }
                                );
                            },
                            onError:()=>{
                                notify(
                                    'Unable to update schedule template',
                                    {
                                        type: 'error'
                                    }
                                );
                            }
                        });
                    }
                }
            }} color={'success'} className={'cursor-pointer'}/>
        </div>
        <div>
            <Refresh onClick={() => {
                if (!isLoading) {
                    update('batch_schedule_templates', { id, data: { start_time: null, end_time: null,  }, previousData: existingValue }, {
                        onSuccess:()=>{
                            notify(
                                'Schedule template updated',
                                {
                                    type: 'success'
                                }
                            );
                            setEndTime('');
                            setStartTime('');
                        },
                        onError:()=>{
                            notify(
                                'Unable to update schedule template',
                                {
                                    type: 'error'
                                }
                            );
                        }
                    });
                }
            }}  color={'info'} className={'cursor-pointer'}/>
        </div>
    </>
}
const BatchScheduleTemplatesComponent = ({batchTemplateSubjects}: { batchTemplateSubjects: any[] }) => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const subjectIds = batchTemplateSubjects?.map((b) => b.subject_id);
    const {data: subjectsData,} = useQuery(
        ['subjects_' + record.id, 'getList', {batch_id: record.id}],
        () => dataProvider.getList('subjects', {
            filter: {
                id: subjectIds
            },
            pagination: {page: 1, perPage: 400},
            sort: {field: 'created_at', order: 'ASC'}
        })
    );
    const {data: scheduleTemplatesData,} = useQuery(
        ['batch_schedule_templates_' + record.id, 'getList', {batch_id: record.id}],
        () => dataProvider.getList('batch_schedule_templates', {
            filter: {
                batch_id: record.id
            },
            pagination: {page: 1, perPage: 100},
            sort: {field: 'created_at', order: 'ASC'}
        })
    );
    const subjects = subjectsData?.data || [];
    const daysOfTheWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    const scheduleTemplates = scheduleTemplatesData?.data || [];
    return <div className={'p-2 space-y-2'}>
        <div className={' grid grid-cols-1 lg:grid-cols-2 gap-4'}>
            {
                subjects.map((subject: Subjects, index) => {
                    const batchTemplateSubjectId = batchTemplateSubjects?.find((b)=>b.subject_id === subject.id)?.id;
                    return <div key={index} className={'space-y-2'}>
                        <div className={'py-1 font-extrabold'}>{subject.name}</div>
                        <div className={'flex p-2 rounded-md border space-x-2 items-center bg-gray-100'}>
                            <div className={'flex-1'}>
                                Day of the Week
                            </div>
                            <div className={'border rounded-md overflow-hidden py-0.5 px-2 w-[120px]'}>
                                Start Time
                            </div>
                            <div className={'border rounded-md overflow-hidden py-0.5 px-2 w-[120px]'}>
                                End Time
                            </div>
                            <div className={'w-[24px]'}/>
                            <div className={'w-[24px]'}/>
                        </div>
                        {
                            daysOfTheWeek.map((day, index) => {
                                return <div key={index} className={'flex p-2 items-center rounded-md border space-x-2'}>
                                    <BatchScheduleTemplateComponent existingValue={scheduleTemplates?.find((a)=>a.batch_template_subject_id === batchTemplateSubjectId && day === a.day)} batchTemplateSubjectId={batchTemplateSubjectId} day={day}/>
                                </div>
                            })
                        }
                    </div>
                })
            }
        </div>
    </div>
}

export default BatchScheduleTemplates
