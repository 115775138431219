import * as React from 'react';
import {
    Create,
    TextInput,
    SimpleForm,
    SelectInput,
    required,
    FormDataConsumer,
    ReferenceInput,
    ArrayInput,
    SelectArrayInput,
    BooleanInput,
    useDataProvider
} from 'react-admin';
import ImageInput from "../../common/inputs/ImageInput";
import { STATUSES, VIDEO_ENCODING, VIDEO_TYPES } from "../../common/constants";
import VideoInput from "../../common/inputs/VideoInput";
import { InputAdornment } from "@mui/material";
import useAllowedQualities from "../../hooks/useAllowedQualities";
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const VideoCreate = () => {
    const Qualities = useAllowedQualities();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const videoSubjectId = searchParams.get('video_subject_id');

    // Determine if the ReferenceInput should be disabled
    const isDisabled = !!videoSubjectId;

    return (
        <Create redirect={'/videos'} transform={(data: any) => ({
            ...data,
            processed_url: data.type === 'YOUTUBE' ? `https://www.youtube.com/embed/${data.raw_url}` : '',
            raw_url: data.type === 'YOUTUBE' ? `https://www.youtube.com/embed/${data.raw_url}` : data.raw_url
        })}>
            <SimpleForm defaultValues={{
                restriction_max_duration_multiple: 10,
                restriction_max_number_playback: 50,
                video_subject_id: videoSubjectId // Set the default value for video_subject_id
            }}>
                <ImageInput source="thumbnail" fullWidth />
                <TextInput source="title" fullWidth />
                <SelectInput
                    source="type"
                    onChange={() => {
                        setTimeout(() => {
                            if (document.getElementById('youtube-input')) {
                                document.getElementById('youtube-input')?.focus();
                            }
                        }, 100);
                    }}
                    label={'Type'}
                    fullWidth
                    validate={[required()]}
                    choices={VIDEO_TYPES}
                />

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.type === 'YOUTUBE' ? <TextInput
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        https://www.youtube.com/embed/
                                    </InputAdornment>
                                ),
                            }}
                            id={'youtube-input'}
                            label={'Youtube Embed Code'} fullWidth
                            validate={[required()]} source="raw_url" {...rest} /> : (formData.type ? <>
                                <ArrayInput source="qualities" validate={[required()]} defaultValue={['360', '480']}>
                                    <SelectArrayInput
                                        validate={[required()]}
                                        label={'Video Qualities'}
                                        fullWidth
                                        choices={Qualities} />
                                </ArrayInput>
                                <ArrayInput source="encodings" defaultValue={['HLS']}>
                                    <SelectArrayInput
                                        label={'Video Encodings'}
                                        fullWidth
                                        choices={VIDEO_ENCODING} />
                                </ArrayInput>
                                <BooleanInput defaultValue={false} fullWidth source={'drm_enabled'} />
                                <VideoInput fullWidth type={'VIDEOS'}
                                    meta={{
                                        drm_enabled: formData.drm_enabled,
                                        encodings: formData.encodings,
                                        qualities: formData.qualities
                                    }}
                                    validate={[required()]} source={'raw_url'} />
                            </> : <></>)
                    }
                </FormDataConsumer>
                <ReferenceInput
                    reference={'video_subjects'}
                    source={'video_subject_id'}
                    defaultValue={videoSubjectId} // Set default value here
                >
                    <SelectInput fullWidth optionText="name"
                        disabled={isDisabled} // Disable if videoSubjectId is present
                    />
                </ReferenceInput>

            </SimpleForm>
        </Create>
    );
};

export default VideoCreate;
