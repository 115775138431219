import DemoVideosCategoriesCreate from "./DemoVideosCategoriesCreate";
import DemoVideosCategoriesEdit from "./DemoVideosCategoriesEdit";
import DemoVideosCategoriesList from "./DemoVideosCategoriesList";
import DemoVideosCategoriesShow from "./DemoVideosCategoriesShow";

export default {
    list: DemoVideosCategoriesList,
    edit: DemoVideosCategoriesEdit,
    create: DemoVideosCategoriesCreate,
    show: DemoVideosCategoriesShow,
};
