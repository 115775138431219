import * as React from 'react';
import {

    List,
    EditButton,
    Datagrid,
    TextField,  BooleanField, ReferenceField,
} from 'react-admin';
import FormattedDateTimeField from '../../common/FormattedDateTimeField';
import ThumbnailField from "../../common/ThumbnailField";
import FullNameField from "../../common/FullNameField";


const DoubtList = () => {
    return (
        <div>
            <List sort={{field: 'featured', order: 'DESC'}}>
                <Datagrid>
                    <ThumbnailField source="image"/>
                    <TextField source="title"/>
                    <TextField source="status"/>
                    <BooleanField source="featured"/>
                    <ReferenceField reference={'subjects'} source={'subject_id'}>
                        <TextField source={'name'}/>
                    </ReferenceField>
                    <ReferenceField reference={'users'} source={'created_by_id'}>
                        <FullNameField source={'first_name'}/>
                    </ReferenceField>
                    <FormattedDateTimeField source="created_at"/>
                    
                    <EditButton/>
                </Datagrid>
            </List>
        </div>
    );
};
export default DoubtList;
