import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, SelectInput, ReferenceInput, NullableBooleanInput, minValue,
} from 'react-admin';

import {Ecom_Attributes,} from "../../custom";
import {EditToolbar} from "../../common/EditToolbar";
import {useParams} from "react-router";
import {LoadingSection} from "../../common/LoadingSection";

const AttributeEdit = () => {
    let {categoryId, attributeId} = useParams();
    if (!attributeId) {
        return <LoadingSection/>
    }
    return <Edit title={<Title/>} redirect={() => {
        return `ecom_categories/${categoryId}/ecom_attributes`
    }} resource={'ecom_attributes'} id={attributeId}>
        <SimpleForm toolbar={<EditToolbar/>}>
            <TextInput source="name" fullWidth/>
            <TextInput source="slug" fullWidth/>
            <ReferenceInput reference={'ecom_categories'} source={'category_id'}>
                <SelectInput disabled fullWidth optionText="name"/>
            </ReferenceInput>
            <NullableBooleanInput source={'is_filterable'} name={'Is Filterable'}/>
            <TextInput source="display_order" type={'number'} fullWidth validate={[minValue(0)]}/>
        </SimpleForm>
    </Edit>
};

const Title = () => {
    const record = useRecordContext<Ecom_Attributes>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.attributes.name', {smart_count: 1})} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default AttributeEdit;
