import * as React from 'react';
import { Fragment, useCallback } from 'react';
import {
    Count,
    DatagridConfigurable,
    List,
    ReferenceField,
    TextField, TextInput,
    TopToolbar,
    useListContext,
    useNotify,
    useRecordContext,
} from 'react-admin';
import { Divider, Tabs, Tab, CircularProgress, Button, Box, InputAdornment, Typography } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';

import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import FullNameField from '../../common/FullNameField';
import PurchasedPrice from './PurchasedPrice';
import OrderItemsFiled from './OrderItemField';
import { BASE_URL } from '../../common/constants';
import TrackingIdAndPaymentSourceField from '../../common/TrackingIdAndPaymentSourceField';

const ListActions = () => (
    <TopToolbar className={'justify-start w-full'}>
        <FilterForm />
    </TopToolbar>
);

const Actions = () => {
    const [revalidate, setRevalidate] = React.useState('');
    const [revalidating, setRevalidating] = React.useState(false);
    const record = useRecordContext()
    const notify = useNotify();

    const handleRevalidate = async () => {
        try {
            setRevalidating(true);

            const response = await fetch(`${BASE_URL}/orders/${record?.id}/re-validate`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            });

            if (response.status === 200) {
                const data = await response.json();
                // console.log("data", data?.message);

                notify(
                    'validated successfully',
                    {
                        type: 'success',
                        messageArgs: {},
                    }
                );

                setRevalidate(data.revalidate);
            } else {
                const errorData = await response.json();
                const Error = errorData?.message
                notify(
                    'please try again',
                    {
                        type: 'error',
                        messageArgs: { Error },
                    }
                );
            }
        } catch (error) {
            // Handle fetch error
            console.error('Fetch error:', error);
            notify(
                'error in validating',
                {
                    type: 'error',
                    messageArgs: {},
                }
            );
        } finally {
            setRevalidating(false);
        }
    };


    return (
        <div className="w-full">
            {/* {revalidate && <div>{revalidate}</div>} */}
            {revalidating ? (
                <div className="flex justify-center">
                    <CircularProgress />
                </div>
            ) : (
                <Button onClick={handleRevalidate} variant="contained" color="primary" size='small' fullWidth>
                    Re-validate
                </Button>
            )}
        </div>
    );
};

const OrderList = () => {
    const record = useRecordContext();
    const filterToQuery = (filterValues: any) => {
        const { tracking_id, query } = filterValues;
        return {
            tracking_id: { _eq: tracking_id },
            student: query ? {
                format: 'hasura-raw-query',
                value: {
                    _or: [
                        { phone: { _eq: query } },
                        { email: { _eq: query } },
                        { first_name: { _ilike: `%${query}%` } },
                        { last_name: { _ilike: `%${query}%` } },
                    ]
                },
            } : {}
        };
    };
    return <List
        filterDefaultValues={{ status: 'PENDING' }}
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={25}
        actions={<ListActions />}
    >
        <TabbedDatagrid />
    </List>
};

const FilterForm = () => {
    const {
        displayedFilters,
        filterValues,
        setFilters,
        hideFilter
    } = useListContext();

    const form = useForm({
        defaultValues: filterValues,
    });

    const clearFilters = () => {
        setFilters({}, undefined, false);
    }

    const onSubmit = (values: any) => {
        const v: any = {}
        if (values.tracking_id) {
            v['tracking_id'] = values.tracking_id
        }
        if (values.phone) {
            v['student'] = {
                format: 'hasura-raw-query',
                value: { phone: { _eq: values.phone } }
            }
        }
        if (values.product) {
            v['order_items'] = {
                format: 'hasura-raw-query',
                value: {
                    'ecom_product': {
                        name: { _eq: values.product }
                    }
                }
            }
        }
        setFilters(v, undefined, false);
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Box display="flex" alignItems={'flex-end'} mb={1}>
                    <Box component="span" mr={2} mb={1.5}>
                        <Button variant="outlined" color="primary" type="button" onClick={clearFilters}>
                            Clear
                        </Button>
                    </Box>
                    <Box component="span" mr={2} mb={1.5}>
                        <Button variant="outlined" color="primary" type="submit">
                            Filter
                        </Button>
                    </Box>
                    <Box component="span" mr={2}>
                        <TextInput
                            resettable
                            helperText={false}
                            source="tracking_id"
                            label="Tracking Id"
                            placeholder={'Tracking Id'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position={'start'}>
                                        <SearchIcon color="disabled" />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                    <Box component="span" mr={2}>
                        <TextInput
                            resettable
                            helperText={false}
                            source="phone"
                            label="User"
                            placeholder={'Phone'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position={'start'}>
                                        <SearchIcon color="disabled" />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                    <Box component="span" mr={2}>
                        <TextInput
                            resettable
                            helperText={false}
                            source="product"
                            label="Product"
                            placeholder={'Product'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position={'start'}>
                                        <SearchIcon color="disabled" />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Box>
            </form>
        </FormProvider>
    );
};

const tabs = [
    { id: 'CANCELLED', name: 'CANCELLED' },
    { id: 'DELIVERED', name: 'DELIVERED' },
    { id: 'PENDING', name: 'PENDING' },
    { id: 'RECEIVED', name: 'RECEIVED' },
    { id: 'RETURNED', name: 'RETURNED' },
    { id: 'SHIPPED', name: 'SHIPPED' },
    { id: 'FAILED', name: 'FAILED' },
];

const TabbedDatagrid = () => {
    const listContext = useListContext();
    const { filterValues, setFilters, displayedFilters } = listContext;

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
                setFilters(
                    { ...filterValues, status: value },
                    displayedFilters,
                    false // no debounce, we want the filter to fire immediately
                );
        },
        [displayedFilters, filterValues, setFilters]
    );

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.status}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice?.id}
                        label={
                            <span>
                                {choice.name} (
                                <Count
                                    filter={{
                                        ...filterValues,
                                        status: choice.name,
                                    }}
                                    sx={{ lineHeight: 'inherit' }}
                                />
                                )
                            </span>
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider />
            <>
                <DatagridConfigurable
                    omit={[]}
                >
                    <ReferenceField reference={'users'} source={'student_id'} label="Student">
                        <FullNameField />
                    </ReferenceField>
                    {/* <TrackingIdAndPaymentSourceField label="Payment Source and Tracking Id" /> */}
                    <TextField source="tracking_id" />
                    <TextField source="status" />
                    {/* <CustomerAddressField /> */}
                    <PurchasedPrice label="Amount" />
                    <OrderItemsFiled />
                    <ReferenceField reference={'users'} source={'created_by_id'} label="Created By">
                        <FullNameField />
                    </ReferenceField>
                    <FormattedDateTimeField source="created_at" />
                    <div className="w-[110px]">
                        {['PENDING'].includes(filterValues.status) && <Actions />}
                    </div>
                </DatagridConfigurable>
            </>
        </Fragment>
    );
};

export default OrderList;

const CustomerAddressField = () => {
    const record = useRecordContext();
    console.log("record", record?.address)
    return (
        <div>
            <Typography>
                {record?.address?.first_name} {record?.address?.last_name}
            </Typography>
            <Typography>{record?.address?.phone} </Typography>
            <Typography>{record?.address?.email} </Typography>
            <Typography>{record?.address?.address_line} </Typography>
            <Typography>{record?.address?.landmark} </Typography>
            <Typography>{record?.address?.locality},{record?.address?.city},{record?.address?.district} </Typography>
            <Typography>{record?.address?.pincode} </Typography>
            <Typography>{record?.address?.state} </Typography>
            {/*<Typography>*/}
            {/*    {record?.city}, {record?.stateAbbr} {record?.zipcode}*/}
            {/*</Typography>*/}
        </div>
    );
};