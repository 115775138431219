import * as React from 'react';
import {Create, TextInput,  SimpleForm, SelectInput, ReferenceInput,} from 'react-admin';
import {useParams} from "react-router";
import {LoadingSection} from "../../common/LoadingSection";


const AttributeValueCreate = () => {
    let {categoryId, attributeId, productTemplateId} = useParams();
    if (!categoryId && !productTemplateId) {
        return <LoadingSection/>
    }
    return <Create resource={'ecom_attribute_values'} redirect={(e: any, id: any) => {
        return productTemplateId ? `` : `ecom_categories/${categoryId}/ecom_attributes/${attributeId}/ecom_attribute_values`
    }}>
        <SimpleForm  defaultValues={
            {
                attribute_id: attributeId,
            }
        }>
            <TextInput source="name" fullWidth/>
            <TextInput source="slug" fullWidth/>
            <ReferenceInput reference={'ecom_attributes'} source={'attribute_id'}>
                <SelectInput disabled fullWidth optionText="name"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
};

export default AttributeValueCreate;
